import { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import { CSSGenerator } from "interfaces";
import { DrawerComponent, DRAWER_WIDTH } from "./DrawerComponent";
import { AppHeader } from "./AppHeader";
import { useStyles } from "hooks";

type Props = {
  children: React.ReactNode;
};

export const Layout: React.FC<Props> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const styles = useStyles<boolean>(generateStyles, drawerOpen);
  return (
    <>
      <AppHeader setDrawerOpen={setDrawerOpen} />
      <Toolbar />
      <Box component="main" sx={styles.root}>
        <Box sx={styles.drawerPlaceholder} />
        <Box sx={styles.content}>{children}</Box>
      </Box>
      <DrawerComponent open={drawerOpen} />
    </>
  );
};

const generateStyles: CSSGenerator<boolean> = (theme, drawerOpen) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
  },
  drawerPlaceholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    width: drawerOpen ? DRAWER_WIDTH : `calc(${theme.spacing(7)} + 1px)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: drawerOpen
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
  content: {
    padding: "31px",
    backgroundColor: "#F9F9F9",
    minHeight: "calc(100vh - 64px)",
    width: `calc(100% - ${
      drawerOpen ? DRAWER_WIDTH + "px" : theme.spacing(7) + " + 1px"
    })`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: drawerOpen
        ? theme.transitions.duration.enteringScreen
        : theme.transitions.duration.leavingScreen,
    }),
  },
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import { SxProps } from "@mui/system";
import {
  TableCellType,
  TableRowData,
  TableTextCellProps,
  TableChipCellProps,
  TableColumnData,
  TableIconButtonCellProps,
  TableChipCellValue,
} from "./interfaces";
import { TextCell } from "./TextCell";
import { ChipCell } from "./ChipCell";
import { IconButtonCell } from "./IconButtonCell";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { TableIconCellProps } from ".";
import { IconCell } from "./IconCell";

export enum Order {
  ASC = "asc",
  DESC = "desc",
}
export const OrderFactor: Record<Order, number> = {
  [Order.ASC]: 1,
  [Order.DESC]: -1,
};

const collator = new Intl.Collator("es", { numeric: true });

type CompareFunc = (a: any, b: any, order: Order) => number;
const getComparator = (orderBy: string): CompareFunc => {
  return (a: any, b: any, order: Order) =>
    OrderFactor[order] *
    collator.compare(a[orderBy] as string, b[orderBy] as string);
};

const getDefaultCol = (cols: TableColumnData[]): TableColumnData | undefined =>
  cols.find((c) => c.sortedByDefault);

const buildRowData = (
  datum: BasicDataEntry,
  columnDefs: TableColumnData[],
): TableRowData => ({
  id: datum.id,
  cols: columnDefs.map((colDef) => ({
    ...colDef,
    type: colDef.cellType,
    value: datum[colDef.attr] as string | number | TableChipCellValue,
  })),
  onRowClick: datum.onRowClick ?? null,
});

const CELL_COMPONENTS: Record<TableCellType, React.FC<any>> = {
  [TableCellType.TEXT]: (props: TableTextCellProps) => <TextCell {...props} />,
  [TableCellType.CHIP]: (props: TableChipCellProps) => <ChipCell {...props} />,
  [TableCellType.ICON_BUTTON]: (props: TableIconButtonCellProps) => (
    <IconButtonCell {...props} />
  ),
  [TableCellType.ICON]: (props: TableIconCellProps) => <IconCell {...props} />,
};

type BasicDataEntry = any & {
  id: number;
  // [x: string]: unknown;
} & Record<string, unknown>;

export type CustomTableProps = {
  sx?: SxProps;
  rawData: BasicDataEntry[];
  columnDefs: TableColumnData[];
  disableSorting?: boolean;
};

export const CustomTable: React.FC<CustomTableProps> = ({
  sx,
  rawData,
  columnDefs,
  disableSorting,
}) => {
  const [order, setOrder] = useState<Order>(
    getDefaultCol(columnDefs)?.sortedByDefaultDirection ?? Order.ASC,
  );
  const [_orderBy, setOrderBy] = useState<string | null>(
    () => getDefaultCol(columnDefs)?.attr ?? columnDefs[0].attr,
  );

  const orderBy = useMemo(
    () => (disableSorting ? null : _orderBy),
    [disableSorting, _orderBy],
  );

  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(15);

  const styles = useStyles(generateStyles);

  const sortedDataSource = useMemo(() => {
    if (!orderBy) {
      return rawData.map((d) => buildRowData(d, columnDefs));
    }
    const colDef = columnDefs.find((c) => c.attr === orderBy);
    const comparator = colDef?.sortFn ?? getComparator(orderBy);
    return rawData
      .sort((a, b) => comparator(a, b, order))
      .map((d) => buildRowData(d, columnDefs));
  }, [order, orderBy, rawData, columnDefs]);

  const pageShownRows = useMemo(() => {
    // show every row when range selected is "Todas"
    if (rowsPerPage < 0) return sortedDataSource;
    // show results for the selected page
    return sortedDataSource.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [page, rowsPerPage, sortedDataSource]);

  const onSortableHeaderClick = useCallback(
    (attr: string) => {
      if (attr === orderBy) {
        setOrder((prev) => (prev === Order.ASC ? Order.DESC : Order.ASC));
      } else {
        setOrderBy(attr);
      }
    },
    [orderBy, setOrder, setOrderBy],
  );

  const handleChangePage = useCallback(
    (_, newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const dataLength = useMemo(() => rawData.length, [rawData]);

  useEffect(() => {
    setPage(0);
  }, [dataLength]);

  return (
    <TableContainer sx={sx} component={Paper}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow>
            {columnDefs.map((headerData, idx) => (
              <TableCell
                sx={{
                  ...styles.headCell,
                  paddingLeft: headerData.align === "center" ? "34px" : "16px",
                }}
                key={`theader-${idx}`}
                align={headerData.align ?? "left"}
              >
                {headerData.sortable && !disableSorting ? (
                  <TableSortLabel
                    active={orderBy === headerData.attr}
                    direction={order}
                    onClick={() => onSortableHeaderClick(headerData.attr)}
                  >
                    {headerData.headerName}
                  </TableSortLabel>
                ) : (
                  headerData.headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {pageShownRows.map((rowData, idxRow) => {
            const { id, cols, onRowClick } = rowData;
            return (
              <TableRow
                sx={{
                  ...(onRowClick
                    ? {
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#EEEEEE70" },
                      }
                    : {}),
                }}
                key={`tr-${idxRow}`}
                onClick={() => onRowClick?.(id)}
              >
                {cols.map((colDef, idxCol) => {
                  const { type, ...props } = colDef;
                  const Component = CELL_COMPONENTS[type];
                  return (
                    <Component
                      key={`row-${idxRow}-col-${idxCol}`}
                      {...props}
                      onClick={(_id: number) => {
                        props.onClick?.(id);
                      }}
                      getIconData={
                        props.getIconData
                          ? (_id: number) => props.getIconData?.(id)
                          : undefined
                      }
                    />
                  );
                })}
              </TableRow>
            );
          })}
          {sortedDataSource.length === 0 && (
            <TableRow>
              <TableCell colSpan={columnDefs.length}>
                No se encontraron datos
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[
                5,
                10,
                15,
                25,
                { label: "Todas", value: -1 },
              ]}
              colSpan={columnDefs.length}
              count={sortedDataSource.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              labelRowsPerPage="Filas mostradas:"
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  tableHead: {
    backgroundColor: theme.palette.grey[200],
  },
  headCell: {
    fontWeight: 500,
  },
});

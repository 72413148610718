import { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Box, Paper, Toolbar } from "@mui/material";
import {
  TextHeader,
  TextSubHeader,
  IdentificationFileUpload,
} from "components";
import { useStyles } from "hooks";
import {
  AppRoutes,
  CSSGenerator,
  IdentityFileType,
  IdentityFileData,
} from "interfaces";
import {
  GetCourseDetailsParams,
  saveIdentityFile,
  saveIdentityFileByUUID,
  useDocumentDataSimplifiedByUUID,
} from "api";
import { AppContext, FeedbackContext } from "contexts";
import {
  fileToBase64,
  generateIdentificationFilePdf,
  compressImage,
} from "utils";

export interface IdentificationFileData {
  front?: File;
  back?: File;
}

export interface IdentificationFilePreview {
  front?: string;
  back?: string;
}

export const IdentificationFileUploadPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const location = useLocation<
    | {
        refetchCourseDetailsQueryParams?: GetCourseDetailsParams;
        comingFromMassSign?: boolean;
      }
    | undefined
  >();
  const { data, loading: loadingData } = useDocumentDataSimplifiedByUUID(
    uuid,
    false,
    location.state?.comingFromMassSign,
  );

  const styles = useStyles(generateStyles);
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const { user } = useContext(AppContext);

  const [compressedFiles, setCompressedFiles] =
    useState<IdentificationFileData | null>(null);
  const [processing, setProcessing] = useState(0);
  const [filesContent, setFilesContent] =
    useState<IdentificationFilePreview | null>(null);

  const onFileChange = useCallback(
    async (section: keyof IdentificationFileData, file: File) => {
      if (!file) return;
      // Compress and save file content (base64)
      setProcessing((prev) => prev + 1);
      toggleLoader(
        true,
        `[IdentificationFileUploadPage] Uploading ${section} file`,
      );
      const compressedFile = await compressImage(file);
      setCompressedFiles((prev) => ({
        ...prev,
        [section]: compressedFile,
      }));
      const content = await fileToBase64(compressedFile);
      setFilesContent((prev) => ({
        ...prev,
        [section]: content,
      }));
      setProcessing((prev) => prev - 1);
      toggleLoader(
        false,
        `[IdentificationFileUploadPage] Uploading ${section} file`,
      );
      showSnackbar("Seleccionada correctamente", "success");
    },
    [
      setProcessing,
      setFilesContent,
      setCompressedFiles,
      toggleLoader,
      showSnackbar,
    ],
  );

  const onDeleteFile = useCallback(() => {
    setFilesContent(null);
  }, [setFilesContent]);

  const onFinish = useCallback(
    async (expirationDate: Date | null) => {
      if (
        !filesContent?.front ||
        !filesContent?.back ||
        !compressedFiles?.front ||
        !compressedFiles?.back
      )
        return;
      toggleLoader(true, "[IdentificationFileUploadPage] Uploading file");
      const combinedFile = await generateIdentificationFilePdf({
        frontContent: filesContent.front,
        backContent: filesContent.back,
      });
      if (!combinedFile) return;
      // Upload files
      const filesData: IdentityFileData[] = [
        {
          type: IdentityFileType.COMBINED,
          file: new File([combinedFile], "file_name.pdf", {
            lastModified: new Date().getTime(),
            type: combinedFile.type,
          }),
        },
        {
          type: IdentityFileType.FRONT_FACE,
          file: compressedFiles.front as File,
        },
        {
          type: IdentityFileType.BACK_FACE,
          file: compressedFiles.back as File,
        },
      ];
      const fileUploaded = user
        ? await saveIdentityFile(
            {
              files: filesData,
              expirationDate,
            },
            location.state?.refetchCourseDetailsQueryParams,
          )
        : await saveIdentityFileByUUID({
            uuid,
            files: filesData,
          });
      if (!fileUploaded) {
        toggleLoader(false, "[IdentificationFileUploadPage] Uploading file");
        showSnackbar("Algo falló al guardar la cédula de identidad", "error");
        return;
      }
      toggleLoader(false, "[IdentificationFileUploadPage] Uploading file");
      if (uuid) {
        // Participant (DJP/Precontract) or OTEC (DJO)
        history.replace(`${AppRoutes.SUCCESSFUL_SIGNATURE}/${uuid}`);
      } else {
        // Representante Legal Empresa (mass precontract sign)
        history.replace(AppRoutes.PRECONTRACTS);
      }
    },
    [
      filesContent,
      compressedFiles,
      history,
      location,
      showSnackbar,
      toggleLoader,
      user,
      uuid,
    ],
  );

  useEffect(() => {
    toggleLoader(
      loadingData,
      "[IdentificationFileUploadPage] Fetching document data",
    );
  }, [loadingData, toggleLoader]);
  useEffect(() => {
    if (user?.identityFile?.url) {
      history.replace(AppRoutes.DASHBOARD);
    }
  }, [user?.identityFile?.url, history]);

  return (
    <Box sx={styles.root}>
      <Toolbar />
      <TextHeader text={"Cédula de identidad"} showAvatar={false} />
      <Paper sx={styles.containerHeader} elevation={4}>
        <TextSubHeader
          text={`Sube tu cédula de identidad ${
            !user ? "para finalizar el proceso de firma" : ""
          }`}
        />
      </Paper>
      <Box sx={styles.content}>
        <IdentificationFileUpload
          filePreview={filesContent}
          minExpirationDate={data?.courseLiquidationDate ?? null}
          onFileChange={onFileChange}
          onDeleteFile={onDeleteFile}
          onFileUpload={onFinish}
          processing={!!processing}
        />
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: { height: "100vh" },
  content: { height: "calc(100vh - 128px)" },
});

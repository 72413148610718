import { CSVLink } from "react-csv";
import { FileDownloadOutlined } from "@mui/icons-material";
import { CustomButton } from ".";

type Headers =
  | {
      label: string;
      key: string;
    }[]
  | string[];

type Data = object[];

type Props = {
  data: Data;
  headers: Headers;
  filename: string;
};

export const DownloadButton: React.FC<Props> = ({
  data,
  headers,
  filename,
}) => (
  <CustomButton variant="contained">
    <CSVLink
      data={data}
      headers={headers}
      filename={filename}
      style={{
        color: "white",
        textDecoration: "none",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <FileDownloadOutlined />
      DESCARGAR
    </CSVLink>
  </CustomButton>
);

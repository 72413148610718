import { useCallback } from "react";
import { Document } from "interfaces";
import { useAvailableDocumentActions } from "hooks";

export const useCanInteractWithParticipantDocument = (): ((
  document: Document,
) => boolean) => {
  const getDocumentActions = useAvailableDocumentActions();
  const canInteractDocument = useCallback(
    (document: Document) => {
      const docPermissions = getDocumentActions(document);
      return (
        docPermissions.canView ||
        docPermissions.canManualUpload ||
        docPermissions.canValidate ||
        docPermissions.canForceValidation ||
        docPermissions.canViewForcedValidation ||
        docPermissions.canReplace
      );
    },
    [getDocumentActions],
  );
  return canInteractDocument;
};

import { useCallback, useContext, useEffect, useMemo, useState, useRef } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { CheckCircle, HighlightOff, DocumentScannerRounded } from "@mui/icons-material";
import { useElearningCoursesSummary, useLastExecutionBot, useUrlCourseFile } from "api";
import { FeedbackContext, AppContext } from "contexts";
import { Range } from 'react-date-range'
import {
  TableCellType,
  OnSiteFilterSection,
  TableColumnData,
  TableChipCellStyle,
  TabbedTable,
  CellIconData
} from "components";
import {
  CSSGenerator,
  CourseListTabs,
  ElearningCourseListFilters,
  Permission,
  AppRoutes,
  CourseFilterType,
} from "interfaces";
import { useStyles, useRestrictedPage } from "hooks";
import {
  parseActiveOnSiteCoursesData,
  parseFinishedOnSiteCoursesData,
  filterElearningCourseListData,
  initialElearningCourseFilters,
  filterColDefsBasedOnUser,
  slashFormatDateTime,
} from "utils";

const lookupIconBotAlert = {
  0: HighlightOff,
  1: CheckCircle,
};

export const LceOnSitePage: React.FC<
  RouteComponentProps
> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_LCE_ONSITE, Permission.CAN_LIST_COURSES],
    redirectTo: AppRoutes.DASHBOARD,
  });
  const history = useHistory();
  const delay = (ms : number) => new Promise(res => setTimeout(res, ms));

  const [ urlSencenet, setUrlSencenet ] = useState<number>(0);

  const { isAllowedTo, user } = useContext(AppContext);
  const { toggleLoader } = useContext(FeedbackContext);
  const { lastExecutionBotData, loading: loadingStatusBot } =
    useLastExecutionBot();

  const executionIsoDate = lastExecutionBotData?.fechaIngreso
    ? new Date(lastExecutionBotData?.fechaIngreso)
    : null;
  const executionDate = slashFormatDateTime(executionIsoDate);

  // date range state
  const [range, setRange] = useState<Range[]>([
    {
      startDate: new Date('01 Jan 2023 08:04:05 GMT'),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const { courses, loading  } = useElearningCoursesSummary({
    type: CourseFilterType.ONSITE,
    startDate: range[0].startDate,
    endDate: range[0].endDate,
  });


  const {data, loading: loadingCourseFile} = useUrlCourseFile({
    sencenet: urlSencenet,
    type: CourseFilterType.ONSITE
  });

 


  const statusButton =
    lastExecutionBotData !== null && lastExecutionBotData?.idAccion !== 5
      ? 1
      : 0;

  const IconComponent = lookupIconBotAlert[statusButton];

  const styles = useStyles(generateStyles, statusButton);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [filters, setFilters] = useState<ElearningCourseListFilters>(
    initialElearningCourseFilters,
  );
  const activeCoursesData = useMemo(
    () =>
      parseActiveOnSiteCoursesData(
        courses.filter((course) => course.djo.status === "E_PENDING"),
        history,
      ),
    [courses, history],
  );

  const finishedCoursesData = useMemo(
    () =>
      parseFinishedOnSiteCoursesData(
        courses.filter((course) => course.djo.status === "E_FINISHED"),
        history,
      ),
    [courses, history],
  );






  const onDocumentClick = useCallback(async (id: number) => {
    const docActiveParticipantData = activeCoursesData.find((course) => course.id === id);
    const docFinishedParticipantData = finishedCoursesData.find((course) => course.id === id);

    if (!docActiveParticipantData && !docFinishedParticipantData) return;


    toggleLoader(true,"LceOnSitePage loading");
    delay(10000);
    setUrlSencenet(id)

    toggleLoader(false, "LceOnSitePage loading");

}, [activeCoursesData, finishedCoursesData, toggleLoader]);





const getActiveDocumentIconData = useCallback((id: number): CellIconData | null => { 
  const docParticipantData = activeCoursesData.find((course) => course.id === id);

  if(!docParticipantData?.haveDocument) return {
    icon: <DocumentScannerRounded />,
    tooltipLabel: "Sin Documento",
    disabled: !docParticipantData?.haveDocument
  };


  return {
    icon: <DocumentScannerRounded />,
    tooltipLabel: "Para acceder a One Drive, debe solicitar permisos a su Jefatura",
    disabled: !docParticipantData
  }

}, [activeCoursesData]);



const getFinishedDocumentIconData = useCallback((id: number): CellIconData | null => { 
  const docParticipantData = finishedCoursesData.find((course) => course.id === id);

  if(!docParticipantData?.haveDocument) return {
    icon: <DocumentScannerRounded />,
    tooltipLabel: "Sin Documento",
    disabled: !docParticipantData?.haveDocument
  };


  return {
    icon: <DocumentScannerRounded />,
    tooltipLabel: "Para acceder a One Drive, debe solicitar permisos a su Jefatura",
    disabled: !docParticipantData
  }
}, [finishedCoursesData]);





  const enrichedActiveColumnDefs = useMemo(() => {

    // Filter columns by permissions
    const colDefs = activeColDefs.filter(
      (colDef) => !colDef.permissions || isAllowedTo(colDef.permissions, false),
    );

    const documentCol = colDefs.find((col) => col.attr === "document");
    if (documentCol) {
      documentCol.onClick = onDocumentClick;
      documentCol.getIconData = getActiveDocumentIconData;
    }

    return colDefs;
 
  }, [isAllowedTo, onDocumentClick, getActiveDocumentIconData])


  const enrichedFinishedColumnDefs = useMemo(() => {

    // Filter columns by permissions
    const colDefs = finishedColDefs.filter(
      (colDef) => !colDef.permissions || isAllowedTo(colDef.permissions, false),
    );

    const documentCol = colDefs.find((col) => col.attr === "document");
    if (documentCol) {
      documentCol.onClick = onDocumentClick;
      documentCol.getIconData = getFinishedDocumentIconData;
    }

    return colDefs;

  }, [isAllowedTo, onDocumentClick, getFinishedDocumentIconData])





  const tabsInfo = useMemo(
    () => [
      {
        name: CourseListTabs.ACTIVE,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(enrichedActiveColumnDefs, user),
          rawData: filterElearningCourseListData(activeCoursesData, filters),
        },
      },
      {
        name: CourseListTabs.FINISHED,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(enrichedFinishedColumnDefs, user),
          rawData: filterElearningCourseListData(finishedCoursesData, filters),
        },
      },
    ],
    [enrichedActiveColumnDefs, enrichedFinishedColumnDefs, activeCoursesData, finishedCoursesData, filters, user],
  );


  const getSelectedTab = useCallback(
    (selectedTab: string) => {
      setSelectedTab(selectedTab);
    },
    [setSelectedTab],
  );

  useEffect(() => {
    toggleLoader(loading || loadingStatusBot || loadingCourseFile, "LceOnSitePage loading");
  }, [loading, loadingStatusBot, loadingCourseFile, toggleLoader]);






  useEffect(() => {
      if(data?.getUrlCourseFile !== "https://oticcchc-my.sharepoint.com/undefine" && data?.getUrlCourseFile !== undefined && data?.getUrlCourseFile !== "https://oticcchc-my.sharepoint.com/null" && data?.getUrlCourseFile !== null) {
        delay(5000)
        window.open(data?.getUrlCourseFile, "_blank");
    }
  }, [data?.getUrlCourseFile]);





  return (
    <Box sx={styles.root}>
      <Typography variant="h6">LCE - Presencial</Typography>
      <OnSiteFilterSection
        data={
          selectedTab === CourseListTabs.FINISHED
            ? finishedCoursesData
            : activeCoursesData
        }
        filters={filters}
        setFilters={setFilters}
        useCriticalityFilters={selectedTab === CourseListTabs.ACTIVE}
        useCellFilter={user?.needFilterByCell}
        range={range}
        setRange={setRange}
      />

      {!loadingStatusBot && (
        <Box sx={styles.boxContainerStatus} id="container-1">
          <Box sx={styles.iconStatus} id="container-2">
            <IconComponent fontSize="large"></IconComponent>
          </Box>
          <Box sx={styles.boxStatus} id="container-3">
            <Box>
              <Typography sx={styles.typographyStatus} variant="h4">
                Estado Ejecución
              </Typography>
            </Box>
            <Box>
              <Typography sx={styles.typographyStatus} variant="h4">
                {executionDate}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <TabbedTable tabsInfo={tabsInfo} getSelectedTab={getSelectedTab} />
    </Box>
  );
};

const generateStyles: CSSGenerator<number> = (_theme, statusButton) => {
  return {
    root: { display: "flex", flexDirection: "column", gap: "24px" },
    buttonContainer: { display: "flex", justifyContent: "end" },
    boxContainerStatus: {
      display: "flex",
      justifyContent: "end",
      marginRight: "20px",
    },
    boxStatus: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: statusButton !== 0 ? "#87cec7" : "#f4433669",
      borderColor: statusButton !== 0 ? "#009688" : "#f44336",
      color: statusButton !== 0 ? "#41d88c" : "#f47336",
      padding: "0px 24px 0px 24px",
    },
    iconStatus: {
      display: "flex",
      backgroundColor: statusButton !== 0 ? "#009688" : "#f44336",
      color: statusButton !== 0 ? "#41d88c" : "#f47336",
      alignItems: "center",
      padding: "0px 16px 0px 16px",
    },
    typographyStatus: {
      fontSize: "20px",
      color: statusButton !== 0 ? "#009688" : "#f44336",
      padding: "5px 16px 5px 16px",
    },
  };
};

const activeColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.CHIP,
    headerName: "Días de plazo",
    attr: "daysLeft",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.PRIMARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Solicitud de Compra",
    attr: "purchaseOrder",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Estado LCE",
    attr: "djoStatus",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.ICON_BUTTON,
    headerName: "Documentos",
    attr: "document",
    sortable: false,
    onClick: undefined, // injected on component
    iconData: {
      icon: <DocumentScannerRounded />,
      tooltipLabel: "Sin Documentos",
    },
    style: {},
    align: "center",
    permissions: [Permission.CAN_VIEW_DJP_FILE, Permission.CAN_VIEW_LCE_FILE ]
  },
];

const finishedColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Fecha de cierre",
    attr: "endDate",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Solicitud de Compra",
    attr: "purchaseOrder",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Estado LCE",
    attr: "djoStatus",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.ICON_BUTTON,
    headerName: "Documentos",
    attr: "document",
    sortable: false,
    onClick: undefined, // injected on component
    iconData: {
      icon: <DocumentScannerRounded />,
      tooltipLabel: "Sin Documentos",
    },
    style: {},
    align: "center",
    permissions: [Permission.CAN_VIEW_DJP_FILE, Permission.CAN_VIEW_LCE_FILE ]
  },
];

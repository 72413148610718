import { gql } from "@apollo/client";
import {
  client,
  GET_DJ_COURSE_DETAILS_QUERY,
  GET_PRECONTRACT_COURSE_DETAILS_QUERY,
} from "api";
import { DocumentType } from "interfaces";

interface MutationParams {
  params: {
    documentIds: number[];
    documentType: DocumentType;
  };
}

interface RefetchParams {
  params: {
    sencenet: string;
    documentType: DocumentType;
  };
}

interface MutationResult {
  sendParticipantsReminders: boolean;
}

export const sendParticipantsReminders = async (
  params: MutationParams["params"],
  refetchParams: RefetchParams["params"],
): Promise<boolean> => {
  if (!params.documentIds || !params.documentType) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SendParticipantsReminders(
        $params: SendParticipantsRemindersParams!
      ) {
        sendParticipantsReminders(params: $params)
      }
    `,
    variables: {
      params,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query:
          params.documentType === DocumentType.PRECONTRACT
            ? GET_PRECONTRACT_COURSE_DETAILS_QUERY(params.documentType)
            : GET_DJ_COURSE_DETAILS_QUERY(params.documentType, refetchParams.sencenet),
        variables: {
          params: refetchParams,
        },
      },
    ],
  });
  return !!response.data?.sendParticipantsReminders;
};

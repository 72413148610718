import { DocumentNode, gql, useQuery } from "@apollo/client";
import { Course, DocumentType } from "interfaces";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";
import { IdentityFileFieldsFragment } from "../fragments";

export const GET_DJ_COURSE_DETAILS_QUERY = (
  documentType: DocumentType, sencenet: String
): DocumentNode => gql`
  query GetCourseDetails($params: GetCourseDetailsParams!) {
    getCourseDetails(params: $params) {
      id
      registrationNumber
      name
      sencenet
      senceCode
      actionType(sencenet: ${sencenet})
      modality
      startDate
      endDate
      liquidationDate
      preinscriptionUserName
      participantsNumber
      company {
        id
        rut
        name
      }
      otec {
        id
        rut
        name
        email
      }
      djo {
        id
        status
        uuid
        url
        type
        emissionDate
        manualUploaded
        otecSigner{
          rut
          identityFile {
            ...IdentityFileFields
          }
        }
        forcedValidationComment
      }
      djDaysLeft
      processEndDate(type: ${documentType})
      djCriticalyLevel
      cell
      active
      finishManagementDate
      documentParticipantInfo(type: ${documentType}) {
        id
        document {
          id
          status
          type
          url
          manualUploaded
          courses {
            id
            name
          }
          companyStatus
        }
        participant {
          id
          name
          rut
          email
          phone
          hasMissingData(type: ${documentType})
          identityFile {
            ...IdentityFileFields
          }
        }
        mainSignerStatus
        lastReminder
        forcedValidationComment
        lastCorrectionRequestComment
      }
    }
  }
  ${IdentityFileFieldsFragment}
`;

export const GET_PRECONTRACT_COURSE_DETAILS_QUERY = (
  documentType: DocumentType
): DocumentNode => gql`
  query GetCourseDetails($params: GetCourseDetailsParams!) {
    getCourseDetails(params: $params) {
      id
      registrationNumber
      name
      sencenet
      senceCode
      contractType
      startDate
      endDate
      liquidationDate
      preinscriptionUserName
      precontractDaysLeft
      precontractCriticalyLevel
      cell
      processEndDate(type: ${documentType})
      participantsNumber
      companySignatureStatus
      active
      finishManagementDate
      documentParticipantInfo(type: ${documentType}) {
        id
        document {
          id
          status
          type
          url
          courses {
            id
            name
          }
          manualUploaded
          companyStatus
        }
        documentVulnerability {
          id
          status
          type
          url
          courses {
            id
            name
          }
          manualUploaded
          companyStatus
        }
        documentMinorAuth {
          id
          status
          type
          url
          courses {
            id
            name
          }
          manualUploaded
          companyStatus
        }
        participant {
          id
          name
          rut
          email
          phone
          hasMissingData(type: ${documentType})
          identityFile {
            ...IdentityFileFields
          }
        }
        mainSignerStatus
        secondarySignerStatus
        minorAuthorizationStatus
        minorAuthorizationId
        vulnerabilityStatus
        vulnerabilityId
        lastReminder
        lastCorrectionRequestComment
        forcedValidationComment
      }
      company {
        id
        rut
        name
      }
      companyLegalRepresentativeSigner {
        id
        rut
        name
        identityFile {
          id
          url
        }
      }
      otec {
        id
        rut
        name
        email
      }
    }
  }
  ${IdentityFileFieldsFragment}
`;

export interface GetCourseDetailsParams {
  sencenet: string;
  documentType: DocumentType;
}

export const useCourseDetails = (
  params: GetCourseDetailsParams,
): {
  course: Course | null;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getCourseDetails: Course | null;
  }>(
    params.documentType === DocumentType.PRECONTRACT
      ? GET_PRECONTRACT_COURSE_DETAILS_QUERY(params.documentType)
      : GET_DJ_COURSE_DETAILS_QUERY(params.documentType, params.sencenet),
    {
      variables: {
        params: {
          sencenet: params.sencenet,
          documentType: params.documentType,
        },
      },
      pollInterval:
        settings?.pollingIntervals?.[PolledQuery.GET_COURSE_DETAILS] ??
        settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
        0,
    },
  );
  return {
    course: data?.getCourseDetails
      ? {
          ...data?.getCourseDetails,
          startDate: new Date(data?.getCourseDetails.startDate),
          endDate: new Date(data?.getCourseDetails.endDate),
          liquidationDate: new Date(data?.getCourseDetails.liquidationDate),
          finishManagementDate: data?.getCourseDetails.finishManagementDate
            ? new Date(data?.getCourseDetails.finishManagementDate)
            : null,
          djo: data?.getCourseDetails.djo
            ? {
                ...data?.getCourseDetails.djo,
                emissionDate: new Date(data.getCourseDetails.djo.emissionDate),
              }
            : data?.getCourseDetails.djo,
        }
      : null,
    loading,
  };
};

import { CSSProperties } from "react";
import { getResponsiveSize } from "utils";

export type Row = Record<string, string | number>;

type Props = {
  headers: string[];
  rows: Row[];
  cellStyle?: CSSProperties;
  useCourseContentLineBreaks?: boolean
};
export interface TableBreakpoints {
  firstPage: number;
  fullPage: number;
  firstPageWithBottomText: number;
}

export const DocumentTable: React.FC<Props> = ({
  headers,
  rows,
  cellStyle,
  useCourseContentLineBreaks
}) => {
  return (
    <table
      style={{ borderCollapse: "collapse", border: "solid 1px", width: "100%" }}
    >
      <thead>
        <tr>
          {headers.map((header, idx) => (
            <th
              key={`th-${idx}`}
              style={{
                fontSize: getResponsiveSize(19),
                border: "solid 1px",
                ...cellStyle,
              }}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIdx) => (
          <tr key={`tr-${rowIdx}`} style={{whiteSpace: useCourseContentLineBreaks ? "pre-line" : "normal"}}>
            {Object.values(row).map((value, valIdx) => (
              <td
                key={`td-${rowIdx}-${valIdx}`}
                style={{
                  fontSize: getResponsiveSize(19),
                  border: "solid 1px",
                  ...cellStyle,
                }}
              >
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

import { useMemo } from "react";
import { Box, alpha } from "@mui/material";
import {
  DocumentPage,
  DocumentTitle,
  DocumentSubtitle,
  DocumentParagraph,
  DocumentFooter,
  SignHereCard,
  SignatureCard,
} from "components";
import { useStyles } from "hooks";
import { CSSGenerator, DJPData, EventType } from "interfaces";
import {
  injectData,
  getResponsiveSize,
  formatSignDate,
  generateRecordItems,
  getEventMainText,
  humanFormatDate,
} from "utils";
import { DocumentComponentType, EventsPages } from ".";

export const DJPDocument: DocumentComponentType = ({
  data,
  openDialog,
  signed,
  settings,
}) => {
  const {
    title,
    subtitle,
    upperDescriptionText,
    lowerDescriptionText,
    footer,
  } = settings?.djpDocumentText ?? {};
  const styles = useStyles(generateStyles);

  const parsedData = useMemo(
    () => generateDJPReplaceableData(data.djpData),
    [data],
  );

  const parsedEvents = useMemo(
    () =>
      data?.document
        ? [
            ...generateRecordItems(data.document, data.document.events),
            {
              mainText: getEventMainText(
                {
                  type: EventType.SIGN,
                  personName: data?.djpData?.firstSigner.name ?? "",
                  personEmail: data?.djpData?.firstSigner.email ?? "",
                },
                data?.document,
              ),
              date: formatSignDate(new Date()),
              eventType: EventType.SIGN,
            },
          ]
        : [],
    [data],
  );

  return (
    <>
      <DocumentPage sx={styles.root}>
        <Box sx={styles.upperContainer}>
          <DocumentTitle text={title} />
          <DocumentSubtitle
            sx={styles.subtitle}
            text={injectData(subtitle, parsedData)}
          />
          <DocumentParagraph
            text={injectData(upperDescriptionText, parsedData)}
          />
          <DocumentParagraph
            text={injectData(lowerDescriptionText, parsedData)}
          />
          <Box sx={styles.signatureContainer}>
            <DocumentParagraph text="Capacitado(a)" />
            {signed ? (
              <>
                <SignatureCard
                  name={data?.djpData?.firstSigner.name ?? ""}
                  rut={data?.djpData?.firstSigner.rut ?? ""}
                  role="Capacitado(a)"
                />
                <Box>
                  <DocumentParagraph
                    sx={styles.signatureBottomText}
                    text={formatSignDate(new Date())}
                  />
                  <DocumentParagraph
                    sx={styles.signatureBottomText}
                    text={`Firmado mediante ${data?.djpData?.firstSigner.email}`}
                  />
                </Box>
              </>
            ) : (
              <SignHereCard
                name={data?.djpData?.firstSigner.name ?? ""}
                rut={data?.djpData?.firstSigner.rut ?? ""}
                role="Capacitado(a)"
                onClick={openDialog}
              />
            )}
          </Box>
        </Box>
        <Box>
          <DocumentFooter footNotes={[footer ?? ""]} />
        </Box>
      </DocumentPage>
      {signed && data.document && (
        <EventsPages items={parsedEvents} document={data.document} />
      )}
    </>
  );
};

const generateDJPReplaceableData = (
  data?: DJPData,
): Record<string, string | number> => {
  if (!data) return {};
  return {
    signDate: data.signDate,
    "firstSigner-rut": data.firstSigner.rut,
    "firstSigner-name": data.firstSigner.name,
    "firstSigner-address": data.firstSigner.address,
    "firstSigner-commune": data.firstSigner.commune,
    "firstSigner-city": data.firstSigner.city,
    "firstSigner-region": data.firstSigner.region,
    "otec-rut": data.otec.rut,
    "otec-name": data.otec.name,
    "course-name": data.course.name,
    "course-senceCode": data.course.senceCode,
    "course-sencenetCode": data.course.sencenet,
    "course-startDate": humanFormatDate(new Date(data.course.startDate)),
    "course-endDate": humanFormatDate(new Date(data.course.endDate)),
  };
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  upperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: getResponsiveSize(32),
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "column",
    gap: getResponsiveSize(21),
  },
  signatureBottomText: {
    color: alpha(theme.palette.common.black, 0.54),
  },
});

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Create } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { cloneDeep, map } from "lodash";
import {
  TextHeader,
  TextSubHeader,
  CustomButton,
  SignDialog,
  DocumentComponentMapping,
  DialogInfo,
  RecordIconType,
} from "components";
import { AppContext, FeedbackContext } from "contexts";
import { useStyles } from "hooks";
import {
  AppRoutes,
  CSSGenerator,
  DocumentCompanyStatus,
  DocumentType,
} from "interfaces";
import { getMassSignDialogInfo, getMassSignPageTitle } from "utils";
import {
  presignCompanyPrecontracts,
  usePrecontractsDataForCompanySignature,
} from "api";

export const MassPrecontractSignTemplatePage: React.FC = () => {
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const history = useHistory();
  const { user, settings } = useContext(AppContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [contentValidation, setContentValidation] = useState<boolean>(false);

  /* FETCH DOCUMENT DATA */
  const { sencenet } = useParams<{ sencenet: string }>();
  const { data, loading: loadingData } =
    usePrecontractsDataForCompanySignature(sencenet);

  useEffect(() => {
    toggleLoader(
      loadingData,
      "[MassPrecontractSignTemplatePage] Fetching document data",
    );
  }, [loadingData, toggleLoader]);

  const isCourseContentValidated = (courses: any[]) => {

    const validation = courses.every(obj =>
      Object.values(obj).every(prop => prop !== null && prop !== undefined)
    )

    setContentValidation(validation)

    console.log(contentValidation)


  }


  useEffect(() => {
    // Navigate away if uuid not provided
    if (!sencenet) {
      history.replace(AppRoutes.PRECONTRACTS);
      showSnackbar(
        "Ocurrió un error al cargar la plantilla del precontrato",
        "error",
      );

      return;
    }

    // Wait for query to completes
    if (loadingData) return;

    // Navigate away if not data
    if (!data) {
      history.replace(AppRoutes.PRECONTRACTS);
      showSnackbar(
        "Ocurrió un error al cargar la plantilla del precontrato",
        "error",
      );
      return;
    }

    if(data?.data?.precontractData){
      isCourseContentValidated(data?.data?.precontractData.courses ?? [])
    };
    
    

  }, [data, history, sencenet, loadingData, showSnackbar]);
  /***********************/

  const parsedData = useMemo(() => {
    if (!data?.data?.precontractData || !user) return null;
    // Clone data (api response is read-only)
    const clonedData = cloneDeep(data?.data);

    console.log('massprecontractsignpage', data.data)
    
    if(!clonedData.precontractData) return null;
    // Inject blank participant and current user in precontractData
    const enrichedPrecontractData = Object.assign(clonedData.precontractData, {
      participant: BLANK_PARTICIPANT,
      companyLegalRepresentative: {
        name: user?.name,
        rut: user?.rut,
      },
    });
    return {
      ...clonedData,
      ...enrichedPrecontractData,
    };
  }, [data?.data, user]);

  const pendingDocumentIds = useMemo(
    () =>
      map(
        data?.documents.filter(
          (d) => d.companyStatus === DocumentCompanyStatus.PENDING,
        ) ?? [],
        "id",
      ),
    [data?.documents],
  );

  

  const showMissingCourseContent = () => showSnackbar("Algunos cursos aun no tienen su contenido agregado", "error");

  const checkContent = () => {
    return contentValidation ? openDialog() : showMissingCourseContent()
  }

  const openDialog = useCallback(() => {
      setDialogOpen(true) 
  }, [setDialogOpen]);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const signTemplate = useCallback(async () => {
    toggleLoader(
      true,
      "[MassPrecontractSignTemplatePage] Presigning documents",
    );
    const presigningSuccesful = pendingDocumentIds.length
      ? await presignCompanyPrecontracts(pendingDocumentIds)
      : true;
    toggleLoader(
      false,
      "[MassPrecontractSignTemplatePage] Presigning documents",
    );
    if (!presigningSuccesful) {
      showSnackbar("Algo falló al prefirmar los precontratos", "error");
      return;
    }
    showSnackbar(
      pendingDocumentIds.length
        ? "La plantilla de los precontratos fue firmada con éxito"
        : "La plantilla de los precontratos ya había sido firmada",
      "success",
    );
    history.push(
      AppRoutes.MASS_PRECONTRACT_SIGN_PARTICIPANTS.replace(
        ":sencenet",
        sencenet,
      ),
    );
  }, [pendingDocumentIds, history, sencenet, showSnackbar, toggleLoader]);
  const styles = useStyles(generateStyles);

  const DocumentComponent = DocumentComponentMapping[DocumentType.PRECONTRACT];

  return (
    <Box>
      <TextHeader
        text={getMassSignPageTitle(data?.data.precontractData)}
        showAvatar={false}
      />
      <Paper sx={styles.containerHeader} elevation={4}>
        <DialogInfo
          icon={RecordIconType.PIN}
          items={getMassSignDialogInfo(data?.data.precontractData)}
        />
        <TextSubHeader
          text={
            "Paso 1: Valida tus datos y firma para generar los precontratos"
          }
        />
      </Paper>
      <Box sx={styles.background}>
        {parsedData && settings && (
          <DocumentComponent
            data={parsedData}
            openDialog={openDialog}
            signed={false}
            settings={settings}
            user={user}
            template
          />
        )}
      </Box>
      {pendingDocumentIds.length ? (
        <CustomButton
          sx={styles.signButton}
          variant="contained"
          onClick={checkContent}
          startIcon={<Create fontSize="inherit" />}
        >
          FIRMAR
        </CustomButton>
      ) : (
        <CustomButton
          sx={styles.signButton}
          variant="contained"
          onClick={signTemplate}
        >
          CONTINUAR
        </CustomButton>
      )}

      <SignDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSignClick={signTemplate}
        signerName={user?.name ?? ""}
      />
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  background: {
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    paddingTop: "calc(32px + 64px)", // gap + header
    paddingBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      // paddingTop: gap + header / 2 (lower section hidden)
      paddingTop: "calc(32px + 64px)",
    },
  },
  signButton: {
    position: "fixed",
    right: "16px",
    // top: half screen - half button + header
    top: "calc(50vh - 24px + 128px)",
    [theme.breakpoints.down("sm")]: {
      top: "unset",
      bottom: "24px",
    },
  },
  containerHeader: {
    width: "100%",
    margin: "65px 0px 5px 0px",
    /* boxShadow: "inset 0 -3px 0 0 cornflowerblue", */
  },
});

const BLANK_PARTICIPANT = {
  rut: "____________",
  name: "_______________________",
  email: "_______________________",
  age: "__",
  birthdate: "____________",
  nationality: "______",
  address: "_____________________",
  commune: "___________",
  city: "_________",
  region: "________",
};

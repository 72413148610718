import { gql } from "@apollo/client";
import { client } from "api";
import { IdentityFileData, IdentityFileType } from "interfaces";

const saveFaces = !!process.env.REACT_APP_SAVE_IDENTITY_FILE_FACES;

interface MutationParams {
  params: {
    uuid: string;
    files: IdentityFileData[];
  };
}
interface MutationResult {
  saveIdentityFileByUUID: boolean | null;
}

export const saveIdentityFileByUUID = async (
  params: MutationParams["params"],
): Promise<boolean | null> => {
  if (!params.files || !params.uuid) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SaveIdentityFileByUUID($params: SaveIdentityFileByUUIDParams!) {
        saveIdentityFileByUUID(params: $params)
      }
    `,
    variables: {
      params: {
        uuid: params.uuid,
        files: saveFaces
          ? params.files
          : params.files.filter((f) => f.type === IdentityFileType.COMBINED),
      },
    },
  });
  return !!response.data?.saveIdentityFileByUUID;
};

import { gql, useQuery } from "@apollo/client";
import { MetricsResponse, GetCourseMetricsParams } from "interfaces";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";

export const GET_COURSE_METRICS = ({
  startDate,
  endDate
}: GetCourseMetricsParams) => gql`
query GetMetrics($params: GetCourseMetricsParams!) {
  getMetrics(params: $params) {
      djOtec {
          Normal
          Medio
          Critico
      }
      djParticipante {
          Normal {
              Cursos
              Participantes
          }
          Medio {
            Cursos
            Participantes
        }
          Critico {
            Cursos
            Participantes
        }
      }
      lcePresencial {
          Normal
          Medio
          Critico
      }
  }
}
`;

export const useCourseMetrics = (
  params: GetCourseMetricsParams,
): {
  data: any;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getMetrics: MetricsResponse;
  }>(GET_COURSE_METRICS(params), {
    variables: { params },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_COURSE_METRICS] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });


  return {
    data,
    loading
  };
};
import { useContext, useCallback } from "react";
import { AppContext } from "contexts";
import { Document, Permission } from "interfaces";
import { validateDocumentActionByStatus, DocumentActions } from "utils";

export interface AvailableDocumentActions {
  /* DJO's */
  canViewDJO: boolean;
  canSignDJO: boolean;
  canUploadIdentityFileDJO: boolean;
  canUploadDJO: boolean;
  canRequestCorrectionDJO: boolean;
  canValidateDJO: boolean;
  canSendReminderDJO: boolean;
  canForceValidationDJO: boolean;
  canViewForcedValidationDJO: boolean;
  /* DJP's & Precontracts */
  canView: boolean;
  canManualUpload: boolean;
  canValidate: boolean;
  canMassiveSignPrecontract: boolean;
  canSendReminderToResponsable: boolean;
  canForceValidation: boolean;
  canViewForcedValidation: boolean;
  canReplace: boolean;
  canSendParticipantReminder: boolean;
}

type HookReturn = (document?: Document | null) => AvailableDocumentActions;

export const useAvailableDocumentActions = (): HookReturn => {
  const { isAllowedTo } = useContext(AppContext);

  const getActions = useCallback(
    (document?: Document | null) => {
      const { status } = document ?? {};
      return {
        /* DJO's */
        canViewDJO:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.VIEW) &&
          isAllowedTo([Permission.CAN_VIEW_OTEC_FILE]),
        canSignDJO:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.SIGN) &&
          isAllowedTo([Permission.CAN_SIGN_DJO]),
        canUploadIdentityFileDJO:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.UPLOAD_IDENTITY_FILE,
          ) &&
          isAllowedTo([Permission.CAN_SIGN_DJO]),
        canUploadDJO:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.MANUAL_UPLOAD,
          ) &&
          isAllowedTo([Permission.CAN_UPLOAD_DJO_FILE]),
        canRequestCorrectionDJO:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.VALIDATION) &&
          isAllowedTo([Permission.CAN_REQUEST_AMEND_DJO]),
        canValidateDJO:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.VALIDATION) &&
          isAllowedTo([Permission.CAN_VALIDATE_DJO]),
        canSendReminderDJO:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.SEND_REMINDER_TO_SIGNER,
          ) &&
          isAllowedTo([Permission.CAN_SEND_OTEC_MAIL]),
        canForceValidationDJO:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.FORCE_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_FORCE_VALIDATION]),
        canViewForcedValidationDJO:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.VIEW_FORCED_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_VIEW_OTEC_FILE]),
        /* DJP's & Precontracts */
        canView:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.VIEW) &&
          isAllowedTo(
            [
              Permission.CAN_VIEW_PARTICIPANT_FILE,
              Permission.CAN_VIEW_OTEC_FILE,
            ],
            false,
          ),
        canManualUpload:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.MANUAL_UPLOAD,
          ) &&
          isAllowedTo([Permission.CAN_UPLOAD_PARTICIPANT_FILE]),
        canValidate:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.VALIDATION) &&
          isAllowedTo(
            [Permission.CAN_REQUEST_AMEND, Permission.CAN_VALIDATE_FILE],
            false,
          ),
        canMassiveSignPrecontract:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.SIGN) &&
          isAllowedTo([Permission.CAN_SIGN_PRECONTRACT_COMPANY]),
        canSendReminderToResponsable:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.SEND_REMINDER_TO_RESPONSABLE,
          ) &&
          isAllowedTo([Permission.CAN_SEND_RESPONSABLE_MAIL]),
        canForceValidation:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.FORCE_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_FORCE_VALIDATION]),
        canViewForcedValidation:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.VIEW_FORCED_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_VIEW_PARTICIPANT_FILE]),
        canReplace:
          !!status &&
          validateDocumentActionByStatus(status, DocumentActions.REPLACE) &&
          isAllowedTo([Permission.CAN_UPLOAD_PARTICIPANT_FILE]),
        canSendParticipantReminder:
          !!status &&
          validateDocumentActionByStatus(
            status,
            DocumentActions.SEND_REMINDER_TO_SIGNER,
          ) &&
          isAllowedTo([Permission.CAN_SEND_PARTICIPANT_MAIL]),
      };
    },
    [isAllowedTo],
  );

  return getActions;
};

import jsPDF from "jspdf";
import { dancingScriptFont } from "./DancingScript-normal";
import { poppinsFont } from "./Poppins-normal";

export const generateNewPdf = (width: number, height: number) => {
  const pdf = new jsPDF("p", "px", [height, width]);
  pdf.addFileToVFS("Poppins-normal.ttf", poppinsFont);
  pdf.addFont("Poppins-normal.ttf", "Poppins", "normal");
  pdf.setFont("Poppins");
  pdf.addFileToVFS("DancingScript-normal.ttf", dancingScriptFont);
  pdf.addFont("DancingScript-normal.ttf", "Dancing Script", "normal");
  pdf.setFont("Poppins");
  return pdf;
};

import { gql } from "@apollo/client";
import { client } from "api";
import {
  GET_DJ_COURSE_DETAILS_QUERY,
  GET_PRECONTRACT_COURSE_DETAILS_QUERY,
} from "../course";
import { DocumentType } from "interfaces";

interface CommonParams {
  documentId: number;
  identityFileId?: number;
}
interface MutationOuterParams {
  params: CommonParams & {
    expirationDate?: Date | null;
  };
}
interface MutationInnerParams {
  params: CommonParams & {
    expirationDate?: string;
  };
}
interface RefetchParams {
  params: {
    sencenet: string;
    documentType: DocumentType;
  };
}
interface MutationResult {
  validateDocument: boolean | null;
}

export const validateDocument = async (
  params: MutationOuterParams["params"],
  refetchParams: RefetchParams["params"],
  documentType: DocumentType,
): Promise<boolean | null> => {
  if (!params.documentId) return false;

  const response = await client.mutate<MutationResult, MutationInnerParams>({
    mutation: gql`
      mutation ValidateDocument($params: ValidateDocumentParams!) {
        validateDocument(params: $params)
      }
    `,
    variables: {
      params: {
        ...params,
        expirationDate: params.expirationDate?.toISOString().slice(0, 10),
      },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query:
          documentType === DocumentType.PRECONTRACT
            ? GET_PRECONTRACT_COURSE_DETAILS_QUERY(documentType)
            : GET_DJ_COURSE_DETAILS_QUERY(documentType, refetchParams.sencenet),
        variables: {
          params: refetchParams,
        },
      },
    ],
  });
  return !!response.data?.validateDocument;
};

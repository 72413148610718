import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { CurrentUser, Permission, Setting } from "interfaces";
import { useCurrentUserQuery, useSettings } from "api";
import { FeedbackContext } from "contexts";

interface AppContextType {
  loading: boolean;
  permissions: Permission[];
  isAllowedTo: (p: Permission | Permission[], every?: boolean) => boolean;
  user: CurrentUser | null;
  settings: Setting | null;
}

export const AppContext = createContext({} as AppContextType);

export const AppProvider: React.FC = ({ children }) => {
  const { toggleLoader } = useContext(FeedbackContext);
  const { user, loading: currentUserLoading } = useCurrentUserQuery();
  const { settings, loading: settingsLoading } = useSettings();

  const isAllowedTo = useCallback(
    (p: Permission | Permission[], every = true) => {
      if (typeof p === "object") {
        return every
          ? p.every((perm) => user?.permissions.includes(perm))
          : p.some((perm) => user?.permissions.includes(perm));
      }
      return !!user?.permissions.includes(p);
    },
    [user?.permissions],
  );

  const loading = useMemo(() => currentUserLoading || settingsLoading, [
    currentUserLoading,
    settingsLoading,
  ]);

  const context = useMemo(
    () => ({
      loading,
      permissions: user?.permissions ?? [],
      isAllowedTo,
      user,
      settings,
    }),
    [loading, user, isAllowedTo, settings],
  );

  useEffect(() => {
    toggleLoader(loading, "AppContext data loading");
  }, [loading, toggleLoader]);
  return (
    <AppContext.Provider value={context}>
      <>{children}</>
    </AppContext.Provider>
  );
};

import React, { useMemo, useCallback } from "react";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { TableIconButtonCellProps } from "./interfaces";

export const IconButtonCell: React.FC<TableIconButtonCellProps> = ({
  iconData: externalIconData,
  getIconData,
  onClick,
  style = {},
  align = "left",
  disabled,
}) => {
  // Passing not relevant value to match the typing bc CustomTable will ignore it and pass the row id
  // TODO-5: best practice would be receive the row id as a prop
  const iconData = useMemo(() => getIconData?.(0) ?? externalIconData, [
    externalIconData,
    getIconData,
  ]);

  const onClickWrapper = useCallback(
    (event) => {
      event.stopPropagation();
      if (onClick) onClick();
    },
    [onClick],
  );

  if (!iconData)
    return <TableCell size={"small"} align={align} padding={"none"} />;
  return (
    <Tooltip title={iconData.tooltipLabel ?? ""}>
      <TableCell size={"small"} align={align} padding={"none"}>
        <IconButton
          sx={style}
          onClick={onClickWrapper}
          disabled={disabled || iconData.disabled}
        >
          {iconData.icon ?? ""}
        </IconButton>
      </TableCell>
    </Tooltip>
  );
};

import React from "react";

const DashboardCardBackground: React.FC<{ color: string }> = ({ color }) => {
  return (
    <svg
      viewBox="0 0 350 62"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        zIndex: 1,
      }}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="PDC---Empresa-KPI-1"
          transform="translate(-1060.000000, -453.000000)"
          fill={color}
        >
          <path
            d="M1060,471.329614 C1109.44335,469.255846 1170.07206,464.502562 1241.88613,457.069764 C1313.7002,449.636966 1369.73816,452.385315 1410,465.314809 L1410,511 C1410,513.209139 1408.20914,515 1406,515 L1064,515 C1061.79086,515 1060,513.209139 1060,511 L1060,471.329614 L1060,471.329614 Z"
            id="bg-green"
            transform="translate(1235.000000, 484.000000) scale(-1, -1) translate(-1235.000000, -484.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default DashboardCardBackground;

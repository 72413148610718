import { useStyles } from "hooks";
import { Box, TextField, TextFieldProps } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CSSGenerator } from "interfaces";

export const SearchBar = ({ ...props }: TextFieldProps) => {
  const styles = useStyles(generateStyles);

  return (
    <Box sx={styles.searchBox}>
      <SearchIcon sx={styles.searchIcon} />
      <TextField
        sx={styles.searchInput}
        variant="standard"
        size="small"
        {...props}
      />
    </Box>
  );
};

const generateStyles: CSSGenerator = () => ({
  searchInput: {
    width: "24vw",
    height: 48,
    justifyContent: "center",
    "& :before": {
      border: "none",
    },
    "& :after": {
      borderBottom: "unset",
    },
    "& :hover": {
      borderBottom: "unset",
    },
    "& :hover:not(.Mui-disabled):before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:hover:not(.Mui-disabled):before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:after": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:hover": {
      borderBottom: "unset",
    },
  },
  searchBox: {
    backgroundColor: "white",
    border: "0.5px solid",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    borderColor: "#9e9e9e",
  },
  searchIcon: { mr: 1, my: 0.5 },
});

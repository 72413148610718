import React, { useCallback, useState, useEffect } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { debounce } from "lodash";
import { validateEmail } from "utils";

type Props = TextFieldProps & {
  onErrorChange?: (error: boolean) => void;
  withLabel?: boolean;
};

const FORMAT_ERROR_MESSAGE = "Formato de email no válido";
const DEBOUNCE_TIME = 800;

export const EmailInput: React.FC<Props> = ({
  sx,
  value,
  onChange,
  onErrorChange,
  error,
  helperText,
  withLabel = true,
  ...props
}) => {
  // Variable to save error on email format
  const [formatError, setFormatError] = useState(false);

  const debouncedValidateFormat = useCallback(
    debounce(
      (value: string) => setFormatError(value !== "" && !validateEmail(value)),
      DEBOUNCE_TIME,
    ),
    [setFormatError],
  );

  const onChangeInner = useCallback(
    async (e: any) => {
      const value = (e.target.value as string).toLowerCase();
      setFormatError(false);
      debouncedValidateFormat(value);
      onChange?.({
        target: {
          name: e.target.name,
          value,
        },
      } as any);
    },
    [setFormatError, debouncedValidateFormat, onChange],
  );

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(formatError);
    }
  }, [onErrorChange, formatError]);

  return (
    <TextField
      sx={sx}
      id="outlined-required"
      name="email"
      label={withLabel ? "Email" : undefined}
      required
      value={value}
      onChange={onChangeInner}
      error={error || formatError}
      helperText={
        error || formatError
          ? helperText ?? (formatError ? FORMAT_ERROR_MESSAGE : "")
          : undefined
      }
      {...props}
    />
  );
};

import { OpenMessageDialogParams } from "contexts";
import {
  Course,
  CustomReminderType,
  DocumentParticipantInfo,
  DocumentStatus,
  DocumentStatusNames,
  DocumentType,
  EmailForm,
  CurrentUser,
  AppRoutes,
} from "interfaces";
import { map } from "lodash";
import {
  humanFormatDate,
  DocumentActions,
  getEmailSubjectOptions,
} from "utils";

export const getMessageDialogParams = (
  course: Course | null,
  docParticipantData: DocumentParticipantInfo,
  documentAction: DocumentActions,
  documentType: DocumentType,
  route: AppRoutes,
  onReminderEditionFinished: (
    id: number,
    emailData: EmailForm,
  ) => Promise<void>,
  user?: CurrentUser | null,
): OpenMessageDialogParams => {
  const {
    participant: { name, email, rut },
  } = docParticipantData;

  const { status } = docParticipantData.document;

  const coursesNames = map(docParticipantData.document.courses, "name");

  const data: OpenMessageDialogParams = {
    title: `Enviar recordatorio · RUT ${rut} · ${name} ${
      documentAction === DocumentActions.SEND_REMINDER_TO_RESPONSABLE
        ? `- ${DocumentStatusNames[status]}`
        : ""
    }`,
    stillOpenAt: route,
    email:
      documentAction === DocumentActions.SEND_REMINDER_TO_RESPONSABLE
        ? ""
        : email,
    subjectOptions: getEmailSubjectOptions(documentAction, status),
    onFinish: (emailData: EmailForm) =>
      onReminderEditionFinished(docParticipantData.id, emailData),
    emailDefaultData: {
      type:
        documentAction === DocumentActions.SEND_REMINDER_TO_RESPONSABLE
          ? coursesNames.length > 1
            ? CustomReminderType.REMINDER_TO_RESPONSABLE_MULTIPLE_COURSES
            : CustomReminderType.REMINDER_TO_RESPONSABLE_SINGLE_COURSE
          : documentType === DocumentType.DJP
          ? CustomReminderType.SIGN_DJP
          : CustomReminderType.SIGN_PRECONTRACT,
      data: {
        participantFullname: name,
        participantRut: rut,
        companyName: course?.company.name ?? "",
        courseName: coursesNames[0],
        coursesNames: coursesNames.map((name) => `· ${name}`).join("\n"),
        senderName: user?.name ?? "",
        senderRole: user?.role ?? "",
        documentName:
          documentType === DocumentType.PRECONTRACT
            ? "del Precontrato"
            : "de la Declaración Jurada",
        missingAction:
          status === DocumentStatus.CORRECTION_REQUESTED
            ? "corrección"
            : "firma" ?? "",
        managementTerm: course
          ? `${humanFormatDate(new Date(course.processEndDate))}`
          : "",
        missingActionParticipant:
          status === DocumentStatus.CORRECTION_REQUESTED
            ? "corregido"
            : "firmado" ?? "",
        correctionParagraph:
          status === DocumentStatus.CORRECTION_REQUESTED &&
          docParticipantData.lastCorrectionRequestComment
            ? `\nLa corrección solicitada ha sido la siguiente:\n${docParticipantData.lastCorrectionRequestComment}\n`
            : " ",
      },
    },
  };

  return data;
};

export const routesAreTheSame = (
  locationPath: string,
  currentLocationPath: string,
): Boolean => {
  const locationPathArray: string[] = locationPath.slice(1).split("/");
  const currentLocationPathArray: string[] = currentLocationPath
    .slice(1)
    .split("/");
  if (locationPathArray.length !== currentLocationPathArray.length)
    return false;
  return locationPathArray.every(
    (e, i) => e === currentLocationPathArray[i] || e.charAt(0) === ":",
  );
};

import React, { useContext } from "react";
import { AppContext } from "contexts";
import { Permission } from "interfaces";

type Props = {
  to: Permission | Permission[];
  every?: boolean;
};

// This component is meant to be used as wrapper everywhere a restriction based on user permission is needed
export const Restricted: React.FC<Props> = ({ to, every, children }) => {
  const { isAllowedTo } = useContext(AppContext);

  // If the user has that permission, render the children
  if (isAllowedTo(to, every)) {
    return <>{children}</>;
  }
  // Otherwise, do not render anything
  return null;
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routesAreTheSame } from "utils";

export const useOpenDialogAt = (
  keepOpenedAtPath: string,
  onClose: () => void,
) => {
  const location = useLocation();

  useEffect(() => {
    if (
      keepOpenedAtPath &&
      !routesAreTheSame(keepOpenedAtPath, location.pathname)
    ) {
      onClose();
    }
  }, [location.pathname, keepOpenedAtPath, onClose]);
};

import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "contexts";
import { PolledQuery, PollingIntervalDuration } from "../interfaces/settings";
import { DashboardDataByCompany } from "interfaces";

export const GET_DASHBOARD_DATA_QUERY = gql`
  query GetDashboardData {
    getDashboardData {
      globalData {
        company
        data {
          PRECONTRACT {
            HIGH {
              courses
              participants
            }
            MEDIUM {
              courses
              participants
            }
            LOW {
              courses
              participants
            }
          }
          DJO {
            HIGH {
              courses
            }
            MEDIUM {
              courses
            }
            LOW {
              courses
            }
          }
          DJP {
            HIGH {
              courses
              participants
            }
            MEDIUM {
              courses
              participants
            }
            LOW {
              courses
              participants
            }
          }
        }
      }
      text {
        DJP {
          HIGH
          MEDIUM
          LOW
        }
        DJO {
          HIGH
          MEDIUM
          LOW
        }
        PRECONTRACT {
          HIGH
          MEDIUM
          LOW
        }
      }
    }
  }
`;

export const useDashboardData = (): {
  data: DashboardDataByCompany | null;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);
  const { data, loading } = useQuery<{
    getDashboardData: DashboardDataByCompany;
  }>(GET_DASHBOARD_DATA_QUERY, {
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_DASHBOARD_DATA] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });
  return {
    data: data?.getDashboardData ?? null,
    loading,
  };
};

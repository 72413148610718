import { useParams, RouteComponentProps } from "react-router-dom";
import { MailOutline, InsertDriveFileOutlined } from "@mui/icons-material";
import { useRestrictedPage } from "hooks";
import {
  Permission,
  AppRoutes,
  DocumentType,
  DocumentStatus,
  DocumentCompanyStatus,
} from "interfaces";
import {
  TableCellType,
  TableColumnData,
  TableChipCellStyle,
  Order,
} from "components";
import { CourseDetailsPage } from "./CourseDetailsPage";
import { compareDocumentCompanyStatuses, compareDocumentStatuses } from "utils";

export const PrecontractDetailsPage: React.FC<RouteComponentProps> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_PRECONTRACTS, Permission.CAN_VIEW_COURSE],
    redirectTo: AppRoutes.DASHBOARD,
  });
  let { sencenet } = useParams<{ sencenet: string }>();
  return (
    <CourseDetailsPage
      columnDefs={columnDefs}
      documentType={DocumentType.PRECONTRACT}
      sencenet={sencenet}
      route={AppRoutes.PRECONTRACT_DETAILS}
    />
  );
};

const columnDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Nombre participante",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Rut",
    attr: "rut",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Correo electrónico",
    attr: "email",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Teléfono",
    attr: "phone",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Firma empresa",
    attr: "secondarySignerStatus",
    sortable: true,
    chipStyleType: TableChipCellStyle.PRIMARY,
    sortFn: (a: any, b: any, order: Order) => {
      const statusA = a["secondarySignerStatus"][2] as DocumentCompanyStatus;
      const statusB = b["secondarySignerStatus"][2] as DocumentCompanyStatus;
      return compareDocumentCompanyStatuses(statusA, statusB, order);
    },
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Firma participante",
    attr: "mainSignerStatus",
    onClick: undefined, // injected on component
    sortable: true,
    chipStyleType: TableChipCellStyle.PRIMARY,
    sortedByDefault: true,
    sortFn: (a: any, b: any, order: Order) => {
      const statusA = a["mainSignerStatus"][2] as DocumentStatus;
      const statusB = b["mainSignerStatus"][2] as DocumentStatus;
      return compareDocumentStatuses(statusA, statusB, order);
    },
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Autorización para menor de edad",
    attr: "minorAuthorizationStatus",
    onClick: undefined, // injected on component
    sortable: true,
    chipStyleType: TableChipCellStyle.PRIMARY,
    sortFn: (a: any, b: any, order: Order) => {
      const statusA = a["minorAuthorizationStatus"][2] as DocumentStatus;
      const statusB = b["minorAuthorizationStatus"][2] as DocumentStatus;
      return compareDocumentStatuses(statusA, statusB, order);
    },
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Vulnerabilidad",
    attr: "vulnerabilityStatus",
    onClick: undefined, // injected on component
    sortable: true,
    chipStyleType: TableChipCellStyle.PRIMARY,
    sortFn: (a: any, b: any, order: Order) => {
      const statusA = a["vulnerabilityStatus"][2] as DocumentStatus;
      const statusB = b["vulnerabilityStatus"][2] as DocumentStatus;
      return compareDocumentStatuses(statusA, statusB, order);
    },
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Útimo recordatorio",
    attr: "lastReminder",
    sortable: true,
  },
  {
    cellType: TableCellType.ICON_BUTTON,
    headerName: "",
    attr: "sendReminder",
    sortable: false,
    onClick: undefined, // injected on component
    iconData: {
      icon: <MailOutline />,
      tooltipLabel: "Enviar recordatorio",
    },
    style: {},
    align: "center",
    permissions: [
      Permission.CAN_SEND_RESPONSABLE_MAIL,
      Permission.CAN_SEND_PARTICIPANT_MAIL,
    ],
  },
  // {
  //   cellType: TableCellType.ICON_BUTTON,
  //   headerName: "",
  //   attr: "whatsApp",
  //   sortable: false,
  //   onClick: undefined, // injected on component
  //   iconData: {
  //     icon: <WhatsApp />,
  //     tooltipLabel: "Enviar recordatorio",
  //   },
  //   style: {},
  //   align: "center",
  // },
  {
    cellType: TableCellType.ICON_BUTTON,
    headerName: "",
    attr: "document",
    sortable: false,
    onClick: undefined, // injected on component
    iconData: {
      icon: <InsertDriveFileOutlined />,
      tooltipLabel: "Subir documento",
    },
    style: {},
    align: "center",
  },
];

import {
  // alpha,
  Box,
  DialogContent,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomDialog,
  DialogInfo,
  DialogInfoItem,
  // RecordIcon,
  RecordIconType,
  Dropzone,
  CustomIframe,
} from "components";
import { useCallback, useMemo, useState, useContext } from "react";
import { AppContext } from "contexts";
// import { DropzoneArea } from "material-ui-dropzone";
import { useStyles } from "hooks";
import { CSSGenerator, Permission } from "interfaces";
import { DialogActions } from "@mui/material";

type Props = {
  open: boolean;
  data: {
    courseName: string;
    sencenet: string;
    sence: string;
    dates: string;
    participantsNumber: string;
  };
  documentExists: boolean;
  title: string;
  onFinish: (file: File, callback: () => void) => void;
  onClose: () => void;
};

export const UploadFileDialog: React.FC<Props> = ({
  open,
  data,
  title,
  onFinish,
  onClose,
  documentExists,
}) => {
  const styles = useStyles(generateStyles);
  const { isAllowedTo } = useContext(AppContext);
  const [file, setFile] = useState<File | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>();
  const dialogInfoItems: DialogInfoItem[] = useMemo(
    () => [
      { title: "Curso", subtitle: data.courseName, sm: 4 },
      { title: "Sencenet", subtitle: data.sencenet },
      { title: "Código Sence", subtitle: data.sence },
      { title: "Inicio y término", subtitle: data.dates },
      { title: "Participantes activos", subtitle: data.participantsNumber },
    ],
    [data],
  );

    const validatePermisions = useCallback(
      (title) => {
        if (title.includes("Vulnerabilidad")) {
          return !isAllowedTo([Permission.CAN_UPLOAD_VULNERABILITY_FILE], false);
        }
        if (title.includes("Autorizacion")) {
          return !isAllowedTo([Permission.CAN_UPLOAD_MINORAUTH_FILE], false);
        }
        if (title.includes("Precontrato")) {
          return !isAllowedTo([Permission.CAN_UPLOAD_PARTICIPANT_FILE], false);
        }
      },[isAllowedTo]
    )

  const onFileChange = useCallback(
    (file: File) => {
      setFile(file ?? null);
      const fileReader = new FileReader();
      fileReader.onload = (content) => {
        if (!content.target?.result) return;
        setFilePreview(content.target?.result as string);
      };
      fileReader.readAsDataURL(file);
    },
    [setFile, setFilePreview],
  );
  const onDeleteFile = useCallback(() => {
    setFile(null);
    setFilePreview(null);
  }, [setFile, setFilePreview]);

  const onCloseWrapper = useCallback(() => {
    setFile(null);
    onClose();
  }, [setFile, onClose]);

  const onFinishClick = useCallback(() => {
    if (file) onFinish(file, onCloseWrapper);
  }, [file, onFinish, onCloseWrapper]);

  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={onCloseWrapper}
      maxWidth="xl"
      fullWidth
    >
      <DialogInfo
        icon={RecordIconType.PIN}
        items={dialogInfoItems}
      ></DialogInfo>
      <DialogContent sx={styles.dialogContent}>
        {!validatePermisions(title) ? (
          <>
            {!file && (
              <Dropzone
                onFileChange={onFileChange}
                accept="image/*, application/pdf"
              >
                <Box sx={styles.textContainer}>
                  <Typography>
                    Arrastra el archivo aquí o presiona para seleccionarlo
                  </Typography>
    
                  {documentExists && (
                    <Typography sx={styles.warningText}>
                      Atención: El documento actual se sobrescribirá
                    </Typography>
                  )}
                </Box>
              </Dropzone>
            )}
            {file && (
              <Box sx={styles.filePreviewContainer}>
                {file.type === "application/pdf" && (
                  <CustomIframe title="file-preview" src={filePreview || ""} />
                )}
                {file.type !== "application/pdf" && (
                  <img alt="file-preview" src={filePreview || ""} />
                )}
              </Box>
            )}
          </>
        ) : (
          <>
            <Box sx={styles.textContainer}>
              <Typography sx={styles.warningText}>
                Atención: No posee los permisos necesarios para subir este documento
              </Typography>
            </Box>
          </>
        )}

      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <CustomButton
          sx={styles.deleteButton}
          variant="text"
          onClick={onDeleteFile}
          disabled={!file}
        >
          ELIMINAR
        </CustomButton>
        <CustomButton variant="text" onClick={onCloseWrapper}>
          CANCELAR
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={onFinishClick}
          disabled={!file}
        >
          CARGAR
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    padding: "26px",
  },
  dialogContent: {
    cursor: "pointer",
    padding: "0px",
    display: "flex",
    justifyContent: "center",
  },
  dialogActions: {
    padding: "8px 24px",
  },
  filePreviewContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    overflowY: "hidden",

    "& > iframe": {
      width: "100%",
      height: "100%",
      minHeight: 600,
    },
    "& > img": {
      height: "100%",
      maxHeight: 600,
      objectFit: "cover",
    },
  },
  deleteButton: {
    marginRight: "auto",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
  },
  warningText: {
    color: theme.palette.error.main,
    fontSize: "14px",
  },
});

import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Toolbar,
  Theme,
  CSSObject,
  Box,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  FileCopy,
  LibraryBooks,
  InsertDriveFile,
} from "@mui/icons-material";
import { AppContext } from "contexts";
import { AppRoutes, CSSGenerator, Permission } from "interfaces";
import { useStyles } from "hooks";
import packageJson from "../../../package.json";

export const DRAWER_WIDTH = 400;

type Props = {
  open: boolean;
};

export const DrawerComponent: React.FC<Props> = ({ open }) => {
  const history = useHistory();
  const location = useLocation();
  const { isAllowedTo, settings } = useContext(AppContext);
  const styles = useStyles(generateStyles, open);
  const [selectedOption, setSelectedOption] = useState<AppRoutes>(
    AppRoutes.DASHBOARD,
  );

  const filteredOptions = useMemo(
    () =>
      drawerOptions.filter(
        (opt) =>
          !opt.permissions ||
          isAllowedTo(opt.permissions, !opt.atLeastOnePermission),
      ),
    [isAllowedTo],
  );

  const onClick = useCallback(
    (route: AppRoutes) => {
      const objRoute = { path: route };
      window.parent.postMessage(objRoute, "*");
      history.push(route);
    },
    [history],
  );

  const openDocument = useCallback(() => {
    if (!settings?.termsAndConditionsUrl) return;
    window.open(settings?.termsAndConditionsUrl, "_blank");
  }, [settings?.termsAndConditionsUrl]);

  useEffect(() => {
    setSelectedOption(location.pathname as AppRoutes);
  }, [location.pathname, setSelectedOption]);

  return (
    <Drawer variant="permanent" open={open} sx={styles.root}>
      <Box sx={styles.upperContainer}>
        <Toolbar />
        <List>
          {filteredOptions.map((el, index) => (
            <ListItem
              disablePadding={el.disablePadding || !open}
              key={`drawer-item-${index}`}
            >
              <ListItemButton
                onClick={() => {
                  onClick(el.route);
                }}
                selected={el.route === selectedOption}
              >
                <ListItemIcon>{el.icon}</ListItemIcon>
                <ListItemText primary={el.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {open && (
        <Box>
          {
            <ListItemButton onClick={openDocument}>
              <ListItemIcon>
                <LibraryBooks />
              </ListItemIcon>
              <ListItemText primary="Términos y condiciones" />
            </ListItemButton>
          }

          <Box sx={styles.lowerContainer}>
            <img
              style={{ width: "100px" }}
              src="/icons/simple.svg"
              alt="simple-logo"
            />
            <Typography sx={styles.version}>{packageJson.version}</Typography>
          </Box>
        </Box>
      )}
    </Drawer>
  );
};
type DrawerOption = {
  route: AppRoutes;
  name: string;
  disablePadding: boolean;
  icon: React.ReactNode;
  permissions?: Permission[];
  atLeastOnePermission?: boolean;
};

const drawerOptions: DrawerOption[] = [
  {
    route: AppRoutes.DASHBOARD,
    name: "Gestión de Firmas",
    disablePadding: true,
    icon: <Dashboard />,
    permissions: [
      Permission.CAN_ACCESS_PRECONTRACTS,
      Permission.CAN_ACCESS_DJS,
      Permission.CAN_ACCESS_DJS_ELEARNING,
      Permission.CAN_ACCESS_LCE_ONSITE,
    ],
    atLeastOnePermission: true,
  },
  {
    route: AppRoutes.PRECONTRACTS,
    name: "Precontratos",
    disablePadding: false,
    icon: <FileCopy />,
    permissions: [Permission.CAN_ACCESS_PRECONTRACTS],
  },
  {
    route: AppRoutes.SWORN_STATEMENTS,
    name: "Declaración Jurada - Distancia",
    disablePadding: false,
    icon: <InsertDriveFile />,
    permissions: [Permission.CAN_ACCESS_DJS],
  },
  {
    route: AppRoutes.SWORN_STATEMENTS_ELEARNING,
    name: "Declaración Jurada - Elearning",
    disablePadding: false,
    icon: <InsertDriveFile />,
    permissions: [Permission.CAN_ACCESS_DJS_ELEARNING],
  },
  {
    route: AppRoutes.LCE_ONSITE,
    name: "LCE - Presencial",
    disablePadding: false,
    icon: <InsertDriveFile />,
    permissions: [Permission.CAN_ACCESS_LCE_ONSITE],
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const generateStyles: CSSGenerator<boolean> = (theme, open) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
    "& div:first-of-type": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  upperContainer: {
    display: "block !important",
  },
  lowerContainer: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "22px",
  },
  version: {
    fontSize: "11px",
    lineHeight: 1,
  },
});

import { gql, useQuery } from "@apollo/client";
import { Historial } from "interfaces";
import { EventFragment } from "../fragments";

interface QueryResult {
  getDocumentHistorial: Historial | null;
}

interface HookReturn {
  data: Historial | null;
  loading: boolean;
}

export const GET_HISTORIAL_QUERY = gql`
  query GetDocumentHistorial($params: GetDocumentHistorialParams!) {
    getDocumentHistorial(params: $params) {
      document {
        id
        type
        status
        url
        participant
        otec
        companyStatus
      }
      comments {
        id
        text
        createdAt
        authorName
        authorRole
      }
      events {
        ...EventFields
      }
    }
  }
  ${EventFragment}
`;

export const useDocumentHistorial = (documentId?: number): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(GET_HISTORIAL_QUERY, {
    variables: {
      params: {
        documentId,
      },
    },
  });
  return { data: data?.getDocumentHistorial ?? null, loading };
};

import { Paper, PaperProps } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { getResponsiveSize } from "utils";

type Props = PaperProps & {
  children: React.ReactNode;
};

export const PAGE_DIMENSIONS = {
  width: "77.3vw",
  widthPercentage: 0.773,
  // aspectRatio: height/width (A4 o carta)
  aspectRatio: 297 / 210,
  // horizontalMarginPercentage: 30mm of 210mm
  horizontalMarginPercentage: 0.1131, // Disminuido para que quepa más contenido
  // verticalMarginPercentage: 25mm of 297mm
  verticalMarginPercentage: 0.08417,
};

export const DocumentPage: React.FC<Props> = ({ children, sx, ...props }) => {
  const styles = useStyles(generateStyles);
  return (
    <Paper
      id={"documentToPdf"}
      sx={{ ...styles.root, ...sx }}
      elevation={3}
      {...props}
    >
      {children}
    </Paper>
  );
};

const generateStyles: CSSGenerator = () => ({
  root: {
    width: PAGE_DIMENSIONS.width,
    height: `calc(${PAGE_DIMENSIONS.width} * ${PAGE_DIMENSIONS.aspectRatio})`,
    padding: `calc(${PAGE_DIMENSIONS.width} * ${PAGE_DIMENSIONS.aspectRatio} * ${PAGE_DIMENSIONS.verticalMarginPercentage}) 
              calc(${PAGE_DIMENSIONS.width} * ${PAGE_DIMENSIONS.horizontalMarginPercentage})`,
    display: "flex",
    flexDirection: "column",
    gap: getResponsiveSize(32),
  },
});

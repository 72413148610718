import { useState, useCallback, useContext, useEffect, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Box, Paper } from "@mui/material";
import { Create } from "@mui/icons-material";
import {
  CustomButton,
  SignDialog,
  DocumentComponentMapping,
  TextHeader,
  TextSubHeader,
  PAGE_DIMENSIONS,
} from "components";
import { useSignPageRedirect, useStyles } from "hooks";
import {
  CSSGenerator,
  DocumentType,
  AppRoutes,
  IdentityFile,
} from "interfaces";
import { generateNewPdf } from "config";
import { FeedbackContext, AppContext } from "contexts";
import { useDocumentDataByUUID, signDocumentByUUID } from "api";
import {
  getSignPageSignerData,
  getSignPageSubTitle,
  getSignPageTitle,
} from "utils";

export const SignPage: React.FC = () => {
  const { loading: loadingUser, user, settings } = useContext(AppContext);
  const history = useHistory();
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const signPageRedirect = useSignPageRedirect();

  /* FETCH DOCUMENT DATA */
  const { uuid } = useParams<{ uuid: string }>();

  const { data, loading: loadingData } = useDocumentDataByUUID(uuid, true);

  useEffect(() => {
    toggleLoader(loadingData, "[SignPage] Fetching document data");
  }, [loadingData, toggleLoader]);

  /*********************/
  const WIDTH = window.innerWidth * PAGE_DIMENSIONS.widthPercentage;
  const HEIGHT = WIDTH * PAGE_DIMENSIONS.aspectRatio;

  const title = useMemo(() => getSignPageTitle(data), [data]);

  const signerIdentityFile: IdentityFile | null = useMemo(() => {
    // Participants signs DJPs and PRECONTRACTs at this page
    if (data?.document?.type === DocumentType.DJP) {
      return data.djpData?.firstSigner.identityFile ?? null;
    }
    if (data?.document?.type === DocumentType.PRECONTRACT) {
      return data.precontractData?.participant.identityFile ?? null;
    }
    // OTEC's legal representative signs DJOs at this page
    if (data?.document?.type === DocumentType.DJO) {
      return data.djoData?.firstSigner.identityFile ?? null;
    }
    return null;
  }, [data]);

  const navigateNext = useCallback(() => {
    if (signerIdentityFile) {
      // Signer has an identity file
      history.replace(`${AppRoutes.SUCCESSFUL_SIGNATURE}/${uuid}`);
    } else {
      // We need to ask the signer for his identity file
      history.replace(
        AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(":uuid", uuid),
        data?.djoData?.course?.sencenet
          ? {
              refetchCourseDetailsQueryParams: {
                sencenet: data?.djoData?.course?.sencenet ?? "",
                documentType: DocumentType.DJP,
              },
            }
          : {},
      );
    }
  }, [data, uuid, signerIdentityFile, history]);

  useEffect(() => {
    // Wait for queries to complete
    if (loadingData || loadingUser) return;

    // Redirect to corresponding page according to the case
    signPageRedirect(data, signerIdentityFile);
  }, [data, loadingData, loadingUser, signPageRedirect, signerIdentityFile]);
  /***********************/

  const styles = useStyles(generateStyles);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const DocumentComponent = useMemo(
    () =>
      data && data?.document
        ? DocumentComponentMapping[data?.document.type]
        : null,
    [data],
  );

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const getPdfContent = useCallback(async (): Promise<Blob | null> => {
    if (!DocumentComponent || !data || !settings) return null;
    const htmlDocument = ReactDOMServer.renderToString(
      <DocumentComponent
        data={data}
        openDialog={openDialog}
        settings={settings}
        user={user}
        signed
      />,
    );
    const pdf = generateNewPdf(WIDTH, HEIGHT * 1.005);
    await pdf.html(htmlDocument);
    const pdfContent = pdf.output("blob");
    return pdfContent;
  }, [DocumentComponent, HEIGHT, WIDTH, data, openDialog, settings, user]);

  const signDocument = useCallback(async () => {
    toggleLoader(true, "[SignPage] Signing document");
    // Generate pdf
    const pdfContent = await getPdfContent();
    if (!pdfContent) {
      toggleLoader(false, "[SignPage] Signing document");
      showSnackbar("Algo falló al generar el contenido del PDF", "error");
      return;
    }
    // Sign document and send file
    let wasSigned;
    try {
      wasSigned = await signDocumentByUUID({
        uuid,
        file: new File([pdfContent], "file_name.pdf", {
          lastModified: new Date().getTime(),
          type: pdfContent.type,
        }),
      });
    } catch (e) {
      wasSigned = false;
    }
    if (!wasSigned) {
      toggleLoader(false, "[SignPage] Signing document");
      showSnackbar("Algo falló al firmar el documento", "error");
      return;
    }
    toggleLoader(false, "[SignPage] Signing document");
    setDialogOpen(false);
    navigateNext();
  }, [toggleLoader, uuid, getPdfContent, showSnackbar, navigateNext]);

  const signerData = useMemo(
    () => getSignPageSignerData(data, user),
    [user, data],
  );

  return (
    <Box>
      <TextHeader text={title} showAvatar={false} />

      <Paper sx={styles.containerHeader} elevation={4}>
        {/* TODO: texto del Paso de ser dinamico Paso 1 - Paso 2 etc...*/}
        <TextSubHeader
          text={"Paso 1: " + getSignPageSubTitle(data?.document?.type)}
        />
      </Paper>

      <Box sx={styles.background}>
        {DocumentComponent && data && settings && (
          <DocumentComponent
            data={data}
            openDialog={openDialog}
            signed={false}
            settings={settings}
            user={user}
          />
        )}
      </Box>
      <CustomButton
        sx={styles.signButton}
        variant="contained"
        onClick={openDialog}
        startIcon={<Create fontSize="inherit" />}
      >
        FIRMAR
      </CustomButton>
      <SignDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSignClick={signDocument}
        signerName={signerData?.name ?? ""}
      />
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  background: {
    backgroundColor: theme.palette.grey[100],
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    // paddingTop: gap + header
    paddingTop: "calc(32px + 128px)",
    paddingBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      // paddingTop: gap + header / 2 (lower section hidden)
      paddingTop: "calc(32px + 64px)",
    },
  },
  signButton: {
    position: "fixed",
    right: "16px",
    // top: half screen - half button + header
    top: "calc(50vh - 24px + 128px)",
    [theme.breakpoints.down("sm")]: {
      top: "unset",
      bottom: "24px",
    },
  },
  containerHeader: {
    width: "100vw",
    margin: "65px 0px 5px 0px",
    /* boxShadow: "inset 0 -3px 0 0 cornflowerblue", */
  },
});

import { gql } from "@apollo/client";

export const PrecontractDocumentDataFragment = gql`
  fragment PrecontractDocumentDataFields on PrecontractDocumentData {
    signDate
    processCoursesTotalHours
    processStartDate
    processFinishDate
    processDurationDays
    processVigencyDate
    participant {
      id
      rut
      name
      email
      age
      birthdate
      nationality
      address
      commune
      city
      region
      phone
      identityFile {
        id
        status
        expirationDate
      }
    }
    company {
      id
      rut
      name
      address
      city
      region
    }
    companyLegalRepresentative {
      id
      rut
      name
      email
      identityFile {
        id
        status
        expirationDate
      }
    }
    courses {
      id
      senceCode
      name
      otec {
        id
        name
      }
      location
      schedule
      duration
      startDate
      endDate
      participantsNumber
      registrationNumber
      sencenet 
      functions
      content
      functionshours
      adressSucCompany
    }
  }
`;

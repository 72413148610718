import { CriticalityLevel, DocumentStatus, Permission } from "interfaces";
import React from "react";
import { CSSObject } from "@mui/material";
import { Order } from ".";

/* TIPADOS AUXILIARES */
// Enum para identificar el tipo de celda
export enum TableCellType {
  TEXT = "TEXT",
  CHIP = "CHIP",
  ICON_BUTTON = "ICON_BUTTON",
  ICON = "ICON",
}

// Alineamientos de celdas
type CellAlignment = "center" | "left" | "right";

/* TIPADOS DE COLUMNAS */

export interface CellIconData {
  icon?: React.ReactNode;
  tooltipLabel?: string;
  disabled?: boolean;
}

// Props necesarias para una columna
export interface TableColumnData {
  headerName: string;
  attr: string;
  sortable?: boolean;
  sortFn?: (a: unknown, b: unknown, order: Order) => number;
  sortedByDefault?: boolean;
  sortedByDefaultDirection?: Order;
  cellType: TableCellType;
  onClick?: (id: number) => void;
  style?: CSSObject;
  align?: CellAlignment;
  getIconData?: (id: number) => CellIconData | null;
  iconData?: CellIconData;
  chipStyleType?: TableChipCellStyle;
  permissions?: Permission | Permission[];
  disabled?: boolean;
}

/* TIPADOS DE CELDAS */

// Props comunes de celda
interface TableCellCommonData {
  value: string | number | TableChipCellValue;
  type: TableCellType;
  onClick?: (id: number) => void;
  style?: CSSObject;
  getIconData?: (id: number) => CellIconData | null;
  iconData?: CellIconData;
  disabled?: boolean;
}

// TEXT CELL
// Props para celdas de tipo Texto
interface TableTextCellData extends TableCellCommonData {
  align?: CellAlignment;
}
export type TableTextCellProps = Omit<TableTextCellData, "type">;

// CHIP CELL
export enum TableChipCellType {
  DAYS = "DAYS",
  FRACTION = "FRACTION",
  DOCUMENT_STATUS = "DOCUMENT_STATUS",
  DEFAULT = "DEFAULT",
}

export enum TableChipCellStyle {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export type TableChipCellValue = [
  string,
  TableChipCellType,
  (CriticalityLevel | DocumentStatus)?,
];

// Props para celdas de tipo Chip
interface TableChipCellData extends TableCellCommonData {
  align?: CellAlignment;
  chipStyleType?: TableChipCellStyle;
  onClick?: (id: number) => void;
}

export type TableChipCellProps = Omit<TableChipCellData, "type">;

// ICON BUTTON CELL
// Props para celdas de tipo IconButton
interface TableIconButtonCellData extends TableCellCommonData {
  onClick?: () => void;
  align?: CellAlignment;
}

export type TableIconButtonCellProps = Omit<TableIconButtonCellData, "type">;

type TableIconCellData = TableCellCommonData;

export type TableIconCellProps = Omit<TableIconCellData, "type">;

/* TIPADOS GENERALES */

export interface TableRowData {
  id: number;
  cols: (TableTextCellData | TableChipCellData | TableIconButtonCellData)[];
  onRowClick?: (id: number) => void;
}

export interface TableData {
  dataSource: TableRowData[];
  columnDefs: TableColumnData[];
}

import { DialogActions, DialogContent } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { CustomDialog } from ".";
import { CustomButton } from "..";

export type InformationDialogProps = {
  text: string;
  title: string;
  open: boolean;
  onClose: () => void;
  closeButtonText?: string;
  onFinish?: (params: unknown) => void;
  finishButtonText?: string;
};

export const InformationDialog: React.FC<InformationDialogProps> = ({
  text,
  title,
  onClose,
  onFinish,
  open,
  closeButtonText = "CERRAR",
  finishButtonText = "ACEPTAR",
}) => {
  const styles = useStyles(generateStyles);
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={title}
      fullWidth
      maxWidth="md"
    >
      <DialogContent sx={styles.content}>{text}</DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>{closeButtonText}</CustomButton>
        {!!onFinish && (
          <CustomButton variant="contained" onClick={onFinish}>
            {finishButtonText}
          </CustomButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  content: {
    padding: "24px !important",
  },
});

import {
  DocumentCompanyStatus,
  DocumentCompanyStatusOrderPositions,
  DocumentStatus,
  DocumentStatusOrderPositions,
} from "interfaces";
import { Order, OrderFactor } from "components";

export enum DocumentActions {
  VALIDATION = "VALIDATION",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  SIGN = "SIGN",
  SUCCESSFUL_SIGN = "SUCCESSFUL_SIGN",
  OUTDATED_SIGN = "OUTDATED_SIGN",
  VIEW = "VIEW",
  SEND_REMINDER_TO_RESPONSABLE = "SEND_REMINDER_TO_RESPONSABLE",
  SEND_REMINDER_TO_SIGNER = "SEND_REMINDER_TO_SIGNER",
  FORCE_VALIDATION = "FORCE_VALIDATION",
  VIEW_FORCED_VALIDATION = "VIEW_FORCED_VALIDATION",
  REPLACE = "REPLACE",
  UPLOAD_IDENTITY_FILE = "UPLOAD_IDENTITY_FILE",
}

export const documentValidations: Record<DocumentActions, DocumentStatus[]> = {
  [DocumentActions.VIEW]: [
    DocumentStatus.CORRECTION_REQUESTED,
    DocumentStatus.PENDING_VALIDATION,
    DocumentStatus.VALIDATED,
  ],
  [DocumentActions.VALIDATION]: [DocumentStatus.PENDING_VALIDATION],
  [DocumentActions.MANUAL_UPLOAD]: [
    DocumentStatus.PENDING,
    DocumentStatus.CORRECTION_REQUESTED,
  ],
  [DocumentActions.SIGN]: [DocumentStatus.PENDING],
  [DocumentActions.SUCCESSFUL_SIGN]: [
    DocumentStatus.VALIDATED,
    DocumentStatus.FORCED_VALIDATED,
  ],
  [DocumentActions.OUTDATED_SIGN]: [
    DocumentStatus.OUTDATED,
    DocumentStatus.INACTIVE,
  ],
  [DocumentActions.SEND_REMINDER_TO_RESPONSABLE]: [
    DocumentStatus.PENDING,
    DocumentStatus.CORRECTION_REQUESTED,
  ],
  [DocumentActions.SEND_REMINDER_TO_SIGNER]: [DocumentStatus.PENDING],
  [DocumentActions.FORCE_VALIDATION]: [DocumentStatus.OUTDATED],
  [DocumentActions.VIEW_FORCED_VALIDATION]: [DocumentStatus.FORCED_VALIDATED],
  [DocumentActions.REPLACE]: [
    DocumentStatus.PENDING_VALIDATION,
    DocumentStatus.CORRECTION_REQUESTED,
  ],
  [DocumentActions.UPLOAD_IDENTITY_FILE]: [
    DocumentStatus.CORRECTION_REQUESTED,
    DocumentStatus.PENDING_VALIDATION,
  ],
};

export const validateDocumentActionByStatus = (
  status: DocumentStatus,
  action: DocumentActions,
): boolean => documentValidations[action].includes(status);

export const compareDocumentStatuses = (
  a: DocumentStatus,
  b: DocumentStatus,
  order: Order,
): number =>
  OrderFactor[order] *
  (DocumentStatusOrderPositions[a] - DocumentStatusOrderPositions[b]);

export const compareDocumentCompanyStatuses = (
  a: DocumentCompanyStatus,
  b: DocumentCompanyStatus,
  order: Order,
): number =>
  OrderFactor[order] *
  (DocumentCompanyStatusOrderPositions[a] -
    DocumentCompanyStatusOrderPositions[b]);

export enum IdentityFileStatus {
  PENDING_VALIDATION = "PENDING_VALIDATION",
  VALIDATED = "VALIDATED",
}

export interface IdentityFile {
  id: number;
  rut: string;
  url: string;
  status: IdentityFileStatus;
  expirationDate: string;
}

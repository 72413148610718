import {  useRef, useState, KeyboardEvent, Dispatch, SetStateAction, } from 'react'
import TextField from '@mui/material/TextField'
import { DateRange, Range } from 'react-date-range'

import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

type Props = {
  range: Range[];
  setRange: Dispatch<SetStateAction<Range[]>>;
}


const DateRangePicker: React.FC<Props> = ({range, setRange}) => {

  // open close
  const [open, setOpen] = useState<boolean>(false)

  // get the target element to toggle 
  const refOne = useRef<HTMLInputElement>(null)

  // hide dropdown on ESC press
  const hideOnEscape = (e: KeyboardEvent<HTMLImageElement>) => {
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  const checkedStartDate = range[0].startDate ? range[0].startDate : 0

  const checkedEndDate = range[0].endDate ? range[0].endDate : 0

  return (
    <div onKeyDown={hideOnEscape}>

      <TextField
        value={`${format(checkedStartDate, "dd/MM/yyyy")} a ${format(checkedEndDate, "dd/MM/yyyy")}`}
        label="Fecha de inicio y fin"
        style={{width: "100%!important"}}
        onClick={ () => setOpen(open => !open) }
      />
      <div style={{position: "relative"}} ref={refOne}>
        {open && 
          <DateRange
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
          />
        }
      </div>

    </div>
  )
}

export default DateRangePicker
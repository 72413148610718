import { Company, Otec, CriticalityLevel } from "interfaces";

export enum CourseFilterType {
  ELEARNING = "ELEARNING",
  ONSITE = "ONSITE",
}

export interface GetElearningCourseParams {
  startDate?: Date;
  endDate?: Date;
  type: CourseFilterType;
}

export interface GetUrlCourseFileParams {
  type: CourseFilterType,
  sencenet: number
}

export interface LastExecutionBotData {
  idLog: number;
  descripcion: string;
  fechaIngreso: Date;
  idAccion: number;
}

interface ElearningDjo {
  id: string;
  status: ElearningCourseStatus;
}

interface ElearningSupervision {
  id: string;
  status: string;
}

export interface ElearningCourse {
  id: string;
  purchaseOrder: number;
  internal: number;
  sencenet: string;
  name: string;
  office: string;
  active: boolean;
  startDate: Date;
  endDate: Date;
  djDaysLeft: number;
  djCriticalyLevel: CriticalityLevel;
  idStatusDjo: string;
  idStatusDjp: string;
  participantsNumber: number;
  djpNumber: number;
  cell: string;
  djo: ElearningDjo;
  supervision: ElearningSupervision;
  company: Company;
  otec: Otec;
  haveDocument: boolean;
}

export enum ElearningCourseStatus {
  PENDING = "E_PENDING",
  FINISHED = "E_FINISHED",
}

export const ElearningCourseStatusNames: Record<ElearningCourseStatus, string> =
  {
    [ElearningCourseStatus.PENDING]: "FALTANTE",
    [ElearningCourseStatus.FINISHED]: "EMITIDA",
  };

export interface ElearningParticipant {
  rutParticipante: string,
  nombreParticipante: string,
  apellidoPaterno: string,
  apellidoMaterno: string,
  estadoDjp: ParticipantDjpStatus,
}

export enum ParticipantDjpStatus {
  PENDING = "E_PENDING",
  FINISHED = "E_FINISHED",
}

export const ElearningParticipantStatusNames: Record<ParticipantDjpStatus, string> = {
  [ParticipantDjpStatus.PENDING]: "FALTANTE",
  [ParticipantDjpStatus.FINISHED]: "EMITIDA",
}

export interface CourseFileUrl {
  url: string,
}

export interface Metric {
  Critico: number,
  Medio: number,
  Normal: number,
}

export interface MetricParticipantes {
  Critico: {
    Cursos: number,
    Participantes: number
  },
  Medio: {
    Cursos: number,
    Participantes: number
  },
  Normal: {
    Cursos: number,
    Participantes: number
  },
}

export interface MetricsResponse {
  djOtec: Metric
  djParticipante: MetricParticipantes
  lcePresencial: Metric
}

export interface GetCourseMetricsParams {
  startDate: Date;
  endDate: Date;
}
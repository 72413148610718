import { gql } from "@apollo/client";
import { client, GET_HISTORIAL_QUERY } from "api";

interface MutationParams {
  params: {
    documentId: number;
    text: string;
  };
}
interface RefetchParams {
  params: {
    documentId: number;
  };
}
interface MutationResult {
  createComment: boolean | null;
}

export const createComment = async (
  params: MutationParams["params"],
  refetchParams: RefetchParams["params"],
): Promise<boolean | null> => {
  if (!params.documentId) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation CreateComment($params: CreateCommentParams!) {
        createComment(params: $params)
      }
    `,
    variables: {
      params,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_HISTORIAL_QUERY,
        variables: {
          params: refetchParams,
        },
      },
    ],
  });
  return !!response.data?.createComment;
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useCoursesSummary } from "api";
import { FeedbackContext, AppContext } from "contexts";
import {
  TabbedTable,
  TableCellType,
  FilterSection,
  TableColumnData,
  TableChipCellStyle,
  Order,
  DownloadButton,
} from "components";
import {
  CSSGenerator,
  CourseListTabs,
  CourseListFilters,
  Permission,
  AppRoutes,
  DocumentType,
  DocumentStatus,
} from "interfaces";
import { useStyles, useRestrictedPage } from "hooks";
import {
  parseActiveDjCoursesData,
  parseFinishedDjCoursesData,
  filterCourseListData,
  initialCourseFilters,
  compareDocumentStatuses,
  parseCoursesSummaryInfoToCSV,
  ParsedCoursesSummaryCSVInfo,
  filterColDefsBasedOnUser,
} from "utils";

export const SwornStatementPage: React.FC<RouteComponentProps> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_DJS, Permission.CAN_LIST_COURSES],
    redirectTo: AppRoutes.DASHBOARD,
  });
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { toggleLoader } = useContext(FeedbackContext);
  const { courses, loading } = useCoursesSummary({
    documentType: DocumentType.DJP,
  });
  const styles = useStyles(generateStyles);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [filters, setFilters] =
    useState<CourseListFilters>(initialCourseFilters);
  const activeCoursesData = useMemo(
    () =>
      parseActiveDjCoursesData(
        courses.filter((course) => course.active),
        history,
      ),
    [courses, history],
  );

  const finishedCoursesData = useMemo(
    () =>
      parseFinishedDjCoursesData(
        courses.filter((course) => !course.active),
        history,
      ),
    [courses, history],
  );

  const tabsInfo = useMemo(
    () => [
      {
        name: CourseListTabs.ACTIVE,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(activeColDefs, user),
          rawData: filterCourseListData(activeCoursesData, filters),
        },
      },
      {
        name: CourseListTabs.FINISHED,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(finishedColDefs, user),
          rawData: filterCourseListData(finishedCoursesData, filters),
        },
      },
    ],
    [activeCoursesData, finishedCoursesData, filters, user],
  );

  const getSelectedTab = useCallback(
    (selectedTab: string) => {
      setSelectedTab(selectedTab);
    },
    [setSelectedTab],
  );

  const CSV_HEADERS: {
    label: string;
    key: keyof ParsedCoursesSummaryCSVInfo;
  }[] = [
    { label: "Días de plazo", key: "daysLeft" },
    { label: "Fecha finalización del curso", key: "endDate" },
    { label: "Estado curso", key: "courseStatus" },
    { label: "N° de Inscripción", key: "registrationNumber" },
    { label: "Sencenet", key: "sencenet" },
    { label: "Nombre curso", key: "courseName" },
    { label: "Nombre empresa", key: "companyName" },
    { label: "DJP faltantes", key: "documentsRemaining" },
  ];

  const parsedExportableData: ParsedCoursesSummaryCSVInfo[] = useMemo(
    () => parseCoursesSummaryInfoToCSV(courses, DocumentType.DJP),
    [courses],
  );

  useEffect(() => {
    toggleLoader(loading, "SwornStatementsPage loading");
  }, [loading, toggleLoader]);

  return (
    <Box sx={styles.root}>
      <Typography variant="h6">Declaraciones Juradas - Distancia</Typography>
      <FilterSection
        data={
          selectedTab === CourseListTabs.FINISHED
            ? finishedCoursesData
            : activeCoursesData
        }
        filters={filters}
        setFilters={setFilters}
        useCriticalityFilters={selectedTab === CourseListTabs.ACTIVE}
        useCellFilter={user?.needFilterByCell}
      />
      <Box sx={styles.buttonContainer}>
        <DownloadButton
          data={parsedExportableData}
          headers={CSV_HEADERS}
          filename="lista-cursos-declaraciones-juradas.csv"
        />
      </Box>
      <TabbedTable tabsInfo={tabsInfo} getSelectedTab={getSelectedTab} />
    </Box>
  );
};

const generateStyles: CSSGenerator = () => ({
  root: { display: "flex", flexDirection: "column", gap: "24px" },
  buttonContainer: { display: "flex", justifyContent: "end" },
});

const activeColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.CHIP,
    headerName: "Días de plazo",
    attr: "daysLeft",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.PRIMARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "N° Inscripción",
    attr: "registrationNumber",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Estado DJO",
    attr: "djoStatus",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "DJP faltantes",
    attr: "remainingDjps",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
];

const finishedColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Fecha de cierre",
    attr: "endDate",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "N° Inscripción",
    attr: "registrationNumber",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Estado DJO",
    attr: "djoStatus",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
    sortFn: (a: any, b: any, order: Order) => {
      const statusA = a["djoStatus"][2] as DocumentStatus;
      const statusB = b["djoStatus"][2] as DocumentStatus;
      return compareDocumentStatuses(statusA, statusB, order);
    },
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "DJP firmadas",
    attr: "finishedDjps",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
];

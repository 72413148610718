import React, { createContext, useCallback, useMemo, useState } from "react";
import { EmailDefaultData, InformationDialog, MessageDialog } from "components";
import { EmailSubject, EmailForm } from "interfaces";
import { useOpenDialogAt } from "hooks";

interface MessageContextType {
  openMessageDialog: (params: OpenMessageDialogParams) => void;
  closeMessageDialog: () => void;
  openInformationDialog: (params: OpenInformationDialogParams) => void;
  closeInformationDialog: () => void;
}

export interface OpenMessageDialogParams {
  onlyCommentMode?: boolean;
  title: string;
  email?: string | null;
  subjectOptions?: EmailSubject[];
  onClose?: () => void;
  onFinish: (emailData: EmailForm) => void;
  emailDefaultData?: EmailDefaultData;
  stillOpenAt: string;
}

interface OpenInformationDialogParams {
  text: string;
  title: string;
  onClose?: () => void;
  closeButtonText?: string;
  onFinish?: (params: unknown) => void;
  finishButtonText?: string;
  stillOpenAt: string;
}

export const MessageContext = createContext({} as MessageContextType);

export const MessageProvider: React.FC = ({ children }) => {
  const [
    messageData,
    setMessageData,
  ] = useState<OpenMessageDialogParams | null>();
  const [
    infoData,
    setInfoData,
  ] = useState<OpenInformationDialogParams | null>();

  // Message Dialog callbacks
  const openMessageDialog = useCallback(
    (params: OpenMessageDialogParams) => {
      setMessageData(params);
    },
    [setMessageData],
  );

  const closeMessageDialog = useCallback(() => {
    setMessageData(null);
  }, [setMessageData]);

  const onCloseWrapperMessage = useCallback(() => {
    if (messageData?.onClose) messageData.onClose();
    closeMessageDialog();
  }, [messageData, closeMessageDialog]);

  const onFinishWrapperMessage = useCallback(
    (emailData: EmailForm) => {
      if (messageData?.onFinish) messageData.onFinish(emailData);
      closeMessageDialog();
    },
    [messageData, closeMessageDialog],
  );

  // Information Dialog callbacks
  const openInformationDialog = useCallback(
    (params: OpenInformationDialogParams) => {
      setInfoData(params);
    },
    [setInfoData],
  );

  const closeInformationDialog = useCallback(() => {
    setInfoData(null);
  }, [setInfoData]);

  const onCloseWrapperInformation = useCallback(() => {
    if (infoData?.onClose) infoData.onClose();
    closeInformationDialog();
  }, [infoData, closeInformationDialog]);

  const onFinishWrapperInformation = useCallback(
    (params: unknown) => {
      if (infoData?.onFinish) infoData.onFinish(params);
      closeInformationDialog();
    },
    [infoData, closeInformationDialog],
  );

  const context = useMemo(
    () => ({
      openMessageDialog,
      closeMessageDialog,
      openInformationDialog,
      closeInformationDialog,
    }),
    [
      openMessageDialog,
      closeMessageDialog,
      openInformationDialog,
      closeInformationDialog,
    ],
  );

  useOpenDialogAt(messageData?.stillOpenAt ?? "", closeMessageDialog);
  useOpenDialogAt(infoData?.stillOpenAt ?? "", closeInformationDialog);

  return (
    <MessageContext.Provider value={context}>
      <>
        {children}
        <MessageDialog
          open={!!messageData}
          onlyCommentMode={!!messageData?.onlyCommentMode}
          title={messageData?.title ?? ""}
          subjectOptions={messageData?.subjectOptions ?? []}
          email={messageData?.email ?? ""}
          onClose={onCloseWrapperMessage}
          onFinish={onFinishWrapperMessage}
          emailDefaultData={messageData?.emailDefaultData}
        />
        <InformationDialog
          text={infoData?.text ?? ""}
          title={infoData?.title ?? ""}
          open={!!infoData}
          onClose={onCloseWrapperInformation}
          onFinish={infoData?.onFinish ? onFinishWrapperInformation : undefined}
          closeButtonText={infoData?.closeButtonText}
          finishButtonText={infoData?.finishButtonText}
        />
      </>
    </MessageContext.Provider>
  );
};

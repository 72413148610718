import { FORCE_AUTH } from "config";
import React, { useState, createContext, useMemo } from "react";
import { getAccessToken } from "services";

interface AuthContextType {
  isLoggedIn: boolean;
}

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider: React.FC = ({ children }) => {
  const [isLoggedIn] = useState(FORCE_AUTH || !!getAccessToken());
  //const [isLoggedIn] = useState(true);

  const context = useMemo(() => ({ isLoggedIn }), [isLoggedIn]);

  return (
    <AuthContext.Provider value={context}>
      <>{children}</>
    </AuthContext.Provider>
  );
};

import { Palette } from "@mui/material";

export * from "./chip";

const customPalette: Partial<Palette> = {
  primary: {
    main: "#0B6A75",
    dark: "#003F49",
    light: "#4A98A4",
    contrastText: "#FFFFFFF",
  },
  secondary: {
    main: "#FEA525",
    dark: "#C67600",
    light: "#FFD65A",
    contrastText: "#FFFFFFF",
  },
};

export const customTheme = {
  palette: customPalette,
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(", "),
  },
};

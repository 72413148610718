import { ParticipantsApiResponse } from '../../../../api/src/interfaces/assistance-api.interface';
import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";


export const GET_PARTICIPANTS_DJP = ( sencenet: number ) => gql`
  query GetParticipantsDjp($sencenet: Int!) {
    getParticipantsDjp(sencenet: $sencenet) {
      nombreParticipante,
      apellidoPaterno,
      apellidoMaterno,
      rutParticipante,
      estadoDjp
    }
  }
`;


export const useParticipantsDjp = (
  sencenet: number,
): {
  data: any;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getParticipantsDjp: ParticipantsApiResponse;
  }>(GET_PARTICIPANTS_DJP(sencenet), {
    variables: { sencenet },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_PARTICIPANTS_DJP] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });

  return {
    data,
    loading
  };
};
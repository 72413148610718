import { DocumentStatus, EmailSubject } from "interfaces";
import { DocumentActions } from "utils";
import { map, uniq } from "lodash";

export const getEmailSubjectOptions = (
  documentAction: DocumentActions,
  status: DocumentStatus,
): EmailSubject[] => {
  const isResponsable =
    documentAction === DocumentActions.SEND_REMINDER_TO_RESPONSABLE;

  if (isResponsable) {
    return status === DocumentStatus.CORRECTION_REQUESTED
      ? [EmailSubject.REMINDER_REQUEST_CORRECTION]
      : [EmailSubject.REMINDER_TO_RESPONSABLE];
  }
  return [EmailSubject.REMINDER_REQUEST_SIGN];
};

export const parseMultipleEmails = (value: string): string[] => {
  const emails = value.split(/[,;\s]/);
  const formattedEmails = map(emails, (email) => email.trim()).filter(Boolean);

  return uniq(formattedEmails);
};

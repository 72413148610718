import { Box, Card, Divider, Typography, alpha } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { RecordIconType, RecordPNGIcon } from "components";
import { getResponsiveSize } from "utils";

type Props = {
  name: string;
  rut: string;
  role: string;
  precontractlegal?: boolean;
  companyname?: string;
  companyrut?: string;
  email?: string;
  phone?: string;
};

export const SignatureCard: React.FC<Props> = ({ name, rut, role, precontractlegal, companyname, companyrut, email, phone}) => {
  const styles = useStyles(generateStyles);
  return (
    <Card sx={styles.root}>
      <Box sx={styles.upperContainer}>
        <RecordPNGIcon
          icon={RecordIconType.LOCK}
          style={styles.iconBackground}
        />
        <Typography sx={styles.signedByText}>
          FIRMADO DIGITALMENTE POR
        </Typography>
      </Box>
      <span
        style={{
          fontFamily: "Dancing Script",
          fontSize: getResponsiveSize(28),
          maxHeight: getResponsiveSize(50),
          lineHeight: 1,
          textAlign: "center",
        }}
      >
        {name}
      </span>
      <Divider sx={styles.divider} />
      <Box sx={styles.dataContainer}>
        <Typography sx={styles.dataText}>{name}</Typography>
        <Typography sx={styles.dataText}>{rut}</Typography>
        <Typography sx={styles.dataText}>{email}</Typography>
        {precontractlegal ? <Typography sx={styles.dataText}>{companyname}</Typography> : null}
        {precontractlegal ? <Typography sx={styles.dataText}>{companyrut}</Typography> : null}
        {phone ? <Typography sx={styles.dataText}>{phone}</Typography> : null}
        <Typography sx={styles.dataText}>{role}</Typography>
      </Box>
    </Card>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    width: getResponsiveSize(378),
    height: getResponsiveSize(240),
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
    gap: getResponsiveSize(12),
    padding: getResponsiveSize(16),
  },
  upperContainer: {
    display: "flex",
    alignItems: "center",
    gap: getResponsiveSize(8),
    width: "100%",
    marginBottom: "auto",
  },
  signedByText: {
    color: theme.palette.primary.light,
    letterSpacing: getResponsiveSize(0.16),
    fontWeight: 600,
    fontSize: getResponsiveSize(16),
  },
  signature: {
    // fontFamily: "Dancing Script",
    // fontWeight: 500,
    fontSize: getResponsiveSize(30),
  },
  divider: {
    width: "100%",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signHereText: {
    fontWeight: 600,
    fontSize: getResponsiveSize(23),
  },
  dataText: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: getResponsiveSize(14),
  },
  icon: {
    fontSize: getResponsiveSize(16),
  },
  iconBackground: {
    width: getResponsiveSize(23),
    height: getResponsiveSize(23),
  },
});

import { Company, Course, IdentityFile, Otec, Participant } from "./api";
import { Event, Comment } from ".";
import { UtilityUser } from "./user";

export enum DocumentType {
  PRECONTRACT = "PRECONTRACT",
  DJO = "DJO",
  DJP = "DJP",
  APME = "APME",
  VULNERABILITY = "VULNERABILITY",
}

export enum DocumentFormat {
  PDF = "pdf",
  IMG = "img",
  EMPTY = "empty",
}

export enum DocumentStatus {
  PENDING = "PENDING",
  CORRECTION_REQUESTED = "CORRECTION_REQUESTED",
  PENDING_VALIDATION = "PENDING_VALIDATION",
  VALIDATED = "VALIDATED",
  INACTIVE = "INACTIVE",
  OUTDATED = "OUTDATED",
  WAITING_MODULAR = "WAITING_MODULAR",
  FORCED_VALIDATED = "FORCED_VALIDATED",
}

export enum DocumentCompanyStatus {
  PENDING = "PENDING", // Company has not signed
  PRESIGNED = "PRESIGNED", // Company has signed the template
  VALIDATED = "VALIDATED", // Company has signed template & participants list
  FORCED_VALIDATED = "FORCED_VALIDATED",
  OUTDATED = "OUTDATED",
}

export const DocumentStatusOrderPositions: Record<DocumentStatus, number> = {
  [DocumentStatus.PENDING]: 1,
  [DocumentStatus.CORRECTION_REQUESTED]: 2,
  [DocumentStatus.PENDING_VALIDATION]: 3,
  [DocumentStatus.VALIDATED]: 4,
  [DocumentStatus.FORCED_VALIDATED]: 5,
  [DocumentStatus.INACTIVE]: 0, // Should never appear at frontend
  [DocumentStatus.OUTDATED]: 0, // Should never appear at frontend
  [DocumentStatus.WAITING_MODULAR]: 0, // Should never appear at frontend
};

export const DocumentCompanyStatusOrderPositions: Record<
  DocumentCompanyStatus,
  number
> = {
  [DocumentCompanyStatus.PENDING]: 1,
  [DocumentCompanyStatus.PRESIGNED]: 2,
  [DocumentCompanyStatus.VALIDATED]: 3,
  [DocumentCompanyStatus.FORCED_VALIDATED]: 4,
  [DocumentCompanyStatus.OUTDATED]: 5,
};

export interface Document {
  id: number;
  type: DocumentType;
  status: DocumentStatus;
  url: string;
  uuid: string;
  participant: string; // Participant rut
  otec: string; // Otec rut
  company: string; // Company rut
  emissionDate: Date;
  manualUploaded: boolean;
  events: Event[];
  companyStatus: DocumentCompanyStatus;
  courses: Course[];
  signatureEmail?: string;
  otecSigner?: UtilityUser;
  forcedValidationComment?: string;
}

/*
  FIRST SIGNER:
  - DJO: OTEC
  - DJP: Participant
  - Precontract: Participant
*/
export interface DocumentCommonData {
  signDate: string;
  firstSigner: Participant;
  course: Course;
  otec: Otec;
}

export type DJPData = DocumentCommonData;

export interface DJOData extends DocumentCommonData {
  participants: Participant[];
}

export interface PrecontractDocumentData {
  signDate: string;
  processCoursesTotalHours: number;
  processStartDate: string;
  processFinishDate: string;
  processDurationDays: number;
  processVigencyDate: string;
  participant: Participant;
  company: Company;
  companyLegalRepresentative: {
    rut: string;
    name: string;
    email: string;
    identityFile?: IdentityFile;
  };
  courses: Course[];
}

export type DocumentData = {
  document?: Document;
  courseLiquidationDate: Date;
  // Data for each document type case (null-able)
  djpData?: DJPData;
  djoData?: DJOData;
  precontractData?: PrecontractDocumentData;
};

export interface Historial {
  document: Document;
  events: Event[];
  comments: Comment[];
}

import { useCallback, useMemo, useContext, useState } from "react";
import { DialogContent, Box } from "@mui/material";
import { Email, Check, UploadFile } from "@mui/icons-material";
import {
  CustomDialog,
  CustomButton,
  DialogInfo,
  DialogInfoItem,
  RecordIconType,
  FileView,
  ExpirationDatePicker,
  CustomIframe,
  Restricted,
} from "components";
import { useStyles } from "hooks";
import {
  CSSGenerator,
  Permission,
  Document,
  DocumentType,
  EmailForm,
  EmailSubject,
  DocumentStatusNames,
  Participant,
  Course,
} from "interfaces";
import { FeedbackContext, MessageContext } from "contexts";
import { requestCorrectionDocument, validateDocument } from "api";
import { useAvailableDocumentActions } from "hooks";
import {
  hasPendingValidationParticipantIdentityFile,
  getFileExtension,
  isDigitallySignedAndValidated,
  showExpirationDate,
  validateExpirationDateValidationDialog,
} from "utils";
import { DialogActions } from "@mui/material";

type Props = {
  open: boolean;
  stillOpenAt: string;
  course: Course | null;
  headerCourseData: {
    courseName: string;
    sencenet: string;
    sence: string;
    dates: string;
    participantsNumber: string;
  };
  participant?: Participant;
  title: string;
  onClose: () => void;
  document?: Document;
  sencenet: string;
  actionable: boolean;
  openUploadDialog?: () => void;
};

export const ValidationDialog: React.FC<Props> = ({
  open,
  stillOpenAt,
  headerCourseData,
  course,
  title,
  onClose,
  document,
  sencenet,
  actionable,
  participant,
  openUploadDialog,
}) => {
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const { openMessageDialog } = useContext(MessageContext);
  const getDocumentActions = useAvailableDocumentActions();
  const { canReplace } = getDocumentActions(document);

  const styles = useStyles(generateStyles);

  const dialogInfoItems = useMemo<DialogInfoItem[]>(
    () => [
      { title: "Curso", subtitle: headerCourseData.courseName, sm: 4 },
      { title: "Sencenet", subtitle: headerCourseData.sencenet },
      { title: "Código Sence", subtitle: headerCourseData.sence },
      { title: "Inicio y término", subtitle: headerCourseData.dates },
      {
        title: "Participantes activos",
        subtitle: headerCourseData.participantsNumber,
      },
    ],
    [headerCourseData],
  );

  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const showIdentityFileContainer = useMemo<boolean>(
    () =>
      (document?.type === DocumentType.DJO && !document?.manualUploaded) ||
      hasPendingValidationParticipantIdentityFile(document) ||
      isDigitallySignedAndValidated(document),
    [document],
  );

  const shownIdentityFile = useMemo(() => {
    if (!document || document?.manualUploaded) return null;
    if (document.type === DocumentType.DJO)
      return document.otecSigner?.identityFile;
    return participant?.identityFile ?? null;
  }, [participant, document]);

  const askForExpirationDate = useMemo<boolean>(
    () => showExpirationDate(document, participant),
    [document, participant],
  );

  const disableValidationButton = useMemo(() => {
    if (document?.manualUploaded) return false;
    if (!shownIdentityFile) return true;
    if (askForExpirationDate) return !validateExpirationDateValidationDialog(expirationDate, course!.liquidationDate);
    return false;
  }, [document, askForExpirationDate, expirationDate, shownIdentityFile, course]);

  const onCloseWrapper = useCallback(() => {
    setExpirationDate(null);
    onClose();
  }, [setExpirationDate, onClose]);

  const onClickValidate = useCallback(async () => {
    if (!document?.id) return;
    toggleLoader(true, "[ValidationDialog] Validating document");
    try {
    const wasValidated = await validateDocument(
      {
        documentId: document?.id,
        // Only send participant identity file ID to ve validated
        identityFileId: participant?.identityFile?.id,
        expirationDate,
      },
      {
        sencenet,
        documentType: document.type,
      },
      document?.type,
    );
    if (!wasValidated) {
      toggleLoader(false, "[ValidationDialog] Validating document");
      showSnackbar("Algo falló al validar el documento", "error");
      return;
    }
    toggleLoader(false, "[ValidationDialog] Validating document");
    showSnackbar("Documento validado exitosamente", "success");
    onCloseWrapper();
  } catch (e) {
    console.log(e);
    toggleLoader(false, "[ValidationDialog] Validating document");
    showSnackbar("Algo falló al validar el documento", "error");
    onCloseWrapper();
  }}, [
    document?.id,
    document?.type,
    toggleLoader,
    participant?.identityFile?.id,
    expirationDate,
    sencenet,
    showSnackbar,
    onCloseWrapper,
  ]);

  const requestCorrection = useCallback(
    async (emailData: EmailForm) => {
      if (!document?.id || !emailData.message) return;
      toggleLoader(true, "[ValidationDialog] Requesting correction document");
      const correctionRequestSuccesful = await requestCorrectionDocument(
        {
          documentId: document.id,
          comment: emailData.message,
          // TODO: handle multiple emails
          emails: emailData.email ? [emailData.email] : undefined,
        },
        {
          sencenet,
          documentType: document.type,
        },
        document?.type,
      );
      if (!correctionRequestSuccesful) {
        showSnackbar("Algo falló al solicitar una corrección", "error");
        toggleLoader(
          false,
          "[ValidationDialog] Requesting correction document",
        );
        return;
      }
      toggleLoader(false, "[ValidationDialog] Requesting correction document");
      showSnackbar("Solicitud de corrección realizada exitosamente", "success");
      onCloseWrapper();
    },
    [document, onCloseWrapper, sencenet, toggleLoader, showSnackbar],
  );

  const onRequestCorrectionClick = useCallback(() => {
    if (!document) return;
    // Get message from user before sending mutation
    openMessageDialog({
      onlyCommentMode: false,
      stillOpenAt,
      subjectOptions: [EmailSubject.REQUEST_CORRECTION],
      title: participant
        ? `Solicitar corrección · RUT ${participant?.rut} · ${
            participant?.name
          } · ${DocumentStatusNames[document.status]}`
        : "Solicitar corrección Declaración Jurada OTEC",
      onFinish: requestCorrection,
      //email: participant ? null : course?.otec.email
    });
  }, [
    document,
    openMessageDialog,
    participant,
    requestCorrection,
    stillOpenAt,
    course
  ]);

  if (!course) return null;
  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={onCloseWrapper}
      maxWidth="xl"
      fullWidth
    >
      <DialogInfo icon={RecordIconType.PIN} items={dialogInfoItems} />
      <DialogContent sx={styles.documentContainer}>
        {showIdentityFileContainer && (
          <FileView
            url={shownIdentityFile?.url}
            identityFileExtension={getFileExtension(shownIdentityFile?.url)}
          />
        )}
        {document?.url && (
          <CustomIframe
            title="Documento"
            src={document?.url ?? ""}
            hideNavpanes
            style={{ width: "100%", height: "100%", overflowX: "auto" }}
          />
        )}
      </DialogContent>

      {actionable && (
        <DialogActions sx={styles.actionContainer}>
          <Restricted
            to={[
              Permission.CAN_VALIDATE_FILE, 
              Permission.CAN_VALIDATE_DJO,
              Permission.CAN_VALIDATE_MINORAUTH,
              Permission.CAN_VALIDATE_VULNERABILITY
            ]}
            every={false}
          >
            {askForExpirationDate && (
              <Box sx={styles.datePickerContainer}>
                <ExpirationDatePicker
                  label="Vencimiento"
                  value={expirationDate}
                  onChange={(date: unknown) => setExpirationDate(date as Date)}
                  minDate={course.liquidationDate}
                />
              </Box>
            )}
          </Restricted>
          <Box sx={styles.buttonActions}>
            <Restricted
              to={[
                Permission.CAN_REQUEST_AMEND,
                Permission.CAN_REQUEST_AMEND_DJO,
                Permission.CAN_REQUEST_AMEND_MINORAUTH, 
                Permission.CAN_REQUEST_AMEND_VULNERABILITY
              ]}
              every={false}
            >
              <CustomButton
                onClick={onRequestCorrectionClick}
                variant="outlined"
                color="warning"
                startIcon={<Email />}
              >
                SOLICITAR CORRECCIÓN
              </CustomButton>
            </Restricted>
            <Restricted
              to={[
                Permission.CAN_VALIDATE_FILE, 
                Permission.CAN_VALIDATE_DJO, 
                Permission.CAN_VALIDATE_MINORAUTH,
                Permission.CAN_VALIDATE_VULNERABILITY
                ]}
              every={false}
            >
              <CustomButton
                onClick={onClickValidate}
                variant="contained"
                color="success"
                startIcon={<Check />}
                disabled={disableValidationButton}
              >
                VALIDAR DOCUMENTO
              </CustomButton>
            </Restricted>
            {canReplace && (
              <CustomButton
                onClick={openUploadDialog}
                variant="outlined"
                color="primary"
                startIcon={<UploadFile />}
              >
                REEMPLAZAR DOCUMENTO
              </CustomButton>
            )}
          </Box>
        </DialogActions>
      )}
    </CustomDialog>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  documentContainer: {
    display: "flex",
    padding: "0px",
    overflowY: "hidden",
    height: "550px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  buttonActions: {
    display: "flex",
    gap: "16px",
  },
  datePickerContainer: {
    left: "15px",
    position: "absolute",
  },
});

import { useCallback, useMemo } from "react";
import {
  Accordion,
  Avatar,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  Chip,
  Box,
  useTheme,
  GridProps,
  Stack
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import {
  useStyles,
} from "hooks";
import {
  ElearningCourse,
  CSSGenerator,
  AppRoutes,
  DocumentType,
  ElearningCourseStatusNames,
} from "interfaces";
import { getTableChipStyles } from "styles";
import {
  TableChipCellStyle,
  TableChipCellType as ChipType,
} from "./Table/interfaces";
import { useHistory } from "react-router-dom";
import {
  formatElearningCourseRangeDates,
} from "utils";

interface AccordionInfoProp {
  course: ElearningCourse;
  documentType: DocumentType;
  route: AppRoutes;
  courseType?: string;
}

export const AccordionInfoDetail = ({
  course,
  documentType,
  route,
  courseType
}: AccordionInfoProp) => {
  const styles = useStyles(generateStyles);
  const theme = useTheme();
  const history = useHistory();

  const isDJ = documentType !== DocumentType.PRECONTRACT;

  const parsedDates = useMemo(() => formatElearningCourseRangeDates(course), [course]);



  const chipDjoStatusStyle = useMemo(() => {
    if (course.djo?.status) {
      return getTableChipStyles(
        theme,
        ChipType["DOCUMENT_STATUS"],
        course.djo.status,
        TableChipCellStyle.PRIMARY,
        true,
      );
    }
  }, [course.djo?.status, theme]);

  const chipCriticalDaysStyle = useMemo(() => {
    if (course.djCriticalyLevel) {
      return getTableChipStyles(
        theme,
        ChipType["DAYS"],
        course.djCriticalyLevel,
        TableChipCellStyle.PRIMARY,
      );
    }
    return getTableChipStyles(
      theme,
      ChipType["DAYS"],
      course.djCriticalyLevel,
      TableChipCellStyle.PRIMARY,
    );
  }, [course, theme]);

  const onHistorialClick = useCallback(() => {
    history.push(`${history.location.pathname}/historial/`);
  }, [history]);

  const formatType = useMemo(() => {
      if(!courseType) return
      const lowercaseType = courseType?.toLowerCase();
      return lowercaseType?.charAt(0).toUpperCase() + lowercaseType?.slice(1)
      }, [courseType])


  return (
    <Accordion defaultExpanded sx={styles.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack sx={styles.stack} direction="row" spacing={2}>
          <Typography variant={"h6"} sx={styles.heading}>
            Detalle del curso
          </Typography>
          <Divider
            flexItem
            orientation="vertical"
            />
          <Typography variant={"h6"} sx={styles.heading}>
          {courseType && ` ${ formatType}`}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <Paper variant="outlined">
          <Grid container>
            {course && (
              <>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.bookAvatar}>
                      <BookOutlinedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <Grid sx={styles.infoGrid} item xs={10} sm={10} md={10}>
                    <Typography
                      variant={"caption"}
                      display={"block"}
                      sx={styles.itemHeader}
                    >
                      {"Curso"}
                    </Typography>
                    <Typography variant={"caption"}>{course.name}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="Sencenet"
                    itemValue={course.sencenet}
                  />
                  <AccordionGridItem
                    itemHeader="Inicio y término"
                    itemValue={parsedDates}
                  />

                  <Grid sx={styles.infoGrid} item xs={3} sm={3} md={3}>
                    <Typography
                      variant={"caption"}
                      display={"block"}
                      sx={styles.itemHeader}
                    >
                      Días de plazo
                    </Typography>
                    <Chip
                      label={course.djDaysLeft ?? 0}
                      sx={chipCriticalDaysStyle}
                    />
                  </Grid>

                  <AccordionGridItem
                    itemHeader="Célula"
                    itemValue={course.cell ?? "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.businessAvatar}>
                      <BusinessRoundedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <AccordionGridItem
                    itemHeader="Empresa"
                    itemValue={course.company?.name ?? "-"}
                    xs={10}
                    sm={10}
                    md={10}
                  />
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="RUT"
                    itemValue={course.company?.rut ?? "-"}
                  />
                  <AccordionGridItem
                    itemHeader="Holding"
                    itemValue={course.company?.holding ?? "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.schoolAvatar}>
                      <SchoolOutlinedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <AccordionGridItem
                    itemHeader="Otec"
                    itemValue={course.otec?.name ?? "-"}
                    xs={10}
                    sm={10}
                    md={10}
                  />
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="RUT"
                    itemValue={course.otec?.rut ?? "-"}
                    xs={isDJ ? 3 : 12}
                    sm={isDJ ? 3 : 12}
                    md={isDJ ? 3 : 12}
                  />
                  {isDJ && (
                    <>
                      <Grid sx={styles.infoGrid} item xs={6} sm={6} md={6}>
                        <Typography
                          variant={"caption"}
                          display={"block"}
                          sx={styles.itemHeader}
                        >
                          Estado
                          {!course.djo ? (
                            <strong> (Falta C.I.)</strong>
                          ) : null}
                        </Typography>
                        <Box>
                          <Chip
                            label={ElearningCourseStatusNames[course.djo?.status] ?? "-"}
                            sx={chipDjoStatusStyle}
                          />
                          
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </AccordionDetails>
      {isDJ && (
        <AccordionActions>
          <Button
            sx={styles.button}
            variant="outlined"
            onClick={onHistorialClick}
          >
            Ver Historial
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

const AccordionGridItem: React.FC<
  GridProps & {
    itemHeader?: string;
    itemValue?: string;
    xs?: number;
    sm?: number;
    md?: number;
  }
> = ({ itemHeader, itemValue, xs, sm, md, children }) => {
  const styles = useStyles(generateStyles);
  return (
    <Grid sx={styles.infoGrid} item xs={xs ?? 3} sm={sm ?? 3} md={md ?? 3}>
      {!!itemHeader && (
        <Typography
          variant={"caption"}
          display={"block"}
          sx={styles.itemHeader}
        >
          {itemHeader}
        </Typography>
      )}
      {children ??
        (!!itemValue && (
          <Typography variant={"caption"}>{itemValue}</Typography>
        ))}
    </Grid>
  );
};

const generateStyles: CSSGenerator = (theme: Theme) => ({
  root: {
    width: "100%",
  },
  accordion: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  heading: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bookIcon: {},
  bookAvatar: {
    margin: "auto",
    backgroundColor: "#e9f5fe",
    color: "#2196f3",
  },
  businessAvatar: {
    margin: "auto",
    backgroundColor: "rgba(254, 165, 37, 0.08)",
    color: theme.palette.secondary.main,
  },
  schoolAvatar: {
    margin: "auto",
    backgroundColor: "#edf6ed",
    color: theme.palette.success.main,
  },
  iconGrid: {
    minHeight: 71,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoGrid: {
    minHeight: 71,
    marginBottom: "14px",
  },
  itemHeader: {
    color: theme.palette.text.disabled,
  },
  grid: {
    marginTop: 0,
  },
  filterDiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
    alignItems: "center",
  },
  stack: {
    alignItems: "center",
    height: "fit-content",
  },
  button: {
    borderRadius: 18,
    height: 36,
  },
  buttonIdentityFile: {
    borderRadius: 18,
    height: 36,
    marginTop: "7px",
  },
  searchInput: {
    width: "24vw",
    height: 48,
    justifyContent: "center",
    "& :before": {
      border: "none",
    },
    "& :after": {
      borderBottom: "unset",
    },
    "& :hover": {
      borderBottom: "unset",
    },
    "& :hover:not(.Mui-disabled):before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:after": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:hover": {
      borderBottom: "unset",
    },
  },
  searchBox: {
    backgroundColor: "white",
    border: "0.5px solid",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    borderColor: "#9e9e9e",
  },
  participantsCount: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  djoEmailButton: {
    marginLeft: "10px",
  },
  tableIcons: {
    color: theme.palette.common.black,
  },
});
import { AppContext, FeedbackContext } from "contexts";
import {
  DocumentData,
  IdentityFile,
  AppRoutes,
  DocumentStatus,
  DocumentType,
} from "interfaces";
import { useCallback, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { validateDocumentActionByStatus, DocumentActions } from "utils";

type HookReturn = (
  data: DocumentData | null,
  signerIdentityFile: IdentityFile | null,
) => void;

export const useSignPageRedirect = (): HookReturn => {
  const { user } = useContext(AppContext);
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();
  const { showSnackbar } = useContext(FeedbackContext);

  const redirectionCallback = useCallback(
    (data: DocumentData | null, signerIdentityFile: IdentityFile | null) => {
      // Navigate away if uuid not provided
      if (!uuid) {
        history.replace(AppRoutes.DASHBOARD);
        return;
      }

      // Navigate away if not data or not document
      if (!data || !data.document) {
        history.replace(AppRoutes.DASHBOARD);
        return;
      }

      // Navigate away not logged user when signing a DJO
      if (data.document.type === DocumentType.DJO && !user) {
        history.replace(AppRoutes.DASHBOARD);
        showSnackbar("Debe iniciar sesión para firmar una DJO", "error");
        return;
      }
      // DocumentStatus OUTDATED or INACTIVE
      if (
        validateDocumentActionByStatus(
          data.document.status,
          DocumentActions.OUTDATED_SIGN,
        )
      ) {
        history.replace(`${AppRoutes.OUTDATED_SIGNATURE}/${uuid}`);
        return;
      }
      // Document manually uploaded
      if (data.document.manualUploaded) {
        history.replace(`${AppRoutes.SUCCESSFUL_SIGNATURE}/${uuid}`);
        return;
      }
      // DocumentStatus PENDING_VALIDATION
      if (data.document.status === DocumentStatus.PENDING_VALIDATION) {
        if (signerIdentityFile) {
          // Signer has an identity file
          history.replace(`${AppRoutes.SUCCESSFUL_SIGNATURE}/${uuid}`);
        } else {
          // We need to ask the signer for his identity file
          history.replace(
            AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(":uuid", uuid),
          );
        }
        return;
      }
      // DocumentStatus CORRECTION_REQUESTED (assumes that correction is requested due to CI error)
      if (data.document.status === DocumentStatus.CORRECTION_REQUESTED) {
        history.replace(
          AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(":uuid", uuid),
        );
        return;
      }
      // DocumentStatus VALIDATED or FORCED_VALIDATED
      if (
        validateDocumentActionByStatus(
          data.document.status,
          DocumentActions.SUCCESSFUL_SIGN,
        )
      ) {
        history.replace(`${AppRoutes.SUCCESSFUL_SIGNATURE}/${uuid}`);
        return;
      }
    },
    [history, showSnackbar, user, uuid],
  );
  return redirectionCallback;
};

import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const REFRESH_TOKEN_API_URL = process.env.REACT_APP_REFRESH_TOKEN_API_URL || "";
const REFRESH_TOKEN_API_PATH =
  process.env.REACT_APP_REFRESH_TOKEN_API_PATH || "";

interface Access {
  roles: string[];
}
interface TokenPayload {
  acr: string;
  aud: string[];
  azp: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  realm_access: Access;
  resource_access: Record<string, Access>;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
}

interface RefreshTokenResponse {
  code: number;
  message: string;
  result: {
    access_token: string;
  };
}

const instance = axios.create({
  baseURL: REFRESH_TOKEN_API_URL,
  headers: { "Content-Type": "application/json" },
});

export const getAccessToken = (): string =>
  Cookies.get("otic.token.private") || "";

export const getRefreshToken = (): string =>
  Cookies.get("otic.refreshToken.private") || "";

export const setAccessToken = (value: string): void => {
  Cookies.set("otic.token.private", value);
};

export const setRefreshToken = (value: string): void => {
  Cookies.set("otic.refreshToken.private", value);
};

export const decodeToken = (token: string): TokenPayload =>
  jwt_decode<TokenPayload>(token);

const isTokenExpired = (token: string): boolean => {
  try {
    const decoded = decodeToken(token);
    return decoded.exp < new Date().getTime() / 1000;
  } catch (e) {
    console.error(e);
    return true;
  }
};

const redirectToLoginPage = (): void => {
  window.location.href = process.env.REACT_APP_HOMEPAGE_URL ?? "";
};

const refreshAccessToken = async (): Promise<string | null> => {
  const refreshToken = getRefreshToken();
  try {
    const response = await instance.post<RefreshTokenResponse>(
      REFRESH_TOKEN_API_PATH,
      { token: refreshToken },
    );
    const { code, message, result } = response.data;
    if (code === 0) {
      const { access_token } = result;
      setAccessToken(access_token);
      return access_token;
    } else {
      throw new Error(`${code} ${message}`);
    }
  } catch (e) {
    console.error("COULD NOT REFRESH ACCESS TOKEN", e);
    // Only redirect logged users
    if (refreshToken) redirectToLoginPage();
    return null;
  }
};

export const getSendableToken = async (): Promise<string | null> => {
  if (process.env.REACT_APP_FORCE_AUTH) return null;
  const token = getAccessToken();
  // Participants should not have refreshToken
  const refreshToken = getRefreshToken();
  if (!refreshToken) return null;

  if (!token || isTokenExpired(token)) {
    return await refreshAccessToken();
  }
  return token;
};

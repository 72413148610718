import { useCallback, useEffect, useState, useMemo, useContext } from "react";
import { CustomDialog } from ".";
import {
  CSSGenerator,
  EmailSubject,
  EmailSubjectNames,
  EmailForm,
  CustomReminderType,
} from "interfaces";
import {
  FormControl,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  TextField,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useStyles } from "hooks";
import { CustomButton, EmailInput } from "..";
import { AppContext } from "contexts";
import { injectData } from "utils";

export type EmailDefaultData = {
  type: CustomReminderType;
  data: Record<string, string>;
};

export type MessageDialogProps = {
  open: boolean;
  onlyCommentMode: boolean;
  title: string;
  subjectOptions: EmailSubject[] | null;
  email: string | null;
  onClose: () => void;
  onFinish: (emailData: EmailForm) => void;
  emailDefaultData?: EmailDefaultData;
};

export const MessageDialog: React.FC<MessageDialogProps> = ({
  open,
  onlyCommentMode,
  title,
  subjectOptions,
  email,
  onClose,
  onFinish,
  emailDefaultData,
}) => {
  const { settings } = useContext(AppContext);
  const styles = useStyles(generateStyles);
  const [messageForm, setMessageForm] = useState<EmailForm>({
    subjectOption: null,
    email: "",
    message: "",
  });

  const [emailError, setEmailError] = useState(false);

  const messageLength = useMemo( () => {
      if(messageForm.message.length >= 10 && messageForm.message.length <= 1500) {
        return false
      }
      else {
        return true 
      }
      
    },[messageForm]
  )

  const disableContinueButton = useMemo(
    () =>
      messageLength ||
      (!onlyCommentMode &&
        (!messageForm.subjectOption || emailError || !messageForm.email)),
    [onlyCommentMode, messageForm, emailError, messageLength],
  );

  // TODO: set default value according to selected subjectOption
  const onFormChange = useCallback(
    (event) => {
      setMessageForm((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    [setMessageForm],
  );

  const onContinueClick = useCallback((_event: any) => onFinish(messageForm), [
    onFinish,
    messageForm,
  ]);

  // Set initial values of email, subject and message
  useEffect(() => {
    setMessageForm({
      subjectOption: subjectOptions?.[0] ?? null,
      email: email ?? "",
      message: emailDefaultData
        ? (injectData(
            settings?.customReminderTexts[emailDefaultData.type] ?? "",
            emailDefaultData.data,
            false,
          ) as string)
        : "",
    });
  }, [subjectOptions, email, setMessageForm, settings, emailDefaultData]);

  return (
    <CustomDialog
      open={open}
      title={title}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent>
        <Box sx={styles.content}>
          {!onlyCommentMode && (
            <>
              <Box sx={styles.rowContainer}>
                <Box sx={styles.labelContainer}>
                  <Typography>Para</Typography>
                </Box>
                <EmailInput
                  value={messageForm.email}
                  onChange={onFormChange}
                  onErrorChange={setEmailError}
                  withLabel={false}
                  name="email"
                  variant="standard"
                  fullWidth
                />
              </Box>
              <Box sx={styles.rowContainer}>

                <Typography>Asunto</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    value={messageForm.subjectOption}
                    name="subjectOption"
                    onChange={onFormChange}
                  >
                    {subjectOptions &&
                      subjectOptions.map((option, idx) => (
                        <FormControlLabel
                          value={option}
                          key={`subject-${idx}`}
                          control={<Radio />}
                          label={EmailSubjectNames[option]}
                        />
                      ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          )}
          <Box sx={styles.columnContainer}>
            <Box sx={styles.rowContainer}>
            <Typography>
              {messageForm.subjectOption
                ? MessageAreaTitles[messageForm.subjectOption]
                : "Mensaje"}
            </Typography>
            <Typography>
              {`${messageForm.message.length}/${1500}`}
            </Typography>
            </Box>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              sx={styles.messageContainer}
              InputProps={{ sx: styles.messageInput }}
              value={messageForm.message}
              name="message"
              onChange={onFormChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <CustomButton variant="text" onClick={onClose}>
          CANCELAR
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={onContinueClick}
          disabled={disableContinueButton}
        >
          ENVIAR
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

const MessageAreaTitles: Record<EmailSubject, string> = {
  [EmailSubject.REQUEST_CORRECTION]: "Observaciones del documento",
  [EmailSubject.REQUEST_IDENTIFICATION]: "Mensaje",
  [EmailSubject.REQUEST_SIGN]: "Mensaje",
  [EmailSubject.REMINDER_REQUEST_SIGN]: "Mensaje",
  [EmailSubject.REMINDER_REQUEST_CORRECTION]: "Mensaje",
  [EmailSubject.REMINDER_TO_RESPONSABLE]: "Mensaje",
};

const generateStyles: CSSGenerator = (theme) => ({
  content: {
    height: "450px",
    paddingTop: "24px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  rowContainer: {
    display: "flex",
    gap: "38px",
    alignItems: "center",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    flexGrow: 1,
  },
  messageContainer: {
    flexGrow: 1,
    alignItems: "start",
  },
  messageInput: {
    height: "100%",
    alignItems: "start",
  },
  labelContainer: {
    display: "flex",
    gap: "8px",
  },
  optional: {
    color: theme.palette.error.light,
    fontWeight: 300,
  },
});

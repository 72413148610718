import { Toolbar, Typography } from "@mui/material";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";

type Props = {
  text: string;
};

export const TextSubHeader: React.FC<Props> = ({ text }) => {
  const styles = useStyles(generateStyles);
  return (
    <Toolbar sx={styles.root}>
      <Typography sx={styles.text}>{text}</Typography>
    </Toolbar>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
});

import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { getSendableToken } from "services";

export * from "./assistence";
export * from "./course";
export * from "./user";
export * from "./document";
export * from "./mail";
export * from "./identityFile";
export * from "./useDashboardData.query";
export * from "./useSettings.query";
export * from "./createComment.mutation";

const authLink = setContext(async (_, { headers }) => {
  const token = await getSendableToken();
  if (!token) return { headers };
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

export const client = new ApolloClient({
  link: from([authLink, uploadLink]),
  cache: new InMemoryCache({
    // addTypename: false,
  }),
});

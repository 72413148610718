import { useState, useCallback, useContext, useMemo } from "react";
import { Alert, Box, Typography, useMediaQuery } from "@mui/material";
import { CustomButton, Dropzone, ExpirationDatePicker } from "components";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { AppContext } from "contexts";
import { validateExpirationDate, validateExpirationDateValidationDialog } from "utils";
import {
  IdentificationFileData,
  IdentificationFilePreview,
} from "pages/IdentificationFileUploadPage";

type Props = {
  filePreview: IdentificationFilePreview | null;
  minExpirationDate?: Date | null;
  onFileChange: (section: keyof IdentificationFileData, file: File) => void;
  onDeleteFile: () => void;
  onFileUpload: (expirationDate: Date | null) => void;
  processing: boolean;
};

export const IdentificationFileUpload: React.FC<Props> = ({
  filePreview,
  minExpirationDate,
  onFileChange,
  onDeleteFile,
  onFileUpload,
  processing,
}) => {
  const styles = useStyles(generateStyles);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useContext(AppContext);
  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const disableUploadButton = useMemo(
    () =>
      processing ||
      !(filePreview?.front && filePreview?.back) ||
      (!!user && !validateExpirationDate(expirationDate)),
    [expirationDate, user, processing, filePreview],
  );
  const onDateChange = useCallback(
    (date) => {
      setExpirationDate(date);
    },
    [setExpirationDate],
  );
  return (
    <Box sx={styles.root}>
      <Box sx={styles.infoContainer}>
        <Alert sx={styles.alert} severity="info">
          {`Sube una foto legible de la parte frontal y posterior de tu cédula ${
            user ? "e ingresa su fecha de vencimiento" : ""
          } `}
        </Alert>
        {user && !isMobile && (
          <ExpirationDatePicker
            label="Vencimiento"
            value={expirationDate}
            onChange={onDateChange}
            minDate={minExpirationDate}
          />
        )}
      </Box>
      <Box sx={styles.infoContainer}>
        <Alert sx={styles.alert} severity="info">
          Su cédula y datos personales serán guardados de forma confidencial y
          solo podrán ser usados para la presentación del documento firmado ante
          SENCE
        </Alert>
        {user && isMobile && (
          <ExpirationDatePicker
            label="Vencimiento"
            value={expirationDate}
            onChange={onDateChange}
            minDate={minExpirationDate}
          />
        )}
      </Box>
      <Box sx={styles.dropzoneContainer}>
        {/* FRONT PICTURE */}
        {filePreview?.front ? (
          <Box sx={styles.filePreviewContainer}>
            <img
              alt="file-preview"
              src={filePreview.front ?? ""}
              style={{
                aspectRatio: "initial",
                objectFit: "contain",
              }}
            />
          </Box>
        ) : (
          <Dropzone
            onFileChange={(file: File) => {
              onFileChange("front", file);
            }}
            accept="image/*"
          >
            <Box sx={styles.dropzoneContent}>
              <img style={{ width: "100px" }} src="/icons/CI.svg" alt="CI" />
              <Typography sx={styles.text}>
                Arrastra y suelta la foto <b>frontal</b> de tu CI
              </Typography>
              <CustomButton variant="outlined" sx={styles.uploadButton}>
                SELECCIONA LA FOTO DESDE TU EQUIPO
              </CustomButton>
            </Box>
          </Dropzone>
        )}
        {/* BACK PICTURE */}
        {filePreview?.back ? (
          <Box sx={styles.filePreviewContainer}>
            <img
              alt="file-preview"
              src={filePreview.back ?? ""}
              style={{
                aspectRatio: "initial",
                objectFit: "contain",
              }}
            />
          </Box>
        ) : (
          <Dropzone
            onFileChange={(file: File) => {
              onFileChange("back", file);
            }}
            accept="image/*"
          >
            <Box sx={styles.dropzoneContent}>
              <img style={{ width: "100px" }} src="/icons/CI.svg" alt="CI" />
              <Typography sx={styles.text}>
                Arrastra y suelta la foto <b>trasera</b> de tu CI
              </Typography>
              <CustomButton variant="outlined" sx={styles.uploadButton}>
                SELECCIONA LA FOTO DESDE TU EQUIPO
              </CustomButton>
            </Box>
          </Dropzone>
        )}
      </Box>

      <Box sx={styles.buttonContainer}>
        <CustomButton onClick={onDeleteFile}>ELIMINAR TODO</CustomButton>
        <CustomButton
          onClick={() => onFileUpload(expirationDate)}
          variant="contained"
          disabled={disableUploadButton}
        >
          CARGAR
        </CustomButton>
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    height: "100%",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  alert: {
    width: "100%",
  },
  dropzoneContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    height: "calc(100% - 128px)",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    },
  },
  dropzoneContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    flex: 1,
  },
  text: {
    textAlign: "center",
  },
  uploadButton: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  filePreviewContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    width: "45%",
    height: "100%",
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      height: "50%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    gap: "16px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
  },
});

export enum IdentityFileType {
  COMBINED = "COMBINED",
  FRONT_FACE = "FRONT_FACE",
  BACK_FACE = "BACK_FACE",
}

export interface IdentityFileData {
  type: IdentityFileType;
  file: File;
}

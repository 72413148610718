import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "contexts";
import { CurrentUser } from "interfaces";

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      rut
      name
      email
      phone
      role
      permissions
      needFilterByCell
      otec {
        id
        rut
      }
      identityFile {
        id
        url
      }
    }
  }
`;

export const useCurrentUserQuery = (): {
  user: CurrentUser | null;
  loading: boolean;
} => {
  const { isLoggedIn } = useContext(AuthContext);
  const { data, loading } = useQuery<{
    getCurrentUser: CurrentUser | null;
  }>(GET_CURRENT_USER_QUERY, { skip: !isLoggedIn });
  return {
    user: data?.getCurrentUser ?? null,
    loading,
  };
};

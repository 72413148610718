import React, { createContext, useCallback, useMemo, useState } from "react";
import { UploadFileDialog, ValidationDialog } from "components";
import { Document, Participant, ParticipantStatus, Course } from "interfaces";
import { useOpenDialogAt } from "hooks";

interface DialogInfoParams {
  courseName: string;
  sencenet: string;
  sence: string;
  dates: string;
  participantsNumber: string;
}

interface CommonDialogParams {
  title: string;
  onClose: () => void;
  // onFinish: () => void;
}

type ValidationParams = {
  common: CommonDialogParams;
  headerCourseData: DialogInfoParams;
  course: Course | null;
  participant?: Participant;
  sencenet: string;
  document?: Document;
  actionable: boolean;
  stillOpenAt: string;
  openUploadDialog?: () => void;
};

export interface UploadParams {
  common: CommonDialogParams & {
    onFinish: (file: File, callback: () => void) => void;
  };
  courseData: DialogInfoParams;
  documentExists: boolean;
  stillOpenAt: string;
}

interface DocumentDialogContextType {
  openValidationDialog: (params: ValidationParams) => void;
  openUploadDialog: (params: UploadParams) => void;
  closeValidationDialog: () => void;
  closeUploadDialog: () => void;
}

export const DocumentDialogContext = createContext(
  {} as DocumentDialogContextType,
);

export const DocumentDialogProvider: React.FC = ({ children }) => {
  const [validationData, setValidationData] = useState<ValidationParams>(
    INITIAL_VALIDATION_DATA,
  );

  const [uploadData, setUploadData] =
    useState<UploadParams>(INITIAL_UPLOAD_DATA);

  const [validationOpen, setValidationOpen] = useState<boolean>(false);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);

  const openValidationDialog = useCallback(
    (params: ValidationParams) => {
      setValidationData(params);
      setValidationOpen(true);
    },
    [setValidationData, setValidationOpen],
  );

  const openUploadDialog = useCallback(
    (params: UploadParams) => {
      setUploadData(params);
      setUploadOpen(true);
    },
    [setUploadData, setUploadOpen],
  );

  const closeValidationDialog = useCallback(() => {
    setValidationOpen(false);
    setValidationData(INITIAL_VALIDATION_DATA);
  }, [setValidationData, setValidationOpen]);

  const closeUploadDialog = useCallback(() => {
    setUploadOpen(false);
    setUploadData(INITIAL_UPLOAD_DATA);
  }, [setUploadData, setUploadOpen]);

  const context = useMemo(
    () => ({
      openValidationDialog,
      openUploadDialog,
      closeValidationDialog,
      closeUploadDialog,
    }),
    [
      openValidationDialog,
      openUploadDialog,
      closeValidationDialog,
      closeUploadDialog,
    ],
  );

  useOpenDialogAt(validationData.stillOpenAt, closeValidationDialog);
  useOpenDialogAt(uploadData.stillOpenAt, closeUploadDialog);

  return (
    <DocumentDialogContext.Provider value={context}>
      <>
        {children}
        <ValidationDialog
          open={validationOpen}
          {...validationData?.common}
          {...validationData}
          headerCourseData={validationData?.headerCourseData}
          openUploadDialog={validationData?.openUploadDialog}
        />
        <UploadFileDialog
          open={uploadOpen}
          {...uploadData.common}
          data={uploadData.courseData}
          documentExists={uploadData.documentExists}
        />
      </>
    </DocumentDialogContext.Provider>
  );
};

const INITIAL_VALIDATION_DATA: ValidationParams = {
  common: {
    title: "",
    onClose: () => {},
  },
  course: null,
  headerCourseData: {
    courseName: "",
    sencenet: "",
    sence: "",
    dates: "",
    participantsNumber: "",
  },
  participant: {
    id: 0,
    name: "",
    firstname: "",
    firstLastname: "",
    secondaryLastname: "",
    rut: "",
    email: "",
    alternativeEmail: "",
    phone: "",
    alternativePhone: "",
    age: 0,
    birthdate: "",
    nationality: "",
    address: "",
    commune: "",
    city: "",
    region: "",
    status: ParticipantStatus.ACTIVE,
    identityFile: undefined,
    hasMissingData: false,
  },
  document: undefined,
  sencenet: "",
  actionable: false,
  stillOpenAt: "",
};

const INITIAL_UPLOAD_DATA: UploadParams = {
  common: {
    title: "",
    onClose: () => {},
    onFinish: (file: File, callback: () => void) => {},
  },
  courseData: {
    courseName: "",
    sencenet: "",
    sence: "",
    dates: "",
    participantsNumber: "",
  },
  stillOpenAt: "",
  documentExists: false,
};

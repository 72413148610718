import { gql } from "@apollo/client";

export const DJODocumentDataFragment = gql`
  fragment DJODocumentDataFields on DJODocumentData {
    signDate
    firstSigner {
      id
      rut
      name
      address
      commune
      city
      region
      identityFile {
        id
        status
        expirationDate
      }
    }
    otec {
      id
      rut
      name
    }
    course {
      id
      name
      senceCode
      sencenet
      startDate
      endDate
    }
    participants {
      id
      rut
      firstname
      firstLastname
      secondaryLastname
    }
  }
`;

import React from "react";
import { alpha, Box, Divider, Paper, Typography } from "@mui/material";
import { Circle, Error, SvgIconComponent } from "@mui/icons-material";
import { useStyles } from "hooks";
import { CriticalityLevel, CSSGenerator } from "interfaces";
import DashboardCardBackground from "./DashboardCardBackground";

type Props = {
  criticalityLevel: CriticalityLevel;
  deadlineText: string;
  courseData: number;
  participantsData?: number;
};

const DeadlineIcon: Record<CriticalityLevel, SvgIconComponent> = {
  [CriticalityLevel.HIGH]: Error,
  [CriticalityLevel.MEDIUM]: Circle,
  [CriticalityLevel.LOW]: Circle,
  [CriticalityLevel.NONE]: Error,
};

const SimpleDataBox: React.FC<{ courseData: number }> = ({ courseData }) => {
  const styles = useStyles(generateSimpleBoxStyles);
  return (
    <Box sx={styles.root}>
      <Typography sx={styles.data}>{courseData}</Typography>
      <Typography sx={styles.description}>CURSOS</Typography>
    </Box>
  );
};

const DoubleDataBox: React.FC<{
  courseData: number;
  participantsData: number;
}> = ({ courseData, participantsData }) => {
  const styles = useStyles(generateDoubleBoxStyles);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Typography sx={styles.data}>{courseData}</Typography>
        <Typography sx={styles.description}>CURSOS</Typography>
      </Box>
      <Divider orientation="vertical" />
      <Box sx={styles.container}>
        <Typography sx={styles.data}>{participantsData}</Typography>
        <Typography sx={styles.description}>PARTICIPANTES</Typography>
      </Box>
    </Box>
  );
};

export const DashboardCard: React.FC<Props> = ({
  criticalityLevel,
  deadlineText,
  courseData,
  participantsData,
}) => {
  const styles = useStyles(generateCardStyles, criticalityLevel);
  const Icon = DeadlineIcon[criticalityLevel];
  return (
    <Paper sx={styles.root}>
      <DashboardCardBackground color={styles.backgroundSvg.fill as string} />
      {participantsData ? (
        <DoubleDataBox
          courseData={courseData}
          participantsData={participantsData}
        />
      ) : (
        <SimpleDataBox courseData={courseData} />
      )}
      <Box sx={styles.deadlineContainer}>
        <Icon sx={styles.icon} />
        <Typography sx={styles.deadlineText}>{deadlineText}</Typography>
      </Box>
    </Paper>
  );
};

const generateSimpleBoxStyles: CSSGenerator = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  data: {
    fontSize: "46px",
    fontWeight: 600,
  },
  description: {
    fontSize: "13px",
  },
});

const generateDoubleBoxStyles: CSSGenerator = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    zIndex: 2,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  data: {
    fontSize: "46px",
    fontWeight: 600,
  },
  description: {
    fontSize: "13px",
  },
});

const generateCardStyles: CSSGenerator<CriticalityLevel> = (
  theme,
  criticalyLevel,
) => {
  const color =
    criticalyLevel === CriticalityLevel.HIGH
      ? theme.palette.error
      : criticalyLevel === CriticalityLevel.MEDIUM
      ? theme.palette.warning
      : theme.palette.success;
  return {
    root: {
      padding: "16px 32px",
      width: "350px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "30px",
      position: "relative",
    },
    backgroundSvg: {
      fill: alpha(color.main, 0.1),
    },
    deadlineContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    deadlineText: {
      fontSize: "13px",
      fontWeight: 500,
      color:
        criticalyLevel === CriticalityLevel.HIGH
          ? theme.palette.error.light
          : theme.palette.text.primary,
    },
    icon: {
      color: color.main,
    },
  };
};

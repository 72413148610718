export enum AppRoutes {
  DASHBOARD = "/gestion-de-firmas",
  PRECONTRACTS = "/precontratos",
  SWORN_STATEMENTS = "/declaraciones-juradas",
  SWORN_STATEMENTS_ELEARNING = "/declaraciones-juradas-elearning",
  LCE_ONSITE = "/lce-presencial",
  // WARNING: route defined in api-firma source code (update in both packages)
  SIGN_DOCUMENT = "/firmar-documento/:uuid/documento",
  UPLOAD_IDENTIFICATION_FILE = "/firmar-documento/:uuid/cedula-identidad",
  SUCCESSFUL_SIGNATURE = "/firma-exitosa",
  OUTDATED_SIGNATURE = "/fuera-de-plazo",
  PRECONTRACT_DETAILS = "/precontratos/:sencenet",
  SWORN_STATEMENT_DETAILS = "/declaraciones-juradas/:sencenet",
  SWORN_STATEMENT_ELEARNING_DETAILS = "/declaraciones-juradas-elearning/:sencenet",
  LCE_ONSITE_DETAILS = "/lce-presencial/:sencenet",
  MASS_PRECONTRACT_SIGN_TEMPLATE = "/firma-masiva/:sencenet/plantilla",
  MASS_PRECONTRACT_SIGN_PARTICIPANTS = "/firma-masiva/:sencenet/participantes",
  SWORN_STATEMENT_HISTORIAL = "/declaraciones-juradas/:sencenet/historial/:documentId",
  PRECONTRACT_HISTORIAL = "/precontratos/:sencenet/historial/:documentId",
  ELEARNING_HISTORIAL = "/declaraciones-juradas-elearning/:sencenet/historial/",
  ONSITE_HISTORIAL = "/lce-presencial/:sencenet/historial/",
}

import {  useContext, useEffect, useMemo, useState } from "react";
import {
  Divider,
  Theme,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import { Group } from "@mui/icons-material";
import {
  useElearningCoursesSummary,
  useParticipantsDjp
} from "api";
import { useHistory } from "react-router-dom";
import {
  FeedbackContext,
} from "contexts";
import {
  useStyles,
} from "hooks";
import {
  CSSGenerator,
  DocumentType,
  DocumentShortNames,
  AppRoutes,
  CourseFilterType,
} from "interfaces";
import {
  AccordionInfoDetail,
  CustomTable,
  TableCellType,
  TableColumnData,
  TableChipCellStyle,
} from "components";
import { 
  parseElearningParticipantsData
} from "utils";

type Props = {
  documentType: DocumentType;
  sencenet: string;
  route: AppRoutes;
};


export const ElearningDetailsPage: React.FC<Props> = ({
  documentType,
  sencenet,
  route,
}) => {
  /* CONTEXTS */
  const { toggleLoader } = useContext(FeedbackContext);
  const history = useHistory();
  /* HOOKS */
  const styles = useStyles(generateStyles)


  const { courses, loading  } = useElearningCoursesSummary({
    type: CourseFilterType.ELEARNING,
  });
  const course = courses.filter((course) => course.sencenet === sencenet)[0];

  const { data, loading: participantsLoading} = useParticipantsDjp(
    Number(sencenet),
  );

  const pageName = useMemo(() => `[${documentType}Page]`, [documentType]);
  

  const participantsData = useMemo(() => {
    if (data?.getParticipantsDjp) {
      return parseElearningParticipantsData(
        data?.getParticipantsDjp,
        history,
      );
    }
    return [];
  }, [data, history]);

  const [participantsTableRawData, setParticipantsTableRawData] =
  useState(participantsData);


  const colDefs = columnDefs
  .filter((col) => col.cellType === TableCellType.ICON_BUTTON)
  .forEach((col) => {
    col.style = styles.tableIcons;
  });



  useEffect(() => {
    setParticipantsTableRawData(participantsData);
  }, [participantsData, setParticipantsTableRawData]);

  useEffect(() => {
    toggleLoader(loading, `${pageName} Loading course details data`);
  }, [loading, participantsLoading, pageName, toggleLoader]);
  useEffect(() => {
    toggleLoader(participantsLoading, `${pageName} Loading participant details data`);
  }, [loading, participantsLoading, pageName, toggleLoader]);
  return (
    <>
      {course && (
        <AccordionInfoDetail
          course={course}
          documentType={documentType}
          route={route}
          courseType={CourseFilterType.ELEARNING}
        />
        
      )}
      
      <Box sx={styles.filterDiv}>
        <Stack sx={styles.stack} direction="row" spacing={2}>
          <Typography variant={"h6"}>
            {DocumentShortNames[documentType]}
          </Typography>
          <Divider
            sx={styles.divider}
            variant="middle"
            flexItem
            orientation="vertical"
          />
          <Group sx={styles.participantsCount} />
          <Typography sx={styles.participantsCount} variant={"h6"}>{`${
            data?.getParticipantsDjp?.length ?? 0
          } participantes activos`}</Typography>
        </Stack>
      </Box>
      {data && (
        <CustomTable
          rawData={participantsTableRawData}
          columnDefs={columnDefs}
        />
      )
      }
    </>
  );
};

const columnDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Nombre participante",
    attr: "nombre",
    sortable: false,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Rut",
    attr: "rut",
    sortable: false,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Estado DJP",
    attr: "estadoDjp",
    sortable: true,
    chipStyleType: TableChipCellStyle.SECONDARY,
    align: "center",
  },
];

const generateStyles: CSSGenerator = (theme: Theme) => ({
  filterDiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
    alignItems: "center",
  },
  stack: {
    alignItems: "center",
    height: "fit-content",
  },
  button: {
    borderRadius: 18,
    height: 36,
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  participantsCount: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

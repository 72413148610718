import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import { Layout } from "components";
import { useContext } from "react";
import { AuthContext } from "contexts";
interface Props extends RouteProps {
  component: React.FC<RouteComponentProps>;
  withLayout?: boolean;
}

export const PrivateRoute: React.FC<Props> = ({
  component: Component,
  withLayout = true,
  ...rest
}) => {
  const { isLoggedIn } = useContext(AuthContext);
  if (!isLoggedIn) {
    return null; // If user is not authenticated, the AuthContext will redirect away
  }
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        withLayout ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

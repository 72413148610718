export enum EventType {
  DOCUMENT_GENERATED = "DOCUMENT_GENERATED",
  MANUAL_SIGN_REQUEST = "MANUAL_SIGN_REQUEST",
  AUTOMATIC_SIGN_REQUEST = "AUTOMATIC_SIGN_REQUEST",
  PRESIGN = "PRESIGN",
  SIGN = "SIGN",
  CORRECTION_REQUEST = "CORRECTION_REQUEST",
  VALIDATION = "VALIDATION",
  DOCUMENT_ACTIVATION = "DOCUMENT_ACTIVATION",
  DOCUMENT_INACTIVATION = "DOCUMENT_INACTIVATION",
  DOCUMENT_VISUALIZATION = "DOCUMENT_VISUALIZATION",
  MANUAL_UPLOAD = "MANUAL_UPLOAD",
  PROCESS_END = "PROCESS_END",
  EXPIRATION = "EXPIRATION",
  FORCED_VALIDATION = "FORCED_VALIDATION",
  IDENTITY_FILE_UPLOAD = "IDENTITY_FILE_UPLOAD",
  IDENTITY_FILE_VALIDATION = "IDENTITY_FILE_VALIDATION",
}

export enum EventEntity {
  COMPANY = "COMPANY",
  OTEC = "OTEC",
  PARTICIPANT = "PARTICIPANT",
}

export interface Event {
  id?: number;
  type: EventType;
  entity?: EventEntity;
  personName?: string;
  personEmail?: string;
  personRut?: string;
  personRole?: string;
  comment?: string;
  documentId: number;
  createdAt: string;
}

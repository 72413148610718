import {  useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Theme,
} from "@mui/material";
import {
  useElearningCoursesSummary,
} from "api";
import {
  FeedbackContext,
  AppContext,
} from "contexts";
import {
  useStyles,
} from "hooks";
import {
  CSSGenerator,
  DocumentType,
  AppRoutes,
  CourseFilterType,
} from "interfaces";
import {
  AccordionInfoDetail,
} from "../components";

type Props = {
  documentType: DocumentType;
  sencenet: string;
  route: AppRoutes;
};


export const OnSiteDetails: React.FC<Props> = ({
  documentType,
  sencenet,
  route,
}) => {
  /* CONTEXTS */
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  /* HOOKS */
  const styles = useStyles(generateStyles);


  const { courses, loading  } = useElearningCoursesSummary({
    type: CourseFilterType.ONSITE,
  });
  const course = courses.filter((course) => course.sencenet === sencenet)[0];



  const pageName = useMemo(() => `[${documentType}Page]`, [documentType]);


  useEffect(() => {
    toggleLoader(loading, `${pageName} Loading course details data`);
  }, [loading, pageName, toggleLoader]);
  return (
    <>
      {course && (
        <AccordionInfoDetail
          course={course}
          documentType={documentType}
          route={route}
          courseType={"PRESENCIAL"}
        />
        
      )}
      <Divider
        sx={styles.divider}
        variant="middle"
        flexItem
        orientation="vertical"
      />
    </>
  );
};

const generateStyles: CSSGenerator = (theme: Theme) => ({
  stack: {
    alignItems: "center",
    height: "fit-content",
  },
  button: {
    borderRadius: 18,
    height: 36,
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
});

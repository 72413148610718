import { useMemo } from "react";

type Props = {
  src: string;
  title: string;
  style?: React.CSSProperties;
  hideToolbar?: boolean;
  hideNavpanes?: boolean;
  hideScrollbar?: boolean;
};

export const CustomIframe: React.FC<Props> = ({
  src,
  title,
  style,
  hideToolbar,
  hideNavpanes,
  hideScrollbar,
}) => {
  const hiddenFeatures: string = useMemo(() => {
    if (!hideToolbar && !hideNavpanes && !hideScrollbar) return "";
    let hiddenFeaturesText = "#";
    if (hideToolbar) hiddenFeaturesText += "toolbar=0&";
    if (hideNavpanes) hiddenFeaturesText += "navpanes=0&";
    if (hideScrollbar) hiddenFeaturesText += "scrollbar=0";

    return hiddenFeaturesText;
  }, [hideNavpanes, hideScrollbar, hideToolbar]);
  return <iframe src={`${src}${hiddenFeatures}`} title={title} style={style} />;
};

import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { RecordIconType, PostSignPageContent, TextHeader } from "components";
import { FeedbackContext } from "contexts";
import { useDocumentDataByUUID } from "api";
import { getSignPageDescription, getSignPageTitle } from "utils";

export const OutdatedSignaturePage: React.FC = () => {
  const { toggleLoader } = useContext(FeedbackContext);

  /* FETCH DOCUMENT DATA */
  const { uuid } = useParams<{ uuid: string }>();
  const { data, loading } = useDocumentDataByUUID(uuid);
  useEffect(() => {
    toggleLoader(loading, "[OutdatedSignaturePage] Fetching document data");
  }, [loading, toggleLoader]);

  const title = useMemo(() => getSignPageTitle(data), [data]);
  const description = useMemo(() => getSignPageDescription(data), [data]);

  return (
    <Box>
      <TextHeader text={title} showAvatar={false} />
      <PostSignPageContent
        icon={RecordIconType.TIMER}
        iconType="error"
        helpIcon={RecordIconType.HELP}
        title="Ha terminado el plazo para firmar"
        description={description}
        bodyText="Si tienes dudas comunícate con el área de capacitación de tu empresa"
        showCheckIcon={false}
        showButtonDocument={false}
      />
    </Box>
  );
};

import { useMemo } from "react";
import { Document } from "interfaces";
import { useAvailableDocumentActions } from "hooks";

export const useCanInteractWithDJO = (document?: Document | null): boolean => {
  const getDocumentActions = useAvailableDocumentActions();
  const canInteractDJO = useMemo(() => {
    const djoPermissions = getDocumentActions(document);
    return (
      djoPermissions.canViewDJO ||
      djoPermissions.canSignDJO ||
      djoPermissions.canUploadIdentityFileDJO ||
      djoPermissions.canUploadDJO ||
      djoPermissions.canRequestCorrectionDJO ||
      djoPermissions.canValidateDJO ||
      djoPermissions.canForceValidationDJO ||
      djoPermissions.canViewForcedValidationDJO
    );
  }, [getDocumentActions, document]);
  return canInteractDJO;
};

import React from "react";
import { AppRoutes } from "interfaces";
import { RouteComponentProps } from "react-router";

import { DashboardPage } from "./DashboardPage";
import { PrecontractsPage } from "./PrecontractsPage";
import { SwornStatementPage } from "./SwornStatementsPage";
import { SwornStatementElearningPage } from "./SwornStatementsElearningPage";
import { LceOnSitePage } from "./LceOnSitePage";
import { SignPage } from "./SignPage";
import { SuccessfulSignaturePage } from "./SuccessfulSignaturePage";
import { OutdatedSignaturePage } from "./OutdatedSignaturePage";
import { PrecontractDetailsPage } from "./PrecontractDetailsPage";
import { SwornStatementDetailsPage } from "./SwornStatementDetailsPage";
import { SwornStatementElearningDetailsPage } from "./SwornStatementElearningDetailsPage";
import { LceOnSiteDetailsPage } from "./LceOnSiteDetailsPage";
import { MassPrecontractSignParticipantsPage } from "./MassPrecontractSignParticipantsPage";
import { HistorialPage } from "./HistorialPage";
import { HistoryDetailPage } from "./HistoryDetailPage";
import { MassPrecontractSignTemplatePage } from "./MassPrecontractSignTemplatePage";
import { IdentificationFileUploadPage } from "./IdentificationFileUploadPage";
export { CourseDetailsPage } from "./CourseDetailsPage";

export const Pages: Record<AppRoutes, React.FC<RouteComponentProps>> = {
  [AppRoutes.DASHBOARD]: DashboardPage,
  [AppRoutes.PRECONTRACTS]: PrecontractsPage,
  [AppRoutes.SWORN_STATEMENTS]: SwornStatementPage,
  [AppRoutes.SWORN_STATEMENTS_ELEARNING]: SwornStatementElearningPage,
  [AppRoutes.LCE_ONSITE]: LceOnSitePage,
  [AppRoutes.SIGN_DOCUMENT]: SignPage,
  [AppRoutes.SUCCESSFUL_SIGNATURE]: SuccessfulSignaturePage,
  [AppRoutes.OUTDATED_SIGNATURE]: OutdatedSignaturePage,
  [AppRoutes.PRECONTRACT_DETAILS]: PrecontractDetailsPage,
  [AppRoutes.SWORN_STATEMENT_DETAILS]: SwornStatementDetailsPage,
  [AppRoutes.LCE_ONSITE_DETAILS]: LceOnSiteDetailsPage,
  [AppRoutes.SWORN_STATEMENT_ELEARNING_DETAILS]: SwornStatementElearningDetailsPage,
  [AppRoutes.MASS_PRECONTRACT_SIGN_PARTICIPANTS]:
    MassPrecontractSignParticipantsPage,
  [AppRoutes.MASS_PRECONTRACT_SIGN_TEMPLATE]: MassPrecontractSignTemplatePage,
  [AppRoutes.SWORN_STATEMENT_HISTORIAL]: HistorialPage,
  [AppRoutes.PRECONTRACT_HISTORIAL]: HistorialPage,
  [AppRoutes.ELEARNING_HISTORIAL]: HistoryDetailPage,
  [AppRoutes.ONSITE_HISTORIAL]: HistoryDetailPage,
  [AppRoutes.UPLOAD_IDENTIFICATION_FILE]: IdentificationFileUploadPage,
};

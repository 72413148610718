import { Avatar, Box, lighten, Typography } from "@mui/material";
import { useStyles } from "hooks";
import { Comment, CSSGenerator } from "interfaces";
import { commentFormatDate } from "utils";

type Props = { comment: Comment };

export const CommentComponent: React.FC<Props> = ({ comment }) => {
  const styles = useStyles(generateStyles);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.dateContainer}>
        <Typography sx={styles.date}>
          {commentFormatDate(new Date(comment.createdAt))}
        </Typography>
      </Box>
      <Box sx={styles.userContainer}>
        <Avatar />
        <Box>
          <Typography sx={styles.name}>{comment.authorName}</Typography>
          <Typography sx={styles.role}>{comment.authorRole}</Typography>
        </Box>
      </Box>
      <Box sx={styles.textContainer}>
        <Typography>{comment.text}</Typography>
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    padding: "16px 16px 24px 16px",
  },
  dateContainer: {
    display: "flex",
    justifyContent: "end",
  },
  date: {
    color: lighten(theme.palette.common.black, 0.54),
    fontSize: "10px",
    letterSpacing: "1.5px",
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  name: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: "13px",
  },
  role: {
    color: theme.palette.primary.main,
    fontSize: "13px",
  },
  textContainer: {
    paddingTop: "16px",
  },
});

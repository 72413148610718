import { gql, useQuery } from "@apollo/client";
import { Setting } from "interfaces";

interface QueryResult {
  getSettings: Setting | null;
}

interface HookReturn {
  settings: Setting | null;
  loading: boolean;
}

export const useSettings = (): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(
    gql`
      query GetSettings {
        getSettings {
          djCriticalyDays {
            HIGH
            MEDIUM
            LOW
          }
          precontractCriticalyDays {
            HIGH
            MEDIUM
            LOW
          }
          elearningCriticalyDays {
            HIGH
            MEDIUM
            LOW
          }
          djpDocumentText {
            title
            subtitle
            upperDescriptionText
            lowerDescriptionText
            footer
          }
          djoDocumentText {
            title
            subtitle
            firstParagraph
            secondParagraph
            thirdParagraph
            footer
          }
          precontractDocumentText {
            title
            firstParagraph
            articles
            observations
          }
          customReminderTexts {
            SIGN_DJP
            SIGN_PRECONTRACT
            SIGN_DJO
            REMINDER_TO_RESPONSABLE_SINGLE_COURSE
            REMINDER_TO_RESPONSABLE_MULTIPLE_COURSES
          }
          pollingIntervals {
            SHORT
            MEDIUM
            HIGH
            GET_COURSES_SUMMARY
            GET_COURSE_DETAILS
            GET_USERS
            GET_COMPANIES
            GET_LEGACY_USERS
            GET_OTECS
            GET_ROLES
            GET_DASHBOARD_DATA
          }
          termsAndConditionsUrl
        }
      }
    `,
    {},
  );
  return { settings: data?.getSettings ?? null, loading };
};

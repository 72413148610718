import { gql, useQuery } from "@apollo/client";
import { MassPrecontractDocumentData } from ".";

interface QueryParams {
  params: { sencenet: string };
}

interface QueryResult {
  getModularPrecontractsDataForCompanySignature: MassPrecontractDocumentData | null;
}

interface HookReturn {
  data: MassPrecontractDocumentData | null;
  loading: boolean;
}

export const useParticipantsDataForCompanySignature = (
  sencenet: string,
): HookReturn => {
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    PRECONTRACTS_DATA_QUERY,
    {
      variables: {
        params: {
          sencenet,
        },
      },
    },
  );
  return {
    data: data?.getModularPrecontractsDataForCompanySignature ?? null,
    loading,
  };
};

const PRECONTRACTS_DATA_QUERY = gql`
  query GetModularPrecontractsDataForCompanySignature(
    $params: GetModularPrecontractsDataParams!
  ) {
    getModularPrecontractsDataForCompanySignature(params: $params) {
      data {
        precontractData {
          courses {
            id
            name
            senceCode
            sencenet
            participantsNumber
            registrationNumber
          }
          processStartDate
          processFinishDate
        }
      }
      participants {
        id
        rut
        name
        email
        nationality
        birthdate
        city
        phone
        email
        address
        hasMissingData(type: PRECONTRACT)
      }
      documents {
        id
        companyStatus
        participant
        uuid
      }
    }
  }
`;

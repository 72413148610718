import { gql } from "@apollo/client";
import { client, DOCUMENT_DATA_UUID_QUERY } from "api";

interface MutationParams {
  params: {
    uuid: string;
    file: File;
  };
}
interface MutationResult {
  signDocumentByUUID: string | null;
}

export const signDocumentByUUID = async (
  params: MutationParams["params"],
): Promise<boolean | null> => {
  if (!params.uuid || !params.file) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SignDocumentByUUID($params: SignDocumentByUUIDParams!) {
        signDocumentByUUID(params: $params)
      }
    `,
    variables: {
      params,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DOCUMENT_DATA_UUID_QUERY,
        variables: {
          params: {
            signatureLinkUUID: params.uuid,
          },
        },
      },
    ],
  });
  return !!response.data?.signDocumentByUUID;
};

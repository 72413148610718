import { RecordItem } from "components";
import { Event, EventType, Document, DocumentType } from "interfaces";
import { formatSignDate } from "utils";

export const getEventMainText = (
  event: Pick<Event, "type" | "personName" | "personEmail">,
  document: Document,
): string => {
  const documentNameWithArticle =
    document.type === DocumentType.PRECONTRACT
      ? "el precontrato"
      : "la declaración jurada";
  switch (event.type) {
    case EventType.AUTOMATIC_SIGN_REQUEST:
      return `Se ha solicitado la firma de ${event.personName} (${event.personEmail}).`;
    case EventType.CORRECTION_REQUEST:
      return `${event.personName} (${event.personEmail}) ha solicitado una corrección.`;
    case EventType.DOCUMENT_ACTIVATION:
      return `Se ha activado el documento.`;
    case EventType.DOCUMENT_GENERATED:
      return `Se ha disponibilizado ${documentNameWithArticle}`;
    case EventType.DOCUMENT_INACTIVATION:
      return `Se ha inactivado el documento.`;
    case EventType.DOCUMENT_VISUALIZATION:
      return `${event.personName} (${event.personEmail}) ha visualizado ${documentNameWithArticle}.`;
    case EventType.EXPIRATION:
      return `Ha finalizado el plazo para la firma de ${documentNameWithArticle}.`;
    case EventType.FORCED_VALIDATION:
      return `El documento fue validado forzosamente.`;
    case EventType.MANUAL_SIGN_REQUEST:
      return `Se ha solicitado la firma de ${event.personName} (${event.personEmail}).`;
    case EventType.MANUAL_UPLOAD:
      return `${event.personName} (${event.personEmail}) ha cargado el documento.`;
    case EventType.PROCESS_END:
      return `El proceso de firma de ${documentNameWithArticle} ha finalizado.`;
    case EventType.PRESIGN:
      return `${event.personName} (${event.personEmail}) ha firmado la plantilla del precontrato.`;
    case EventType.SIGN:
      return `${event.personName} (${event.personEmail}) ha firmado ${documentNameWithArticle}.`;
    case EventType.VALIDATION:
      return `${event.personName} (${event.personEmail}) ha validado el documento.`;
    case EventType.IDENTITY_FILE_UPLOAD:
      return `${event.personName} (${event.personEmail}) ha subido su cédula de identidad.`;
    case EventType.IDENTITY_FILE_VALIDATION:
      return `${event.personName} (${event.personEmail}) ha validado la cédula de identidad del participante.`;
    default:
      return "";
  }
};

export const generateRecordItems = (
  document: Document,
  events: Event[],
): RecordItem[] =>
  events.map((event) => ({
    mainText: getEventMainText(event, document),
    date: formatSignDate(event.createdAt),
    ...(event.comment ? { secondaryText: event.comment } : {}),
    eventType: event.type,
  }));

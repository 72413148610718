import { Permission } from "../permission";
import { Otec, IdentityFile } from "interfaces";

export interface CurrentUser {
  id: string;
  rut: string;
  name: string;
  email: string;
  phone: string;
  role: string;
  permissions: Permission[];
  needFilterByCell: boolean;
  otec: Otec;
  jobPosition?: string;
  createdAt: Date;
  identityFile?: IdentityFile;
}

export enum RoleNames {
  ADMIN = "Admin",
  PDC = "PDC",
  INSCRIPTIONS = "Inscripción y MDA",
  DOCUMENTS_CONTROL = "Control Documental",
  COMPANY = "Empresa Cliente",
  COMPANY_REPRESENTATIVE = "Representante Empresa",
  OTIC_CONSULTANT_USER = "Usuario de Consulta OTIC",
  OTEC = "OTEC",
  OTEC_REPRESENTATIVE = "Representante Legal OTEC",
}

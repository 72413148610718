import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Toolbar } from "@mui/material";
import { useDocumentHistorial } from "api";
import { FeedbackContext } from "contexts";
import { RecordSection, CommentSection, TextHeader } from "components";
import {
  ComponentType,
  CSSGenerator,
  DocumentNames,
  DocumentType,
} from "interfaces";
import { generateRecordItems } from "utils";
import { useStyles } from "hooks";

export const HistorialPage: React.FC = () => {
  const styles = useStyles(generateStyles);
  const { toggleLoader } = useContext(FeedbackContext);
  const { documentId } = useParams<{ documentId: string }>();

  const { data, loading: loadingData } = useDocumentHistorial(
    Number(documentId),
  );

  const parsedEvents = useMemo(() => {
    if (!data?.document || !data?.events) return [];
    return generateRecordItems(data?.document, data?.events);
  }, [data]);

  const headerText = useMemo(
    () =>
      data?.document
        ? `${DocumentNames[data.document.type]} · RUT ${
            data.document.type === DocumentType.DJO
              ? data.document.otec
              : data.document.participant
          }`
        : "",
    [data?.document],
  );

  useEffect(() => {
    toggleLoader(loadingData, "[Historial Page] Fetching historial data");
  }, [loadingData, toggleLoader]);
  return (
    <Box sx={styles.background}>
      <TextHeader text={headerText} />
      <Toolbar />
      <Box sx={styles.root}>
        {data?.document && (
          <>
            <Paper sx={styles.historialSection}>
              <RecordSection
                type={ComponentType.DEFAULT}
                document={data?.document}
                items={parsedEvents}
              />
            </Paper>
            <Paper sx={styles.commentsSection}>
              <CommentSection
                comments={data?.comments ?? []}
                documentId={Number(documentId)}
              />
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = () => ({
  root: { display: "flex", gap: "16px" },
  background: {
    padding: "31px",
    backgroundColor: "#F9F9F9",
    minHeight: "calc(100vh - 64px)",
  },
  historialSection: {
    flex: 5,
    padding: "8px",
  },
  commentsSection: {
    flex: 3,
  },
});

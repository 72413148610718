import { gql, useQuery } from "@apollo/client";
import { Course, ElearningCourse, GetElearningCourseParams } from "interfaces";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";

export const GET_ELEARNING_COURSES_SUMMARY_QUERY = ({
  type,
}: GetElearningCourseParams) => gql`
  query GetElearningCoursesSummary($params: GetElearningCourseParams!) {
    getElearningCoursesSummary(params: $params) {
      id
      purchaseOrder
      internal
      sencenet
      name
      office
      active
      startDate
      endDate
      djDaysLeft
      djCriticalyLevel
      idStatusDjo
      idStatusDjp
      participantsNumber
      djpNumber
      cell
      djo {
        id
        status
      }
      supervision {
        id
        status
      }
      company {
        id
        rut
        name
        holding
      }
      otec {
        rut
        name
      }
      haveDocument
    }
  }
`;

export const useElearningCoursesSummary = (
  params: GetElearningCourseParams,
): {
  courses: ElearningCourse[];
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getElearningCoursesSummary: ElearningCourse[];
  }>(GET_ELEARNING_COURSES_SUMMARY_QUERY(params), {
    variables: { params },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_ELEARNING_COURSES_SUMMARY] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });
  const courses = data?.getElearningCoursesSummary ?? [];

  return {
    courses: courses.map((c) => ({
      ...c,
      startDate: new Date(c.startDate),
      endDate: new Date(c.endDate),
    })),
    loading
  };
};

export const useCoursesEmpty = (): {
  courses: Course[];
  loading: boolean;
} => {
  return {
    courses: [],
    loading: false,
  };
};

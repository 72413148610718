import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RouteComponentProps } from "react-router";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { DashboardCard, Restricted } from "components";
import { useStyles, useRestrictedPage } from "hooks";
import {
  CriticalityLevel,
  CSSGenerator,
  DocumentType,
  Permission,
  // AppRoutes,
} from "interfaces";
import { FeedbackContext } from "contexts";
import { useCourseMetrics, useDashboardData } from "api";

export const DashboardPage: React.FC<RouteComponentProps> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_PRECONTRACTS, Permission.CAN_ACCESS_DJS],
    every: false,
  });
  const styles = useStyles(generateStyles);

  const { toggleLoader } = useContext(FeedbackContext);
  const { data, loading } = useDashboardData();


  const { data: metricData, loading: metricLoading } = useCourseMetrics({
    startDate: new Date('01 Jan 2023 08:04:05 GMT'),
    endDate: new Date('30 Dec 2023 08:04:05 GMT'),
  });

  const companyOptions = useMemo(() => {
    if (!data) return [];
    return ["Todas las empresas"].concat(
      data.globalData
        .map((d) => d.company)
        .filter((d) => d !== "all")
        .sort(),
    );
  }, [data]);

  const [selectedCompany, setSelectedCompany] =
    useState<string>("Todas las empresas");

  const handleSelectCompany = useCallback(
    (_, company: string | null) => {
      setSelectedCompany(company ?? "Todas las empresas");
    },
    [setSelectedCompany],
  );

  const selectedData = useMemo(() => {
    const all = selectedCompany === "Todas las empresas";
    const selected = data?.globalData.find(
      (d) => d.company === (all ? "all" : selectedCompany),
    );
    return selected
      ? selected.data
      : Object.assign(
          {},
          ...Object.values(DocumentType).map((dt) => ({
            [dt]: Object.assign(
              {},
              ...Object.values(CriticalityLevel).map((cl) => ({
                [cl]: { courses: 0, participants: 0 },
              })),
            ),
          })),
        );
  }, [selectedCompany, data]);

  useEffect(() => {
    toggleLoader(loading || metricLoading, "DashboardPage loading");
  }, [loading, metricLoading, toggleLoader]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>Gestión de firmas</Typography>
        <Autocomplete
          disablePortal
          options={companyOptions}
          sx={styles.companySelector}
          value={selectedCompany}
          onChange={handleSelectCompany}
          noOptionsText="Sin opciones disponibles"
          renderInput={(params) => <TextField {...params} label="Empresa" />}
        />
      </Box>
      <Restricted to={Permission.CAN_ACCESS_PRECONTRACTS}>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>Precontratos</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                data?.text[DocumentType.PRECONTRACT][CriticalityLevel.HIGH] ??
                ""
              }
              courseData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.HIGH]
                  .courses
              }
              participantsData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.HIGH]
                  .participants
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                data?.text[DocumentType.PRECONTRACT][CriticalityLevel.MEDIUM] ??
                ""
              }
              courseData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.MEDIUM]
                  .courses
              }
              participantsData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.MEDIUM]
                  .participants
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                data?.text[DocumentType.PRECONTRACT][CriticalityLevel.LOW] ?? ""
              }
              courseData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.LOW]
                  .courses
              }
              participantsData={
                selectedData[DocumentType.PRECONTRACT][CriticalityLevel.LOW]
                  .participants
              }
            />
          </Box>
        </Box>
      </Restricted>
      <Restricted to={Permission.CAN_ACCESS_DJS}>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>DJ OTEC</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                data?.text[DocumentType.DJO][CriticalityLevel.HIGH] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJO][CriticalityLevel.HIGH].courses
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                data?.text[DocumentType.DJO][CriticalityLevel.MEDIUM] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJO][CriticalityLevel.MEDIUM].courses
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                data?.text[DocumentType.DJO][CriticalityLevel.LOW] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJO][CriticalityLevel.LOW].courses
              }
            />
          </Box>
        </Box>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>DJ participantes</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                data?.text[DocumentType.DJP][CriticalityLevel.HIGH] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJP][CriticalityLevel.HIGH].courses
              }
              participantsData={
                selectedData[DocumentType.DJP][CriticalityLevel.HIGH]
                  .participants
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                data?.text[DocumentType.DJP][CriticalityLevel.MEDIUM] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJP][CriticalityLevel.MEDIUM].courses
              }
              participantsData={
                selectedData[DocumentType.DJP][CriticalityLevel.MEDIUM]
                  .participants
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                data?.text[DocumentType.DJP][CriticalityLevel.LOW] ?? ""
              }
              courseData={
                selectedData[DocumentType.DJP][CriticalityLevel.LOW].courses
              }
              participantsData={
                selectedData[DocumentType.DJP][CriticalityLevel.LOW]
                  .participants
              }
            />
          </Box>
        </Box>
      </Restricted>
      <Restricted to={Permission.CAN_ACCESS_DJS_ELEARNING}>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>DJ OTEC - E-learning</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                "Cursos entre 0 y 10 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djOtec.Critico || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                "Cursos entre 11 y 20 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djOtec.Medio || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                "Cursos entre 21 y 59 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djOtec.Normal || 0
              }
            />
          </Box>
        </Box>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>DJ participantes - E-learning</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                "Cursos entre 0 y 10 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djParticipante.Critico.Cursos || 0
              }
              participantsData={
                metricData?.getMetrics.djParticipante.Critico.Participantes || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                "Cursos entre 11 y 20 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djParticipante.Medio.Cursos || 0
              }
              participantsData={
                metricData?.getMetrics.djParticipante.Medio.Participantes || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                "Cursos entre 21 y 59 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.djParticipante.Normal.Cursos || 0
              }
              participantsData={
                metricData?.getMetrics.djParticipante.Normal.Participantes || 0
              }
            />
          </Box>
        </Box>
      </Restricted>



      <Restricted to={Permission.CAN_ACCESS_LCE_ONSITE}>
        <Box sx={styles.row}>
          <Typography sx={styles.sectionTitle}>LCE - Presencial</Typography>
          <Box sx={styles.cardContainer}>
            <DashboardCard
              criticalityLevel={CriticalityLevel.HIGH}
              deadlineText={
                "Cursos entre 0 y 10 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.lcePresencial.Critico || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.MEDIUM}
              deadlineText={
                "Cursos entre 11 y 20 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.lcePresencial.Medio || 0
              }
            />
            <DashboardCard
              criticalityLevel={CriticalityLevel.LOW}
              deadlineText={
                "Cursos entre 21 y 59 días para su liquidación"
              }
              courseData={
                metricData?.getMetrics.lcePresencial.Normal || 0
              }
            />
          </Box>
        </Box>
      </Restricted>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "32px",
  },
  title: {
    fontSize: "23px",
    fontWeight: 600,
  },
  titleContainer: {
    display: "flex",
    gap: "32px",
    alignItems: "center",
  },
  row: {
    width: "100%",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: "19px",
    marginBottom: "16px",
  },
  companySelector: {
    width: "100%",
    maxWidth: "500px",
    height: "51px",
    backgroundColor: theme.palette.common.white,
  },
});

import { Button, ButtonProps } from "@mui/material";
import { useMemo } from "react";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
type Props = ButtonProps & {
  children: React.ReactNode;
};

export const CustomButton: React.FC<Props> = ({
  children,
  onClick,
  sx,
  variant,
  color = "primary",
  ...props
}) => {
  const styleVariables: StyleParams = useMemo(() => ({ variant, color }), [
    variant,
    color,
  ]);
  const styles = useStyles(generateStyles, styleVariables);
  return (
    <Button
      sx={{ ...styles.root, ...sx }}
      onClick={onClick}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </Button>
  );
};

interface StyleParams {
  variant: ButtonProps["variant"];
  color: ButtonProps["color"];
}

const generateStyles: CSSGenerator<StyleParams> = (
  theme,
  { variant, color },
) => ({
  root: {
    display: "flex",
    borderRadius: "28px",
    letterSpacing: "1.25px",
    color:
      variant === "contained" && color === "primary"
        ? theme.palette.common.white
        : undefined,
  },
});

import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { SxProps } from "@mui/system";
import { RecordIconType } from "./RecordIcon";

const iconsPath: Record<RecordIconType, string> = {
  [RecordIconType.DOCUMENT]: "/icons/record/document.png",
  [RecordIconType.WATCH]: "/icons/record/watch.png",
  [RecordIconType.SIGN]: "/icons/record/sign.png",
  [RecordIconType.MESSAGE]: "/icons/record/reminder.png",
  [RecordIconType.LOCK]: "/icons/record/lock.png",
  [RecordIconType.CHECK_CIRCLE]: "/icons/record/check_circle.png",
  [RecordIconType.UPLOAD_FILE]: "/icons/record/upload_file.png",
  [RecordIconType.FEEDBACK]: "/icons/record/feedback.png",
  [RecordIconType.TIMER]: "/icons/record/timer.png",
  [RecordIconType.ADD_TASK]: "/icons/record/add_task.png",
  [RecordIconType.HELP]: "/icons/record/help.png",
  [RecordIconType.DOCUMENT_SUCCESSFUL]: "/icons/record/document_successful.png",
  [RecordIconType.PIN]: "/icons/record/pin.png",
  [RecordIconType.ERROR]: "/icons/record/error.png",
  [RecordIconType.WARNING]: "",
  [RecordIconType.IDENTITY_FILE]: "/icons/record/upload_file.png",
};

type Props = {
  icon: RecordIconType;
  style?: SxProps;
};

export const RecordPNGIcon: React.FC<Props> = ({ icon, style }) => {
  const styles = useStyles(generateStyles);
  return (
    <Box sx={{ ...styles.root, ...style }}>
      <img
        src={iconsPath[icon]}
        draggable={false}
        alt=""
        style={{ height: "100%" }}
      />
    </Box>
  );
};

const generateStyles: CSSGenerator = () => {
  return {
    root: { width: "30px", height: "30px" },
  };
};

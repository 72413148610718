import { gql } from "@apollo/client";
import {
  client,
  GetCourseDetailsParams,
  GET_CURRENT_USER_QUERY,
  GET_DJ_COURSE_DETAILS_QUERY,
} from "..";
import { IdentityFileData, IdentityFileType } from "../../interfaces";

const saveFaces = !!process.env.REACT_APP_SAVE_IDENTITY_FILE_FACES;

interface MutationParams {
  params: {
    files: IdentityFileData[];
    expirationDate: Date | null;
  };
}
interface MutationResult {
  saveIdentityFile: boolean | null;
}

export const saveIdentityFile = async (
  params: MutationParams["params"],
  courseDetailQueryParams?: GetCourseDetailsParams,
): Promise<boolean | null> => {
  if (!params.files || !params.expirationDate) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SaveIdentityFile($params: SaveIdentityFileParams!) {
        saveIdentityFile(params: $params)
      }
    `,
    variables: {
      params: {
        expirationDate: params.expirationDate,
        files: saveFaces
          ? params.files
          : params.files.filter((f) => f.type === IdentityFileType.COMBINED),
      },
    },
    refetchQueries: [
      {
        query: GET_CURRENT_USER_QUERY,
      },
      ...shouldRefetchCourseDetails(courseDetailQueryParams),
    ],
  });
  return !!response.data?.saveIdentityFile;
};

const shouldRefetchCourseDetails = (params?: GetCourseDetailsParams): any => {
  if (!params) return [];
  return [
    {
      query: GET_DJ_COURSE_DETAILS_QUERY(params.documentType, params.sencenet),
      variables: {
        params,
      },
    },
  ];
};

import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Paper,
  TextField,
  Grid,
} from "@mui/material";
import DateRangePicker from "./DateRangePicker";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import { map, uniq } from "lodash";
import { useStyles } from "hooks";
import {
  CSSGenerator,
  CriticalityLevel,
  ElearningCourseListData,
  ElearningCourseListFilters,
} from "interfaces";
import Fuse from "fuse.js";
import { Range } from 'react-date-range'

const fuzzyFilterOptions = (
  options: string[],
  { inputValue }: { inputValue: string },
): string[] => {
  if (!inputValue) return options;
  const instance = new Fuse<string>(options, {
    threshold: 0.4,
    isCaseSensitive: false,
  });
  return map(instance.search(inputValue), "item");
};

const removeNonNumbers = (text: string | null): string => {
  if (!text) return "";
  return text.replace(/[^\d]/g, "");
};

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const autocompleteFromStartFilterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 20,
});

enum FilterElem {
  COMP = "COMPANY_FILTER",
  OTEC = "OTEC_FILTER",
  CELL = "CELL_FILTER",
  CRIT = "CRITICALLY_FILTER",
}

type Props = {
  data: (ElearningCourseListData & { criticalityLevel?: CriticalityLevel })[];
  filters: ElearningCourseListFilters;
  setFilters: Dispatch<SetStateAction<ElearningCourseListFilters>>;
  useCriticalityFilters?: boolean;
  useCellFilter?: boolean;
  range: Range[];
  setRange: Dispatch<SetStateAction<Range[]>>; 
};

export const OnSiteFilterSection: React.FC<Props> = ({
  data,
  filters,
  setFilters,
  useCriticalityFilters = false,
  useCellFilter = false,
  range,
  setRange
}) => {
  const styles = useStyles(generateStyles);

  const options = useMemo(
    () => ({
      purchaseOrder: map(data, "purchaseOrder").sort(),
      sencenet: map(data, "sencenet").sort(),
      company: uniq(map(data, "companyData")).sort(),
      otec: uniq(map(data, "otecData")).sort(),
      name: uniq(map(data, "name")).sort(),
      cell: uniq(map(data, "cell")).sort(),
    }),
    [data],
  );

  const [writingSencenet, setWritingSencenent] = useState("");
  const [writingRN, setWritingRN] = useState("");

  const handleSelectRN = useCallback(
    (_, purchaseOrders: unknown[]) => {
      setFilters((prev) => ({
        ...prev,
        purchaseOrder: purchaseOrders as string[],
      }));
    },
    [setFilters],
  );

  const handleMultipleRegistrationNumbers = useCallback(
    (event) => {
      const input = event.target.value;
      if (input.includes(" ")) {
        const inputList: string[] = uniq(
          input
            .split(" ")
            .filter((rn: string) =>
              data.map((c) => c.purchaseOrder).includes(rn),
            ),
        );
        setFilters((prev) => ({
          ...prev,
          purchaseOrder: uniq(prev.purchaseOrder.concat(inputList)),
        }));
      }
    },
    [data, setFilters],
  );

  const handleSelectSencenet = useCallback(
    (_, sencenet: unknown) => {
      setFilters((prev) => ({ ...prev, sencenet: sencenet as string }));
    },
    [setFilters],
  );

  const handleSelectName = useCallback(
    (_, name: string | null) => {
      setFilters((prev) => ({ ...prev, name }));
    },
    [setFilters],
  );

  const handleSelectCompany = useCallback(
    (_, company: string | null) => {
      setFilters((prev) => ({ ...prev, company }));
    },
    [setFilters],
  );

  const handleSelectOtec = useCallback(
    (_, otec: string | null) => {
      setFilters((prev) => ({ ...prev, otec }));
    },
    [setFilters],
  );

  const handleSelectCell = useCallback(
    (_, cell: unknown) => {
      setFilters((prev) => ({ ...prev, cell: cell as string }));
    },
    [setFilters],
  );


  const toggleCriticalyLevel = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedCriticalityLevel = event.target.name as CriticalityLevel;
      setFilters((prev) => ({
        ...prev,
        criticalityLevel: {
          ...prev.criticalityLevel,
          [selectedCriticalityLevel]:
            !prev.criticalityLevel[selectedCriticalityLevel],
        },
      }));
    },
    [setFilters],
  );

  const columnsWidths = useMemo<FiltersColsEntry["cols"]>(
    () => getFilterColumnWidth(useCellFilter, useCriticalityFilters),
    [useCellFilter, useCriticalityFilters],
  );

  return (
    <Paper sx={styles.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} xl={2.1} >
          <DateRangePicker range={range} setRange={setRange} />
        </Grid>
        <Grid item xl={3.9} md={6} xs={6}>
          <Autocomplete
            sx={styles.purchaseOrderInput}
            multiple
            filterOptions={autocompleteFromStartFilterOptions}
            limitTags={7}
            options={options.purchaseOrder}
            disableCloseOnSelect
            value={filters.purchaseOrder}
            onChange={handleSelectRN}
            noOptionsText="Sin opciones disponibles"
            inputValue={removeNonNumbers(writingRN)}
            onInputChange={(_, val) => setWritingRN(removeNonNumbers(val))}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="N° Solicitud de Compra"
                onChange={handleMultipleRegistrationNumbers}
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={options.sencenet}
            filterOptions={autocompleteFromStartFilterOptions}
            sx={styles.autocomplete}
            value={filters.sencenet}
            onChange={handleSelectSencenet}
            inputValue={removeNonNumbers(writingSencenet)}
            onInputChange={(_, val) =>
              setWritingSencenent(removeNonNumbers(val))
            }
            noOptionsText="Sin opciones disponibles"
            renderInput={(params) => <TextField {...params} label="Sencenet" />}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            options={options.name}
            sx={styles.autocomplete}
            value={filters.name}
            onChange={handleSelectName}
            noOptionsText="Sin opciones disponibles"
            filterOptions={fuzzyFilterOptions}
            renderInput={(params) => <TextField {...params} label="Curso" />}
          />
        </Grid>
        <Grid item xl={columnsWidths[FilterElem.COMP]} md={7.5} xs={7.5}>
          <Autocomplete
            disablePortal
            options={options.company}
            sx={styles.autocomplete}
            value={filters.company}
            onChange={handleSelectCompany}
            noOptionsText="Sin opciones disponibles"
            filterOptions={fuzzyFilterOptions}
            renderInput={(params) => <TextField {...params} label="Empresa" />}
          />
        </Grid>
        <Grid item xs={columnsWidths[FilterElem.OTEC]}>
          <Autocomplete
            disablePortal
            options={options.otec}
            sx={styles.autocomplete}
            value={filters.otec}
            onChange={handleSelectOtec}
            noOptionsText="Sin opciones disponibles"
            filterOptions={fuzzyFilterOptions}
            renderInput={(params) => <TextField {...params} label="OTEC" />}
          />
        </Grid>
        {useCellFilter && (
          <Grid item xs={columnsWidths[FilterElem.CELL]}>
            <Autocomplete
              disablePortal
              options={options.cell}
              sx={styles.autocomplete}
              value={filters.cell}
              onChange={handleSelectCell}
              noOptionsText="Sin opciones disponibles"
              filterOptions={fuzzyFilterOptions}
              renderInput={(params) => <TextField {...params} label="Célula" />}
            />
          </Grid>
        )}
        {useCriticalityFilters && (
          <Grid item xl={3} md={12} xs={12}>
            <Box sx={styles.checkboxContainer}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Criticidad</FormLabel>
                <FormGroup aria-label="position" row>
                  {checkboxOptions.map((option, idx) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.criticalityLevel[option.level]}
                          onChange={toggleCriticalyLevel}
                          name={option.level}
                          color={option.color}
                        />
                      }
                      label={option.label}
                      key={`checkbox-${idx}`}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const checkboxOptions: {
  level: CriticalityLevel;
  color: "error" | "secondary" | "success";
  label: string;
}[] = [
  { level: CriticalityLevel.HIGH, color: "error", label: "Alta" },
  { level: CriticalityLevel.MEDIUM, color: "secondary", label: "Media" },
  { level: CriticalityLevel.LOW, color: "success", label: "Baja" },
];

const generateStyles: CSSGenerator = () => ({
  root: {
    flexGrow: 1,
    padding: "24px",
  },
  checkboxContainer: {
    height: "56px",
  },
});

const getFilterColumnWidth = (
  useCell: boolean,
  useCritically: boolean,
): FiltersColsEntry["cols"] =>
  FILTERS_COLS.find(
    (e) => e.useCell === useCell && e.useCritically === useCritically,
  )?.cols ?? FILTERS_COLS[3].cols;

interface FiltersColsEntry {
  useCell: boolean;
  useCritically: boolean;
  cols: Record<FilterElem, number>;
}
const FILTERS_COLS: FiltersColsEntry[] = [
  {
    useCell: false,
    useCritically: false,
    cols: {
      [FilterElem.COMP]: 6,
      [FilterElem.OTEC]: 6,
      [FilterElem.CELL]: 0,
      [FilterElem.CRIT]: 0,
    },
  },
  {
    useCell: false,
    useCritically: true,
    cols: {
      [FilterElem.COMP]: 4.5,
      [FilterElem.OTEC]: 4.5,
      [FilterElem.CELL]: 0,
      [FilterElem.CRIT]: 3,
    },
  },
  {
    useCell: true,
    useCritically: false,
    cols: {
      [FilterElem.COMP]: 4,
      [FilterElem.OTEC]: 4,
      [FilterElem.CELL]: 4,
      [FilterElem.CRIT]: 0,
    },
  },
  {
    useCell: true,
    useCritically: true,
    cols: {
      [FilterElem.COMP]: 3,
      [FilterElem.OTEC]: 3,
      [FilterElem.CELL]: 3,
      [FilterElem.CRIT]: 3,
    },
  },
];

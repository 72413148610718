import { gql } from "@apollo/client";
import { client } from "api";

interface MutationResult {
  presignCompanyPrecontracts: boolean | null;
}

interface MutationParams {
  params: {
    documentIds: number[];
  };
}

export const presignCompanyPrecontracts = async (
  documentIds: number[],
): Promise<boolean> => {
  if (documentIds.length === 0) return false;

  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation PresignCompanyPrecontracts(
        $params: SignCompanyPrecontractsParams!
      ) {
        presignCompanyPrecontracts(params: $params)
      }
    `,
    variables: {
      params: { documentIds },
    },
  });

  return !!response.data?.presignCompanyPrecontracts;
};

import { Box, Typography, Divider, TypographyProps } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { getSuperIndex } from "utils";
import { getResponsiveSize } from "utils";

type TitleProps = TypographyProps & {
  prefix?: string;
  text: string;
};

type TextProps = TypographyProps & {
  prefix?: string;
  text: string | React.ReactNode;
};

/* DOCUMENT TITLE */
export const DocumentTitle: React.FC<TitleProps> = ({ text, sx, ...props }) => {
  const styles = useStyles(generateTitleStyles);
  return (
    <Typography sx={{ ...styles.root, ...sx }} {...props} variant="h5">
      {text.split(" ").map((word, idx) => (
        <span key={idx} style={styles.word as React.CSSProperties}>
          {word}
        </span>
      ))}
    </Typography>
  );
};

const generateTitleStyles: CSSGenerator = () => ({
  root: {
    textAlign: "center",
    fontWeight: "bold",
    // 33px in 1440px vw
    fontSize: getResponsiveSize(33),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  word: {
    marginRight: getResponsiveSize(8),
  },
});

/* DOCUMENT SUBTITLE */
export const DocumentSubtitle: React.FC<TextProps> = ({
  text,
  sx,
  ...props
}) => {
  const styles = useStyles(generateSubitleStyles);
  return (
    <Typography sx={{ ...styles.root, ...sx }} {...props}>
      {text}
    </Typography>
  );
};

const generateSubitleStyles: CSSGenerator = () => ({
  root: {
    textAlign: "center",
    fontWeight: 600,
    // 19px in 1440px vw
    fontSize: getResponsiveSize(19),
  },
});

/* DOCUMENT PARAGRAPH */
export const DocumentParagraph: React.FC<TextProps> = ({
  sx,
  prefix = "",
  text,
  ...props
}) => {
  const styles = useStyles(generateParagraphStyles);
  return (
    <Typography sx={{ ...styles.root, ...sx }} {...props}>
      {prefix}
      {text}
    </Typography>
  );
};

const generateParagraphStyles: CSSGenerator = () => ({
  root: {
    textAlign: "left",
    // 19px in 1440px vw
    fontSize: getResponsiveSize(19),
  },
});

/* DOCUMENT FOOTER */
export const DocumentFootNote: React.FC<TextProps & { index: number }> = ({
  text,
  index,
}) => {
  const styles = useStyles(generateFootNoteStyles);
  return (
    <Typography sx={styles.root}>
      {getSuperIndex(index.toString())} {text}
    </Typography>
  );
};

const generateFootNoteStyles: CSSGenerator = () => ({
  root: {
    textAlign: "left",
    // 12px in 1440px vw
    fontSize: getResponsiveSize(12),
  },
});

export const DocumentFooter: React.FC<{ footNotes: string[] }> = ({
  footNotes,
}) => {
  const styles = useStyles(generateFooterStyles);
  return (
    <Box>
      <Divider sx={styles.divider} />
      <Box>
        {footNotes.map((footNote, idx) => (
          <DocumentFootNote
            key={`footNote-${idx}`}
            text={footNote}
            index={idx + 1}
          />
        ))}
      </Box>
    </Box>
  );
};

const generateFooterStyles: CSSGenerator = () => ({
  root: {
    textAlign: "left",
  },
  divider: {
    width: getResponsiveSize(121),
  },
});

import { Chip, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import {
  RecordIconType,
  RecordPNGIcon,
  RecordItem,
  RecordTimeline,
  TableChipCellStyle,
  TableChipCellType,
} from "components";
import { useStyles } from "hooks";
import {
  ComponentType,
  DocumentNames,
  CSSGenerator,
  Document,
  DocumentType,
  DocumentStatusNames,
  DocumentCompanyStatusNames,
} from "interfaces";
import React from "react";
import { getTableChipStyles } from "styles";
import { setSizeByComponentType } from "utils";

type RecordSectionProps = {
  type: ComponentType;
  // documentType: DocumentType;
  document: Document;
  items: RecordItem[];
  continuation?: boolean;
};

export const RecordSection: React.FC<RecordSectionProps> = ({
  type,
  items,
  document,
  continuation,
}) => {
  const styles = useStyles(generateSectionStyles, type);
  if (continuation)
    return (
      <Box sx={styles.root}>
        <RecordTimeline type={type} items={items} />
      </Box>
    );
  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>Historial de Firma Digital</Typography>
        <RecordPNGIcon
          icon={RecordIconType.LOCK}
          style={styles.backgroundIcon}
        />
        <Typography sx={styles.documentName}>
          {DocumentNames[document.type]}
        </Typography>
      </Box>
      <Box sx={styles.descriptionContainer}>
        {type === ComponentType.DOCUMENT ? (
          <DocumentDescription type={type} document={document} />
        ) : (
          <DefaultDescription type={type} document={document} />
        )}
      </Box>
      <RecordTimeline type={type} items={items} />
    </Box>
  );
};

const DocumentDescription: React.FC<{
  type: ComponentType;
  document: Document;
}> = ({ type, document }) => {
  const styles = useStyles(generateDescriptionStyles, type);
  return (
    <>
      <Box sx={styles.row}>
        <Typography sx={styles.key}>ID de Transacción:</Typography>
        <Typography sx={styles.value}>{document.uuid}</Typography>
      </Box>
      <Box sx={styles.row}>
        <Typography sx={styles.key}>Estado:</Typography>
        <Typography sx={styles.value}>Firmado</Typography>
      </Box>
    </>
  );
};

const DefaultDescription: React.FC<{
  type: ComponentType;
  document: Document;
}> = ({ type, document }) => {
  const styles = useStyles(generateDescriptionStyles, type);
  const theme = useTheme();

  const companyChipStyles = getTableChipStyles(
    theme,
    TableChipCellType.DOCUMENT_STATUS,
    document.companyStatus,
    TableChipCellStyle.PRIMARY,
  );
  const participantChipStyles = getTableChipStyles(
    theme,
    TableChipCellType.DOCUMENT_STATUS,
    document.status,
    TableChipCellStyle.PRIMARY,
  );
  return (
    <>
      {document.type === DocumentType.PRECONTRACT && (
        <Box sx={styles.defaultDescriptionComponent}>
          <Typography sx={styles.key}>Firma empresa</Typography>
          <Chip
            sx={companyChipStyles}
            label={DocumentCompanyStatusNames[document.companyStatus]}
          />
        </Box>
      )}
      <Box sx={styles.defaultDescriptionComponent}>
        <Typography sx={styles.key}>Estado de firma</Typography>
        <Chip
          sx={participantChipStyles}
          label={DocumentStatusNames[document.status]}
        />
      </Box>
    </>
  );
};

const generateSectionStyles: CSSGenerator<ComponentType> = (theme, type) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: setSizeByComponentType(24, type),
    border:
      type === ComponentType.DOCUMENT
        ? `solid ${setSizeByComponentType(0.6, type)} ${
            theme.palette.grey[300]
          }`
        : "unset",
    padding: setSizeByComponentType(16, type),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: setSizeByComponentType(16, type),
  },
  title: {
    fontSize: setSizeByComponentType(20, type),
    fontWeight: type === ComponentType.DOCUMENT ? 600 : 500,
  },
  documentName: {
    fontSize: setSizeByComponentType(20, type),
    color: theme.palette.text.secondary,
    fontWeight: type === ComponentType.DOCUMENT ? 600 : 500,
  },
  descriptionContainer: {
    backgroundColor: theme.palette.grey[100],
    padding: setSizeByComponentType(16, type),
    display: "flex",
    flexDirection: type === ComponentType.DOCUMENT ? "column" : "row",
  },
  backgroundIcon: {
    width: setSizeByComponentType(30, type),
    height: setSizeByComponentType(30, type),
  },
  icon: {
    fontSize: setSizeByComponentType(15, type),
  },
});

const generateDescriptionStyles: CSSGenerator<ComponentType> = (
  theme,
  type,
) => ({
  row: {
    display: "flex",
    gap: setSizeByComponentType(8, type),
  },
  key: {
    fontSize: setSizeByComponentType(16, type),
    color: theme.palette.text.secondary,
  },
  value: {
    fontSize: setSizeByComponentType(16, type),
  },
  defaultDescriptionComponent: {
    display: "flex",
    flexDirection: "row",
    gap: "24px",
    flex: 1,
    alignItems: "center",
  },
});

import { alpha, Grid, GridSize, Paper, Typography } from "@mui/material";
import { RecordIcon, RecordIconType } from "components";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";

export type DialogInfoItem = {
  title: string;
  subtitle: string;
  sm?: GridSize;
};

type Props = {
  items: DialogInfoItem[];
  icon?: RecordIconType;
};

export const DialogInfo: React.FC<Props> = ({ items, icon }) => {
  const styles = useStyles(generateStyles);
  return (
    <Paper>
      <Grid sx={styles.root} container>
        {icon && (
          <Grid sx={styles.iconContainer} item sm={1}>
            <RecordIcon
              icon={icon}
              backgroundStyle={styles.backgroundIcon}
              iconStyle={styles.icon}
            />
          </Grid>
        )}
        {items.map((item, idx) => (
          <Grid
            sx={styles.gridContainer}
            key={`gridItem-${idx}`}
            item
            sm={item.sm ?? true}
          >
            <Typography sx={styles.title}>{item.title}</Typography>
            <Typography sx={styles.subtitle}>{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    minHeight: "64px",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backgroundIcon: {
    width: "48px",
    height: "48px",
  },
  icon: {
    fontSize: "30px",
  },
  title: {
    fontSize: "12px",
    color: alpha(theme.palette.text.primary, 0.54),
  },
  subtitle: {
    fontSize: "12px",
  },
});

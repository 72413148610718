import { Snackbar, Alert, AlertColor } from "@mui/material";

export interface SnackbarData {
  text: string;
  severity: AlertColor;
}

type Props = {
  open: boolean;
  data: SnackbarData;
  onClose: () => void;
};

export const CustomSnackbar: React.FC<Props> = ({ open, data, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={4000} // TODO-2: traer desde settings
    onClose={onClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    style={{ zIndex: 10001 }}
  >
    <Alert
      onClose={onClose}
      severity={data.severity}
      sx={{ width: "100%" }}
      variant="filled"
    >
      {data.text}
    </Alert>
  </Snackbar>
);

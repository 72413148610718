import { IdentityFile } from "interfaces";
export interface Participant {
  id: number;
  name: string;
  firstname: string;
  firstLastname: string;
  secondaryLastname: string;
  rut: string;
  email: string;
  alternativeEmail?: string;
  phone: string;
  alternativePhone?: string;
  //company: Company;
  age: number;
  birthdate: string;
  nationality: string;
  address: string;
  commune: string;
  city: string;
  region: string;
  status: ParticipantStatus;
  hasMissingData: boolean;
  identityFile?: IdentityFile;
}

export enum ParticipantStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

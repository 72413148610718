import { DocumentStatus, DocumentType } from "interfaces";

export enum EmailSubject {
  REQUEST_CORRECTION = "REQUEST_CORRECTION",
  REQUEST_SIGN = "REQUEST_SIGN",
  REQUEST_IDENTIFICATION = "REQUEST_IDENTIFICATION",
  REMINDER_REQUEST_SIGN = "REMINDER_REQUEST_SIGN",
  REMINDER_REQUEST_CORRECTION = "REMINDER_REQUEST_CORRECTION",
  REMINDER_TO_RESPONSABLE = "REMINDER_TO_RESPONSABLE",
}

export const EmailSubjectNames: Record<EmailSubject, string> = {
  [EmailSubject.REQUEST_CORRECTION]: "Solicitar corrección",
  [EmailSubject.REQUEST_SIGN]: "Solicitar firma",
  [EmailSubject.REQUEST_IDENTIFICATION]: "Solicitar CI",
  [EmailSubject.REMINDER_REQUEST_SIGN]: "Recordatorio solicitud de firma",
  [EmailSubject.REMINDER_REQUEST_CORRECTION]:
    "Recordatorio solicitud corrección",
  [EmailSubject.REMINDER_TO_RESPONSABLE]: "Recordatorio al responsable",
};

export interface EmailForm {
  subjectOption: EmailSubject | null;
  email: string;
  message: string;
}

export interface CustomEmailData {
  documentId: number;
  subject: string;
  emails: string[];
  message: string;
}

interface InternalMassReminderParticipantData {
  name: string;
  rut: string;
  email: string;
  documentStatus: DocumentStatus;
  comment: string;
}

export interface InternalMassReminderParams {
  emails: string[];
  sencenet: string;
  documentType: DocumentType;
  participants: InternalMassReminderParticipantData[];
}

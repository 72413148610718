import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Typography,
  alpha,
} from "@mui/material";
import { Create } from "@mui/icons-material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { getResponsiveSize } from "utils";

type Props = {
  name: string;
  rut: string;
  role: string;
  email?: string;
  companyname?: string;
  companyrut?: string;
  phone?: string;
  onClick: () => void;
};

export const SignHereCard: React.FC<Props> = ({ name, rut, role, email, companyname, companyrut, phone, onClick }) => {
  const styles = useStyles(generateStyles);
  return (
    <Card sx={styles.root}>
      <CardActionArea sx={styles.action} onClick={onClick}>
        <Typography sx={styles.signHereText}>
          <Create sx={styles.icon} />
          FIRMA AQUÍ
        </Typography>
        <Divider sx={styles.divider} />
        <Box sx={styles.dataContainer}>
          <Typography sx={styles.dataText}>{name}</Typography>
          <Typography sx={styles.dataText}>{rut}</Typography>
          { email ? <Typography sx={styles.dataText}>{email}</Typography> : null }
          { companyname ? <Typography sx={styles.dataText}>{companyname}</Typography> : null }
          { companyrut ? <Typography sx={styles.dataText}>{companyrut}</Typography> : null }
          {phone ? <Typography sx={styles.dataText}>{phone}</Typography> : null}
          <Typography sx={styles.dataText}>{role}</Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    backgroundColor: "#FAF2E8",
    width: getResponsiveSize(378),
    height: getResponsiveSize(240),
  },
  action: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",
    width: "100%",
    height: "100%",
    gap: getResponsiveSize(16),
    padding: getResponsiveSize(16),
  },
  divider: {
    width: "100%",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signHereText: {
    fontWeight: 600,
    fontSize: getResponsiveSize(23),
  },
  dataText: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: getResponsiveSize(14),
  },
  icon: {
    fontSize: getResponsiveSize(22),
  },
});

import { TableCell, Chip, useTheme } from "@mui/material";
import React, { useCallback } from "react";
import { getTableChipStyles } from "styles";
import { TableChipCellProps, TableChipCellValue } from "./interfaces";

export const ChipCell: React.FC<TableChipCellProps> = ({
  value,
  align,
  chipStyleType,
  onClick,
}) => {
  const [label, type, level] = value as unknown as TableChipCellValue;
  const theme = useTheme();
  const chipStyles = level
    ? getTableChipStyles(theme, type, level, chipStyleType, !!onClick)
    : {};

  const onClickWrapper = useCallback(
    (event, id) => {
      event.stopPropagation();
      if (onClick) onClick(id);
    },
    [onClick],
  );

  return (
    <TableCell align={align ?? "center"}>
      <Chip
        label={label}
        sx={chipStyles}
        // Passing not relevant value to match the typing bc CustomTable will ignore it and pass the row id
        // TODO-5: best practice would be receive the row id as a prop
        onClick={onClick ? (event) => onClickWrapper(event, 0) : undefined}
      />
    </TableCell>
  );
};

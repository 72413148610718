import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { Box, Paper } from "@mui/material";
import { Create, WarningAmberRounded } from "@mui/icons-material";
import {
  CustomButton,
  TextSubHeader,
  DialogInfo,
  RecordIconType,
  CustomTable,
  TableCellType,
  TextHeader,
  TableColumnData,
  SignDialog,
  Order,
  TableChipCellType,
  CellIconData,
} from "components";
import { useStyles } from "hooks";
import {
  CSSGenerator,
  AppRoutes,
  DocumentCompanyStatus,
  DocumentType,
  DocumentCompanyStatusNames,
} from "interfaces";
import { AppContext, FeedbackContext } from "contexts";
import { getMassSignPageTitle, getMassSignDialogInfo } from "utils";
import {
  signCompanyPrecontracts,
  useParticipantsDataForCompanySignature,
} from "api";
import { map } from "lodash";

export const MassPrecontractSignParticipantsPage: React.FC = () => {
  const history = useHistory();
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const { user } = useContext(AppContext);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  /* FETCH DOCUMENT DATA */
  const { sencenet } = useParams<{ sencenet: string }>();
  const { data, loading: loadingData } =
    useParticipantsDataForCompanySignature(sencenet);
  useEffect(() => {
    toggleLoader(
      loadingData,
      "[MassPrecontractSignParticipantsPage] Fetching document data",
    );
  }, [loadingData, toggleLoader]);

  useEffect(() => {
    // Navigate away if uuid not provided
    if (!sencenet) {
      history.replace(AppRoutes.PRECONTRACTS);
      showSnackbar(
        "Ocurrió un error al cargar la lista de participantes",
        "error",
      );
      return;
    }

    // Wait for query to completes
    if (loadingData) return;

    // Navigate away if not data
    if (!data) {
      history.replace(AppRoutes.PRECONTRACTS);
      showSnackbar(
        "Ocurrió un error al cargar la lista de participantes",
        "error",
      );
      return;
    }
  }, [data, history, sencenet, loadingData, showSnackbar]);
  /***********************/

  const styles = useStyles(generateStyles);

  const participantsData = useMemo(
    () =>
      data?.participants
        .map((participant) => {
          const document = data?.documents.find(
            (d) => d.participant === participant.rut,
          );
          if (
            !document ||
            document.companyStatus !== DocumentCompanyStatus.PRESIGNED
          )
            return null;
          return {
            ...participant,
            companyStatus: [
              DocumentCompanyStatusNames[document.companyStatus],
              TableChipCellType.DOCUMENT_STATUS,
              document.companyStatus,
            ],
          };
        })
        .filter(Boolean) ?? [],
    [data?.documents, data?.participants],
  );

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const signParticipantsList = useCallback(async () => {
    toggleLoader(
      true,
      "[MassPrecontractSignParticipantsPage] Signing documents",
    );
    const participantsToSignRuts = map(
      data?.participants.filter((p) => !p.hasMissingData),
      "rut",
    );
    const presignedDocumentIds = map(
      data?.documents.filter(
        (d) =>
          d.companyStatus === DocumentCompanyStatus.PRESIGNED &&
          participantsToSignRuts.includes(d.participant),
      ),
      "id",
    );
    if (presignedDocumentIds.length === 0) {
      toggleLoader(
        false,
        "[MassPrecontractSignParticipantsPage] Signing documents",
      );
      showSnackbar(
        "Ningún participante posee los datos necesarios para realizar la firma masiva",
        "error",
      );
      // history.push(AppRoutes.PRECONTRACTS);
      return;
    }

    const signingSuccesful = await signCompanyPrecontracts(
      presignedDocumentIds,
      DocumentType.PRECONTRACT,
    );
    toggleLoader(
      false,
      "[MassPrecontractSignParticipantsPage] Signing documents",
    );
    if (!signingSuccesful) {
      showSnackbar("Algo falló al firmar los precontratos", "error");
      return;
    }
    showSnackbar("Los precontratos fueron firmados con éxito", "success");
    if (user?.identityFile?.url) {
      history.push(AppRoutes.PRECONTRACTS);
    } else {
      const uuid = data?.documents[0]?.uuid ?? "";
      history.push(
        AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(":uuid", uuid),
        {
          comingFromMassSign: true,
        },
      );
    }
  }, [data, history, showSnackbar, toggleLoader, user?.identityFile?.url]);

  const getFileButtonIconData = useCallback(
    (id: number): CellIconData | null => {
      const participantData = data?.participants.find((p) => p.id === id);
      if (!participantData) return null;
      if (participantData.hasMissingData) {
        return {
          icon: <WarningAmberRounded sx={styles.warningIcon} />,
          tooltipLabel:
            "A este participante le faltan datos, por lo que no será incluído en la firma masiva.",
        };
      }
      return null;
    },
    [data?.participants, styles.warningIcon],
  );

  const enrichedColumnDefs: TableColumnData[] = useMemo(() => {
    const fileCol = columnDefs.find((col) => col.attr === "hasMissingData");
    if (fileCol) {
      fileCol.getIconData = getFileButtonIconData;
    }

    return columnDefs;
  }, [getFileButtonIconData]);

  const disabledSignButton = useMemo<boolean>(() => {
    return (
      data?.participants.filter(
        (p) =>
          !p.hasMissingData &&
          data?.documents.find((d) => d.participant === p.rut)
            ?.companyStatus !== DocumentCompanyStatus.VALIDATED,
      ).length === 0
    );
  }, [data]);

  return (
    <>
      <TextHeader text={getMassSignPageTitle(data?.data?.precontractData)} />
      <Paper sx={styles.containerHeader} elevation={4}>
        <DialogInfo
          icon={RecordIconType.PIN}
          items={getMassSignDialogInfo(data?.data?.precontractData)}
        />
        <TextSubHeader text="Paso 2: Valida la lista de participantes para firmar los precontratos" />
      </Paper>

      <Box sx={styles.background}>
        <Box sx={styles.containerBody}>
          <CustomTable
            rawData={participantsData ?? []}
            columnDefs={enrichedColumnDefs}
          />
          <CustomButton
            sx={styles.signButton}
            variant="contained"
            startIcon={<Create />}
            onClick={openDialog}
            disabled={disabledSignButton}
          >
            {disabledSignButton
              ? "NO HAY DOCUMENTOS QUE FIRMAR"
              : "FIRMAR LISTA DE PARTICIPANTES"}
          </CustomButton>
        </Box>
      </Box>
      <SignDialog
        open={dialogOpen}
        onClose={closeDialog}
        onSignClick={signParticipantsList}
        signerName={user?.name ?? ""}
      />
    </>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  background: {
    backgroundColor: theme.palette.grey[100],
    width: "100%",
    minHeight: "100vh",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      paddingTop: "calc(32px + 64px)", // gap + (header / 2) (lower section hidden)
    },
  },
  signButton: {
    marginTop: "30px",
    marginLeft: "auto",
  },
  containerBody: {
    padding: "32px",
  },
  containerHeader: {
    width: "100%",
    margin: "65px 0px 5px 0px ",
  },
  warningIcon: {
    color: theme.palette.warning.light,
  },
});

const columnDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Nombre",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Rut",
    attr: "rut",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Nacionalidad",
    attr: "nationality",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Fecha de nacimiento",
    attr: "birthdate",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Dirección",
    attr: "address",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Ciudad",
    attr: "city",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Teléfono",
    attr: "phone",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Correo electrónico",
    attr: "email",
    sortable: true,
  },
  // {
  //   cellType: TableCellType.CHIP,
  //   headerName: "Firma Empresa",
  //   attr: "companyStatus",
  //   sortable: true,
  //   sortedByDefault: true,
  //   chipStyleType: TableChipCellStyle.PRIMARY,
  //   sortFn: (a: any, b: any, order: Order) => {
  //     const statusA = a["companyStatus"][2] as DocumentCompanyStatus;
  //     const statusB = b["companyStatus"][2] as DocumentCompanyStatus;
  //     return compareDocumentCompanyStatuses(statusA, statusB, order);
  //   },
  // },
  {
    cellType: TableCellType.ICON,
    headerName: "",
    attr: "hasMissingData",
    sortedByDefault: true,
    sortedByDefaultDirection: Order.DESC,
  },
];

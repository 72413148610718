import React from "react";
import {
  Create,
  Lock,
  Description,
  Visibility,
  Email,
  PushPinOutlined,
  SvgIconComponent,
  CheckCircleRounded,
  Task,
  Error,
  Feedback,
  UploadFile,
  Timer,
  Help,
  AddTask,
  Warning,
  AccountBoxRounded,
} from "@mui/icons-material";
import { alpha, Box } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator, EventType } from "interfaces";
import { SxProps } from "@mui/system";

export enum RecordIconType {
  DOCUMENT = "DOCUMENT",
  WATCH = "WATCH",
  SIGN = "SIGN",
  MESSAGE = "MESSAGE",
  LOCK = "LOCK",
  DOCUMENT_SUCCESSFUL = "DOCUMENT_SUCCESSFUL",
  CHECK_CIRCLE = "CHECK_CIRCLE",
  PIN = "PIN",
  ERROR = "ERROR",
  FEEDBACK = "FEEDBACK",
  UPLOAD_FILE = "UPLOAD_FILE",
  TIMER = "TIMER",
  HELP = "HELP",
  ADD_TASK = "ADD_TASK",
  WARNING = "WARNING",
  IDENTITY_FILE = "IDENTITY_FILE",
}

export const EventIconMapping: Record<EventType, RecordIconType> = {
  [EventType.DOCUMENT_GENERATED]: RecordIconType.DOCUMENT,
  [EventType.MANUAL_SIGN_REQUEST]: RecordIconType.MESSAGE,
  [EventType.AUTOMATIC_SIGN_REQUEST]: RecordIconType.MESSAGE,
  [EventType.PRESIGN]: RecordIconType.SIGN,
  [EventType.SIGN]: RecordIconType.SIGN,
  [EventType.CORRECTION_REQUEST]: RecordIconType.FEEDBACK,
  [EventType.VALIDATION]: RecordIconType.CHECK_CIRCLE,
  [EventType.DOCUMENT_VISUALIZATION]: RecordIconType.WATCH,
  [EventType.MANUAL_UPLOAD]: RecordIconType.UPLOAD_FILE,
  [EventType.FORCED_VALIDATION]: RecordIconType.ADD_TASK,
  [EventType.EXPIRATION]: RecordIconType.TIMER,
  [EventType.PROCESS_END]: RecordIconType.CHECK_CIRCLE,
  [EventType.DOCUMENT_ACTIVATION]: RecordIconType.DOCUMENT,
  [EventType.DOCUMENT_INACTIVATION]: RecordIconType.DOCUMENT,
  [EventType.IDENTITY_FILE_UPLOAD]: RecordIconType.IDENTITY_FILE,
  [EventType.IDENTITY_FILE_VALIDATION]: RecordIconType.CHECK_CIRCLE,
};

export enum RecordIconStyle {
  PRIMARY = "PRIMARY",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  GREY = "GREY",
  SECONDARY = "SECONDARY",
}

const defaultStyles: Record<RecordIconType, RecordIconStyle> = {
  [RecordIconType.DOCUMENT]: RecordIconStyle.SUCCESS,
  [RecordIconType.WATCH]: RecordIconStyle.GREY,
  [RecordIconType.SIGN]: RecordIconStyle.INFO,
  [RecordIconType.MESSAGE]: RecordIconStyle.ERROR,
  [RecordIconType.LOCK]: RecordIconStyle.PRIMARY,
  [RecordIconType.DOCUMENT_SUCCESSFUL]: RecordIconStyle.PRIMARY,
  [RecordIconType.CHECK_CIRCLE]: RecordIconStyle.SUCCESS,
  [RecordIconType.PIN]: RecordIconStyle.INFO,
  [RecordIconType.ERROR]: RecordIconStyle.ERROR,
  [RecordIconType.FEEDBACK]: RecordIconStyle.SECONDARY,
  [RecordIconType.UPLOAD_FILE]: RecordIconStyle.INFO,
  [RecordIconType.TIMER]: RecordIconStyle.ERROR,
  [RecordIconType.HELP]: RecordIconStyle.GREY,
  [RecordIconType.ADD_TASK]: RecordIconStyle.SUCCESS,
  [RecordIconType.WARNING]: RecordIconStyle.ERROR,
  [RecordIconType.IDENTITY_FILE]: RecordIconStyle.INFO,
};

const Icons: Record<RecordIconType, SvgIconComponent> = {
  [RecordIconType.DOCUMENT]: Description,
  [RecordIconType.WATCH]: Visibility,
  [RecordIconType.SIGN]: Create,
  [RecordIconType.MESSAGE]: Email,
  [RecordIconType.LOCK]: Lock,
  [RecordIconType.DOCUMENT_SUCCESSFUL]: Task,
  [RecordIconType.CHECK_CIRCLE]: CheckCircleRounded,
  [RecordIconType.PIN]: PushPinOutlined,
  [RecordIconType.ERROR]: Error,
  [RecordIconType.FEEDBACK]: Feedback,
  [RecordIconType.TIMER]: Timer,
  [RecordIconType.HELP]: Help,
  [RecordIconType.UPLOAD_FILE]: UploadFile,
  [RecordIconType.ADD_TASK]: AddTask,
  [RecordIconType.WARNING]: Warning,
  [RecordIconType.IDENTITY_FILE]: AccountBoxRounded,
};

type Props = {
  icon: RecordIconType;
  styleType?: RecordIconStyle;
  iconStyle?: SxProps;
  backgroundStyle?: SxProps;
};

export const RecordIcon: React.FC<Props> = ({
  icon,
  styleType,
  iconStyle,
  backgroundStyle,
}) => {
  const styles = useStyles(generateStyles, styleType ?? defaultStyles[icon]);
  const Icon = Icons[icon];
  return (
    <Box sx={{ ...styles.background, ...backgroundStyle }}>
      <Icon sx={{ ...styles.icon, ...iconStyle }} fontSize="inherit" />
    </Box>
  );
};

const generateStyles: CSSGenerator<RecordIconStyle> = (theme, styleType) => {
  const styles: Record<
    RecordIconStyle,
    { iconColor: string; backgroundColor: string }
  > = {
    [RecordIconStyle.PRIMARY]: {
      iconColor: theme.palette.common.white,
      backgroundColor: theme.palette.primary.light,
    },
    [RecordIconStyle.INFO]: {
      iconColor: theme.palette.info.dark,
      backgroundColor: alpha(theme.palette.info.light, 0.2),
    },
    [RecordIconStyle.SUCCESS]: {
      iconColor: theme.palette.success.dark,
      backgroundColor: alpha(theme.palette.success.light, 0.2),
    },
    [RecordIconStyle.ERROR]: {
      iconColor: theme.palette.error.dark,
      backgroundColor: alpha(theme.palette.error.light, 0.2),
    },
    [RecordIconStyle.GREY]: {
      iconColor: theme.palette.grey[700],
      backgroundColor: alpha(theme.palette.grey[100], 0.2),
    },
    [RecordIconStyle.SECONDARY]: {
      iconColor: theme.palette.secondary.dark,
      backgroundColor: alpha(theme.palette.secondary.light, 0.2),
    },
  };

  return {
    background: {
      borderRadius: "100%",
      backgroundColor: styles[styleType as RecordIconStyle].backgroundColor,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30px",
      height: "30px",
    },
    icon: {
      color: styles[styleType as RecordIconStyle].iconColor,
    },
  };
};

import { useCallback, useState } from "react";
import {
  alpha,
  Avatar,
  Box,
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CustomButton,
  TableChipCellStyle,
  TableChipCellType as ChipType,
} from "components";
import { useStyles } from "hooks";
import {
  CSSGenerator,
  DocumentParticipantInfo,
  DocumentStatus,
  DocumentStatusNames,
} from "interfaces";
import { getTableChipStyles } from "styles";
import { CustomDialog } from ".";
import { EmailInput } from "..";
import { validateEmail } from "utils";

type Props = {
  participants: DocumentParticipantInfo[];
  open: boolean;
  onFinish: (email: string | null) => void;
  onClose: () => void;
  toResponsable: boolean;
};

export const MassiveReminderDialog: React.FC<Props> = ({
  participants,
  open,
  onFinish,
  onClose,
  toResponsable,
}) => {
  const styles = useStyles(generateDialogStyles);
  const [email, setEmail] = useState<string | null>(null);
  const onInnerFinish = useCallback(() => {
    onFinish(email);
  }, [email, onFinish]);
  return (
    <CustomDialog
      open={open}
      title={COMPONENT_TEXTS[String(toResponsable)].title}
      onClose={onClose}
      maxWidth={"md"}
    >
      <DialogContent sx={styles.content}>
        {toResponsable && (
          <Box sx={styles.emailContainer}>
            <Typography>Destinatario:</Typography>
            <EmailInput
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              withLabel={false}
            />
          </Box>
        )}
        <Typography>
          {COMPONENT_TEXTS[String(toResponsable)].description}
        </Typography>
        <Typography sx={styles.subtitle}>{`${participants.length} participante${
          participants.length > 1 ? "s" : ""
        }`}</Typography>
        <Box sx={styles.participantsContainer}>
          {participants.map((participant, idx) => (
            <ParticipantAvatar
              key={`participantAvatar-${idx}`}
              name={participant.participant.name}
              email={participant.participant.email}
              status={participant.mainSignerStatus}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose}>CANCELAR</CustomButton>
        <CustomButton
          variant="contained"
          onClick={onInnerFinish}
          disabled={toResponsable && !validateEmail(email ?? "")}
        >
          {COMPONENT_TEXTS[String(toResponsable)].button}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

const COMPONENT_TEXTS: Record<
  string,
  { title: string; description: string; button: string }
> = {
  // Responsable
  true: {
    title: "Enviar recordatorio de gestión",
    description:
      "Vas a enviar un recordatorio de gestión para los siguientes participantes:",
    button: "ENVIAR RECORDATORIO",
  },
  // Participant
  false: {
    title: "Enviar recordatorio masivo",
    description:
      "Vas a enviar un recordatorio de firma a los siguientes participantes faltantes:",
    button: "ENVIAR RECORDATORIOS",
  },
};

const generateDialogStyles: CSSGenerator = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "26px",
    marginTop: "16px",
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  participantsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "26px",
  },
  emailContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    paddingTop: "8px",
  },
});

const ParticipantAvatar: React.FC<{
  name: string;
  email: string;
  status: DocumentStatus;
}> = ({ name, email, status }) => {
  const styles = useStyles(generateAvatarStyles);
  const theme = useTheme();
  const chipStyle = getTableChipStyles(
    theme,
    ChipType["DOCUMENT_STATUS"],
    status,
    TableChipCellStyle.PRIMARY,
    true,
  );

  return (
    <Grid container spacing={1}>
      <Grid item sm={1}>
        <Avatar />
      </Grid>
      <Grid item sm={8}>
        <Box>
          <Typography sx={styles.name}>{name}</Typography>
          <Typography sx={styles.email}>{email}</Typography>
        </Box>
      </Grid>
      <Grid item sm={3}>
        <Chip label={DocumentStatusNames[status]} sx={chipStyle} />
      </Grid>
    </Grid>
  );
};

const generateAvatarStyles: CSSGenerator = (theme) => ({
  name: {
    fontWeight: 500,
  },
  email: {
    color: alpha(theme.palette.common.black, 0.54),
    fontSize: "13px",
  },
});

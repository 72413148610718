import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { esES } from "@mui/material/locale";
import { AppRoutes } from "./interfaces";
import { Pages } from "./pages";
import { PrivateRoute } from "./components";
import { customTheme } from "styles";
import {
  AppProvider,
  AuthProvider,
  DocumentDialogProvider,
  FeedbackProvider,
  MessageProvider,
} from "contexts";
import esLocale from "date-fns/locale/es";
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { client } from "./api";
import { ApolloProvider } from "@apollo/client";

function App() {
  const theme = createTheme(
    {
      ...customTheme,
    },
    esES,
  );
  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Helmet>
          <title>Sucursal Virtual - OTIC CChC</title>
          <link rel="icon" href="/favicon.png" type="image/svg" />
          <link rel="preconnect" href="https://fonts.googleapis.com"></link>
          <link rel="preconnect" href="https://fonts.gstatic.com"></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"
            rel="stylesheet"
          ></link>
          <link
            href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&display=swap"
            rel="stylesheet"
          ></link>
        </Helmet>
      </HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <ApolloProvider client={client}>
          <FeedbackProvider>
            <AuthProvider>
              <AppProvider>
                <div className="App">
                  <CssBaseline />
                  <Router>
                    <MessageProvider>
                      <DocumentDialogProvider>
                        <Switch>
                          <Route exact path="/">
                            <Redirect to={AppRoutes.DASHBOARD} />
                          </Route>
                          <PrivateRoute
                            path={AppRoutes.DASHBOARD}
                            component={Pages[AppRoutes.DASHBOARD]}
                          />
                          <PrivateRoute
                            path={AppRoutes.SWORN_STATEMENT_HISTORIAL}
                            component={
                              Pages[AppRoutes.SWORN_STATEMENT_HISTORIAL]
                            }
                            withLayout={false}
                          />
                          <PrivateRoute
                            path={AppRoutes.PRECONTRACT_HISTORIAL}
                            component={Pages[AppRoutes.PRECONTRACT_HISTORIAL]}
                            withLayout={false}
                          />
                          <PrivateRoute
                            path={AppRoutes.ELEARNING_HISTORIAL}
                            component={Pages[AppRoutes.ELEARNING_HISTORIAL]}
                            withLayout={false}
                          />
                          <PrivateRoute
                            path={AppRoutes.ONSITE_HISTORIAL}
                            component={Pages[AppRoutes.ONSITE_HISTORIAL]}
                            withLayout={false}
                          />
                          <PrivateRoute
                            path={AppRoutes.PRECONTRACT_DETAILS}
                            component={Pages[AppRoutes.PRECONTRACT_DETAILS]}
                          />
                          <PrivateRoute
                            path={AppRoutes.SWORN_STATEMENT_DETAILS}
                            component={Pages[AppRoutes.SWORN_STATEMENT_DETAILS]}
                          />
                          <PrivateRoute
                            path={AppRoutes.SWORN_STATEMENT_ELEARNING_DETAILS}
                            component={
                              Pages[AppRoutes.SWORN_STATEMENT_ELEARNING_DETAILS]
                            }
                          />
                          <PrivateRoute
                            path={AppRoutes.LCE_ONSITE_DETAILS}
                            component={Pages[AppRoutes.LCE_ONSITE_DETAILS]}
                          />
                          <PrivateRoute
                            path={AppRoutes.PRECONTRACTS}
                            component={Pages[AppRoutes.PRECONTRACTS]}
                          />
                          <PrivateRoute
                            path={AppRoutes.SWORN_STATEMENTS}
                            component={Pages[AppRoutes.SWORN_STATEMENTS]}
                          />
                          <PrivateRoute
                            path={AppRoutes.SWORN_STATEMENTS_ELEARNING}
                            component={
                              Pages[AppRoutes.SWORN_STATEMENTS_ELEARNING]
                            }
                          />
                          <PrivateRoute
                            path={AppRoutes.LCE_ONSITE}
                            component={Pages[AppRoutes.LCE_ONSITE]}
                          />
                          <Route
                            path={AppRoutes.SIGN_DOCUMENT}
                            component={Pages[AppRoutes.SIGN_DOCUMENT]}
                          />
                          <Route
                            path={AppRoutes.UPLOAD_IDENTIFICATION_FILE}
                            component={
                              Pages[AppRoutes.UPLOAD_IDENTIFICATION_FILE]
                            }
                          />
                          <Route
                            path={`${AppRoutes.SUCCESSFUL_SIGNATURE}/:uuid`}
                            component={Pages[AppRoutes.SUCCESSFUL_SIGNATURE]}
                          />
                          <Route
                            path={`${AppRoutes.OUTDATED_SIGNATURE}/:uuid`}
                            component={Pages[AppRoutes.OUTDATED_SIGNATURE]}
                          />
                          <PrivateRoute
                            path={AppRoutes.MASS_PRECONTRACT_SIGN_PARTICIPANTS}
                            component={
                              Pages[
                                AppRoutes.MASS_PRECONTRACT_SIGN_PARTICIPANTS
                              ]
                            }
                            withLayout={false}
                          />
                          <PrivateRoute
                            path={AppRoutes.MASS_PRECONTRACT_SIGN_TEMPLATE}
                            component={
                              Pages[AppRoutes.MASS_PRECONTRACT_SIGN_TEMPLATE]
                            }
                            withLayout={false}
                          />
                          <Route>
                            <Redirect to={AppRoutes.DASHBOARD} />
                          </Route>
                        </Switch>
                      </DocumentDialogProvider>
                    </MessageProvider>
                  </Router>
                </div>
              </AppProvider>
            </AuthProvider>
          </FeedbackProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;

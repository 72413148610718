import { useMemo } from "react";
import { Box, alpha } from "@mui/material";
import {
  DocumentFooter,
  DocumentPage,
  DocumentParagraph,
  DocumentSubtitle,
  DocumentTable,
  DocumentTitle,
  SignatureCard,
  SignHereCard,
} from "components";
import { useStyles } from "hooks";
import { CSSGenerator, DJOData, EventType } from "interfaces";
import {
  getResponsiveSize,
  injectData,
  formatSignDate,
  generateRecordItems,
  getEventMainText,
  divideDataInTableBreakpoints,
  humanFormatDate,
} from "utils";
import { DocumentComponentType, TableBreakpoints, EventsPages } from ".";

const TABLE_HEADERS = ["APELLIDO PATERNO", "APELLIDO MATERNO", "NOMBRE", "RUT"];
const TABLE_BREAKPOINTS: TableBreakpoints = {
  firstPage: 18,
  fullPage: 34,
  firstPageWithBottomText: 15,
};

const collator = new Intl.Collator("es", { numeric: true });

export const DJODocument: DocumentComponentType = ({
  data,
  openDialog,
  signed,
  settings,
  user,
}) => {
  const {
    title,
    subtitle,
    firstParagraph,
    secondParagraph,
    thirdParagraph,
    footer,
  } = settings?.djoDocumentText ?? {};
  const styles = useStyles(generateStyles);

  const parsedData = useMemo(
    () => ({
      ...generateDJOReplaceableData(data.djoData),
      // Overwrite signer name and rut
      "firstSigner-name": user?.name ?? "",
      "firstSigner-rut": user?.rut ?? "",
    }),
    [data, user],
  );

  const participantsParsedData = useMemo(() => {
    const parsedData =
      data?.djoData?.participants.map((p) => ({
        firstLastname: p.firstLastname,
        secondaryLastname: p.secondaryLastname,
        firstname: p.firstname,
        rut: p.rut,
      })) ?? [];
    return parsedData.sort((a, b) => collator.compare(a.rut, b.rut));
  }, [data]);

  const participantsByBreakpoints = useMemo(
    () =>
      divideDataInTableBreakpoints(participantsParsedData, TABLE_BREAKPOINTS),
    [participantsParsedData],
  );

  const parsedEvents = useMemo(
    () =>
      data.document
        ? [
            ...generateRecordItems(data.document, data.document.events),
            {
              mainText: getEventMainText(
                {
                  type: EventType.SIGN,
                  personName: user?.name ?? "",
                  personEmail: user?.email ?? "",
                },
                data?.document,
              ),
              date: formatSignDate(new Date()),
              eventType: EventType.SIGN,
            },
          ]
        : [],
    [data, user],
  );

  return (
    <>
      <DocumentPage sx={styles.root}>
        <DocumentTitle text={title} />
        <DocumentSubtitle
          sx={styles.subtitle}
          text={injectData(subtitle, parsedData)}
        />
        <DocumentParagraph text={injectData(firstParagraph, parsedData)} />
        <DocumentTable
          headers={TABLE_HEADERS}
          rows={participantsByBreakpoints.firstPage}
        />
        {!participantsByBreakpoints.remaining && (
          <DocumentParagraph text={injectData(secondParagraph, parsedData)} />
        )}
      </DocumentPage>
      {participantsByBreakpoints.remaining?.map((participants, idx) => (
        <DocumentPage key={`remainingParticipantsPage-${idx}`}>
          <DocumentTable headers={TABLE_HEADERS} rows={participants} />
        </DocumentPage>
      ))}

      <DocumentPage sx={styles.secondPage}>
        <Box sx={styles.upperContainer}>
          {participantsByBreakpoints.remaining && (
            <DocumentParagraph text={injectData(secondParagraph, parsedData)} />
          )}
          <DocumentParagraph text={injectData(thirdParagraph, parsedData)} />
          <Box sx={styles.signatureContainer}>
            <DocumentParagraph text="OTEC - Representante Legal" />
            {signed ? (
              <>
                <SignatureCard
                  name={user?.name ?? ""}
                  rut={user?.rut ?? ""}
                  role={user?.role ?? ""}
                />
                <Box>
                  <DocumentParagraph
                    sx={styles.signatureBottomText}
                    text={formatSignDate(new Date())}
                  />
                  <DocumentParagraph
                    sx={styles.signatureBottomText}
                    text={`Firmado mediante ${user?.email}`}
                  />
                </Box>
              </>
            ) : (
              <SignHereCard
                name={user?.name ?? ""}
                rut={user?.rut ?? ""}
                role={user?.role ?? ""}
                onClick={openDialog}
              />
            )}
          </Box>
        </Box>
        <DocumentFooter footNotes={[footer ?? ""]} />
      </DocumentPage>
      {signed && data.document && (
        <EventsPages items={parsedEvents} document={data.document} />
      )}
    </>
  );
};

const generateDJOReplaceableData = (
  data?: DJOData,
): Record<string, string | number> => {
  if (!data) return {};
  return {
    signDate: data.signDate,
    "firstSigner-rut": data.firstSigner.rut,
    "firstSigner-name": data.firstSigner.name,
    "firstSigner-address": data.firstSigner.address,
    "firstSigner-commune": data.firstSigner.commune,
    "firstSigner-city": data.firstSigner.city,
    "firstSigner-region": data.firstSigner.region,
    "otec-rut": data.otec.rut,
    "otec-name": data.otec.name,
    "course-name": data.course.name,
    "course-senceCode": data.course.senceCode,
    "course-sencenetCode": data.course.sencenet,
    "course-startDate": humanFormatDate(new Date(data.course.startDate)),
    "course-endDate": humanFormatDate(new Date(data.course.endDate)),
  };
};

const generateStyles: CSSGenerator = (theme) => ({
  secondPage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  upperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: getResponsiveSize(21),
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: getResponsiveSize(76),
    gap: getResponsiveSize(21),
  },
  signatureBottomText: {
    color: alpha(theme.palette.common.black, 0.54),
  },
});

export enum Permission {
  CAN_ADD_USER = "CAN_ADD_USER",
  CAN_LIST_USERS = "CAN_LIST_USERS",
  CAN_EDIT_USER = "CAN_EDIT_USER",
  CAN_DELETE_USER = "CAN_DELETE_USER",
  CAN_ACCESS_PRECONTRACTS = "CAN_ACCESS_PRECONTRACTS",
  CAN_ACCESS_DJS = "CAN_ACCESS_DJS",
  CAN_ACCESS_DJS_ELEARNING = "CAN_ACCESS_DJS_ELEARNING",
  CAN_ACCESS_LCE_ONSITE = "CAN_ACCESS_LCE_ONSITE",
  CAN_VIEW_DJP_FILE = "CAN_VIEW_DJP_FILE",
  CAN_VIEW_LCE_FILE = "CAN_VIEW_LCE_FILE",
  CAN_LIST_COURSES = "CAN_LIST_COURSES",
  CAN_VIEW_COURSE = "CAN_VIEW_COURSE",
  CAN_VIEW_COURSE_HISTORY = "CAN_VIEW_COURSE_HISTORY",
  CAN_VIEW_PARTICIPANT_HISTORY = "CAN_VIEW_PARTICIPANT_HISTORY",
  CAN_SEND_BUSINESS_MAIL = "CAN_SEND_BUSINESS_MAIL",
  CAN_SEND_PARTICIPANT_MAIL = "CAN_SEND_PARTICIPANT_MAIL",
  CAN_UPLOAD_PARTICIPANT_FILE = "CAN_UPLOAD_PARTICIPANT_FILE",
  CAN_VIEW_PARTICIPANT_FILE = "CAN_VIEW_PARTICIPANT_FILE",
  CAN_VALIDATE_FILE = "CAN_VALIDATE_FILE",
  CAN_REQUEST_AMEND = "CAN_REQUEST_AMEND",
  CAN_SEND_RESPONSABLE_MAIL = "CAN_SEND_RESPONSABLE_MAIL",
  CAN_SIGN_PRECONTRACT_COMPANY = "CAN_SIGN_PRECONTRACT_COMPANY",
  CAN_FORCE_VALIDATION = "CAN_FORCE_VALIDATION",
  /* DJOs */
  CAN_VIEW_OTEC_FILE = "CAN_VIEW_OTEC_FILE",
  CAN_SIGN_DJO = "CAN_SIGN_DJO",
  CAN_UPLOAD_DJO_FILE = "CAN_UPLOAD_DJO_FILE",
  CAN_REQUEST_AMEND_DJO = "CAN_REQUEST_AMEND_DJO",
  CAN_VALIDATE_DJO = "CAN_VALIDATE_DJO",
  CAN_VIEW_OTEC_HISTORY = "CAN_VIEW_OTEC_HISTORY",
  CAN_SEND_OTEC_MAIL = "CAN_SEND_OTEC_MAIL",
  CAN_VIEW_MINORAUTH_FILE = "CAN_VIEW_MINORAUTH_FILE",
  CAN_UPLOAD_MINORAUTH_FILE = "CAN_UPLOAD_MINORAUTH_FILE",
  CAN_VALIDATE_MINORAUTH = "CAN_VALIDATE_MINORAUTH",
  CAN_REQUEST_AMEND_MINORAUTH = "CAN_REQUEST_AMEND_MINORAUTH",
  CAN_VIEW_VULNERABILITY_FILE = "CAN_VIEW_VULNERABILITY_FILE",
  CAN_UPLOAD_VULNERABILITY_FILE = "CAN_UPLOAD_VULNERABILITY_FILE",
  CAN_VALIDATE_VULNERABILITY = "CAN_VALIDATE_VULNERABILITY",
  CAN_REQUEST_AMEND_VULNERABILITY = "CAN_REQUEST_AMEND_VULNERABILITY",
}

export interface Role {
  id: number;
  name: string;
}

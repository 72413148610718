import { gql, useQuery } from "@apollo/client";
import { MassPrecontractDocumentData } from ".";
import { PrecontractDocumentDataFragment } from "../fragments";

interface QueryParams {
  params: { sencenet: string };
}

interface QueryResult {
  getModularPrecontractsDataForCompanySignature: MassPrecontractDocumentData | null;
}

interface HookReturn {
  data: MassPrecontractDocumentData | null;
  loading: boolean;
}

export const usePrecontractsDataForCompanySignature = (
  sencenet: string,
): HookReturn => {
  const { data, loading } = useQuery<QueryResult, QueryParams>(
    PRECONTRACTS_DATA_QUERY,
    {
      variables: {
        params: {
          sencenet,
        },
      },
    },
  );
  return {
    data: data?.getModularPrecontractsDataForCompanySignature ?? null,
    loading,
  };
};

const PRECONTRACTS_DATA_QUERY = gql`
  query GetModularPrecontractsDataForCompanySignature(
    $params: GetModularPrecontractsDataParams!
  ) {
    getModularPrecontractsDataForCompanySignature(params: $params) {
      data {
        precontractData {
          ...PrecontractDocumentDataFields
        }
      }
      documents {
        id
        companyStatus
      }
    }
  }
  ${PrecontractDocumentDataFragment}
`;

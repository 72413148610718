
import { GetUrlCourseFileParams, CourseFileUrl } from "interfaces";
import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";

export const GET_URL_COURRSE_FILE = ({type, sencenet} : GetUrlCourseFileParams ) => gql`
  query GetUrlCourseFile($params: GetUrlCourseFileParams!) {
    getUrlCourseFile(params: $params) 
  }
`

export const useUrlCourseFile = (
  params: GetUrlCourseFileParams,
): {
  data: any;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getUrlCourseFile: CourseFileUrl;
  }>(GET_URL_COURRSE_FILE(params), {
    variables: { params },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_URL_COURRSE_FILE] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });

  return {
    data,
    loading
  };
};
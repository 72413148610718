import { Box, Typography, alpha } from "@mui/material";
import { RecordIconType, RecordIcon, CustomButton } from "components";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";

type IconType = "success" | "error";

type Props = {
  title: string;
  description: string;
  email?: string;
  bodyText: string;
  iconType: IconType;
  icon: RecordIconType;
  helpIcon?: RecordIconType;
  showButtonDocument?: boolean;
  showCheckIcon?: boolean;
  onClick?: () => void;
};

export const PostSignPageContent: React.FC<Props> = ({
  title,
  description,
  icon,
  showButtonDocument = true,
  bodyText,
  email,
  showCheckIcon = true,
  onClick,
  iconType,
  helpIcon,
}) => {
  const styles = useStyles(generateStyles, iconType);
  return (
    <>
      <Box sx={styles.background}>
        <RecordIcon
          icon={icon}
          iconStyle={styles.icon}
          backgroundStyle={styles.iconBackground}
        />
        <Typography sx={styles.textTitle}>{title}</Typography>
        <Box sx={styles.container}>
          <Typography sx={styles.textBody}>{description}</Typography>
        </Box>
        {showCheckIcon && (
          <Typography sx={styles.textEmail}>
            {bodyText} {email && <b>{email}</b>}
          </Typography>
        )}
        {showButtonDocument && (
          <CustomButton sx={styles.button} onClick={onClick}>
            VER DOCUMENTO FIRMADO
          </CustomButton>
        )}
      </Box>
      {!showCheckIcon && (
        <Box sx={styles.container}>
          {helpIcon && (
            <RecordIcon iconStyle={styles.iconHelp} icon={helpIcon} />
          )}
          <Typography sx={styles.textEmail}>{bodyText}</Typography>
        </Box>
      )}
    </>
  );
};

const generateStyles: CSSGenerator<IconType> = (theme, iconType) => {
  const iconColor =
    iconType === "success"
      ? theme.palette.primary.light
      : iconType === "error"
      ? theme.palette.error.dark
      : undefined;

  return {
    background: {
      marginTop: "3vh",
      backgroundColor: theme.palette.common.white,
      width: "100vw",
      minHeight: "85vh",
      display: "flex",
      /* justifyContent: "center", */
      flexDirection: "column",
      gap: "32px",
      alignItems: "center",
      // paddingTop: gap + header
      paddingTop: "calc(32px + 128px)",
      paddingBottom: "32px",
      [theme.breakpoints.down("sm")]: {
        // paddingTop: gap + header / 2 (lower section hidden)
        paddingTop: "calc(32px + 64px)",
      },
    },
    button: {
      border: "1px solid",
      width: "257px",
      height: "42px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    textTitle: {
      color: theme.palette.text.primary,
      fontWeight: 500,
      fontSize: "33px",
      textAlign: "center",
      padding: "0px 30px 0px 30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "23px",
      },
    },
    textBody: {
      color: theme.palette.common.black,
      fontSize: "23px",
      opacity: 0.54,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    textEmail: {
      color: theme.palette.primary.main,
      fontWeight: "500",
      fontSize: "23px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    icon: {
      color: iconColor,
      height: "78px",
      width: "63px",
      [theme.breakpoints.down("sm")]: {
        height: "43px",
        width: "32px",
      },
    },
    iconBackground: {
      background: iconColor ? alpha(iconColor, 0.1) : undefined,
      height: "133px",
      width: "133px",
      [theme.breakpoints.down("sm")]: {
        height: "53px",
        width: "53px",
      },
    },
    container: {
      display: "flex",
      padding: "0px 20% 0px 20%",
      gap: "4px",
      justifyContent: "center",
    },
    iconCheck: {
      background: theme.palette.common.white,
      color: theme.palette.success.main,
      height: "32px",
      width: "32px",
      [theme.breakpoints.down("sm")]: {
        height: "23px",
        width: "23px",
      },
    },
    iconHelp: {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      height: "32px",
      width: "32px",
      [theme.breakpoints.down("sm")]: {
        height: "23px",
        width: "23px",
      },
    },
  };
};

import { useContext } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { useStyles } from "hooks";
import { CSSStyles } from "interfaces";
import { AppContext } from "contexts";

export const AvatarComponent: React.FC = () => {
  const styles = useStyles(generateStyles);
  const { user } = useContext(AppContext);

  return (
    <Box sx={styles.root}>
      <Typography>{user?.name}</Typography>
      <Avatar />
    </Box>
  );
};

const generateStyles: CSSStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
};

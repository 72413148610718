import { gql } from "@apollo/client";
import {
  client,
  GET_DJ_COURSE_DETAILS_QUERY,
  GET_PRECONTRACT_COURSE_DETAILS_QUERY,
} from "api";
import { DocumentType } from "interfaces";

interface MutationParams {
  params: {
    documentId: number;
    file: File;
  };
}
interface RefetchParams {
  params: {
    sencenet: string;
    documentType: DocumentType;
  };
}
interface MutationResult {
  uploadManualDocument: boolean | null;
}

export const uploadManualDocument = async (
  params: MutationParams["params"],
  refetchParams: RefetchParams["params"],
  documentType: DocumentType,
): Promise<boolean | null> => {
  if (!params.documentId || !params.file) return false;
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation UploadManualDocument($params: UploadManualDocumentParams!) {
        uploadManualDocument(params: $params)
      }
    `,
    variables: {
      params,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query:
          documentType === DocumentType.PRECONTRACT
            ? GET_PRECONTRACT_COURSE_DETAILS_QUERY(documentType)
            : GET_DJ_COURSE_DETAILS_QUERY(documentType, refetchParams.sencenet),
        variables: {
          params: refetchParams,
        },
      },
    ],
  });
  return !!response.data?.uploadManualDocument;
};

import { format as formatDate } from "date-fns";
import es from "date-fns/locale/es";
import queryString from "query-string";
import parse from "html-react-parser";
import {
  InsertDriveFileOutlined,
  PlagiarismOutlined,
  UploadFileOutlined,
  AddTaskOutlined,
  CommentOutlined,
} from "@mui/icons-material";
import { clone } from "lodash";
import {
  PAGE_DIMENSIONS,
  Row,
  TableBreakpoints,
  CellIconData,
  DialogInfoItem,
} from "components";
import {
  ComponentType,
  DocumentType,
  DocumentData,
  DocumentShortNames,
  DocumentNames,
  CurrentUser,
  DocumentStatus,
  PrecontractDocumentData,
  Document,
  DocumentFormat,
  Participant,
} from "interfaces";
import { AvailableDocumentActions } from "hooks";
import { slashFormatDate } from "utils";

// WARNING: Only for PageWidth = 70vw
const RESPONSIVENESS_FACTOR = 1100;
// Get responsive size of given size (in px) based on a 1440px screen
export const getResponsiveSize = (size: number): string =>
  `calc(${PAGE_DIMENSIONS.width} * ${size / RESPONSIVENESS_FACTOR})`;

export const setSizeByComponentType = (
  size: number,
  type: ComponentType | undefined,
): string =>
  type === ComponentType.DOCUMENT ? getResponsiveSize(size) : `${size}px`;

// Styles: underlined and bold
export const injectFieldStyleTags = (field: string | number): string =>
  `<u><b>${String(field).toUpperCase()}</b></u>`;

export const injectData = (
  text: string = "",
  data: Record<string, string | number | undefined>,
  injectTags: boolean = true,
): string | JSX.Element | JSX.Element[] => {
  if (!text) return "";
  let replacedText = text;
  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      replacedText = replacedText.replaceAll(
        `{{${key}}}`,
        injectTags ? injectFieldStyleTags(value) : String(value),
      );
    }
  });
  return parse(replacedText);
};

export const getSuperIndex = (number: string): string => {
  const numberMapping: Record<string, string> = {
    "0": "⁰",
    "1": "¹",
    "2": "²",
    "3": "³",
    "4": "⁴",
    "5": "⁵",
    "6": "⁶",
    "7": "⁷",
    "8": "⁸",
    "9": "⁹",
  };
  return number.replace(/[0123456789]/g, (match) => {
    if (Object.keys(numberMapping).includes(match)) {
      return numberMapping[match];
    }
    return match;
  });
};

export const formatSignDate = (date: string | Date | number): string =>
  formatDate(new Date(date), "d 'de' MMMM, y kk':'mm", { locale: es });

export const getSignPageTitle = (data?: DocumentData | null): string => {
  if (!data || !data.document) return "";
  const docName = data.document.type
    ? DocumentShortNames[data.document.type]
    : "";
  let finalText = "";
  if (data.document.type === DocumentType.DJP)
    finalText = data.djpData?.course.name ?? "";
  if (data.document.type === DocumentType.DJO)
    finalText = data.djoData?.course.name ?? "";
  if (data.document.type === DocumentType.PRECONTRACT)
    finalText = data.precontractData?.company.name ?? "";

  const separator = !!docName && !!finalText ? " · " : "";
  return `${docName}${separator}${finalText}`;
};

export const hasPendingValidationParticipantIdentityFile = (
  document?: Document,
): boolean => {
  return (
    (document?.status === DocumentStatus.PENDING_VALIDATION ||
      document?.status === DocumentStatus.CORRECTION_REQUESTED) &&
    !document.manualUploaded
  );
};

export const showExpirationDate = (
  document?: Document,
  participant?: Participant,
): boolean =>
  document?.status === DocumentStatus.PENDING_VALIDATION &&
  !!participant?.identityFile &&
  !document?.manualUploaded;

export const isDigitallySignedAndValidated = (document?: Document): boolean =>
  document?.status === DocumentStatus.VALIDATED && !document.manualUploaded;

export const getFileExtension = (url?: string): DocumentFormat => {
  if (!url) return DocumentFormat.EMPTY;
  const { query } = queryString.parseUrl(url);
  const extension = query.ext as string;

  if (extension === DocumentFormat.PDF) return DocumentFormat.PDF;
  if (extension === DocumentFormat.IMG) return DocumentFormat.IMG;
  return DocumentFormat.EMPTY;
};

export const getSignPageDescription = (data?: DocumentData | null): string => {
  if (!data || !data.document) return "";
  const docName = data.document.type ? DocumentNames[data.document.type] : "";
  let finalText = "";
  if (data.document.type === DocumentType.DJP)
    finalText = data.djpData?.course.name ?? "";
  if (data.document.type === DocumentType.DJO)
    finalText = data.djoData?.course.name ?? "";
  if (data.document.type === DocumentType.PRECONTRACT)
    finalText = data.precontractData?.company.name ?? "";
  const separator = !!docName && !!finalText ? " · " : "";
  return `${docName}${separator}${finalText}`;
};

export const getSignPageSignerData = (
  data: DocumentData | null,
  user: CurrentUser | null,
): { name: string; email: string } | null => {
  if (!data || !data.document) return null;

  // OTEC/Company Representative: Signer is logged user
  if (data.document.type === DocumentType.DJO) {
    if (!user) return null;
    if (user) return { name: user.name, email: user.email };
  }
  // DJP: Signer is participant
  if (data.document.type === DocumentType.DJP) {
    if (data.djpData)
      return {
        name: data.djpData.firstSigner.name,
        email: data.djpData.firstSigner.email,
      };
  }
  // Precontract: Signer is participant
  if (data.document.type === DocumentType.PRECONTRACT) {
    if (data.precontractData)
      return {
        name: data.precontractData.participant.name,
        email: data.precontractData.participant.email,
      };
  }
  return null;
};

export const getSuccessfulSignaturePageBodyText = (
  data: DocumentData | null,
): string => {
  if (
    !data ||
    !data.document?.signatureEmail ||
    data?.document?.type === DocumentType.DJO
  )
    return "";
  return "Se ha enviado una copia a";
};

export const getSignPageSubTitle = (documentType?: DocumentType) => {
  switch (documentType) {
    case DocumentType.PRECONTRACT:
      return "Valida los datos y firma el Precontrato";

    case DocumentType.DJO:
      return "Valida los datos y firma la Declaración Jurada";

    case DocumentType.DJP:
      return "Valida los datos y firma la Declaración Jurada";

    default:
      return "";
  }
};

export const getDocumentIconButtonData = (
  status: DocumentStatus,
  documentActions: AvailableDocumentActions,
): Pick<CellIconData, "icon" | "tooltipLabel"> | null => {
  const {
    canValidate,
    canManualUpload,
    canView,
    canForceValidation,
    canViewForcedValidation,
  } = documentActions;
  // Validate
  if (canValidate) {
    return {
      icon: <PlagiarismOutlined />,
      tooltipLabel: "Validar documento",
    };
  }
  // Manual upload
  if (canManualUpload) {
    return {
      icon: <UploadFileOutlined />,
      tooltipLabel:
        status === DocumentStatus.PENDING
          ? "Subir documento"
          : "Corregir documento",
    };
  }
  // View document
  if (canView) {
    return {
      icon: <InsertDriveFileOutlined />,
      tooltipLabel: "Ver documento",
    };
  }
  // Force validation
  if (canForceValidation) {
    return {
      icon: <AddTaskOutlined />,
      tooltipLabel: "Validar forzadamente",
    };
  }
  // View forced validation comment
  if (canViewForcedValidation) {
    return {
      icon: <CommentOutlined />,
      tooltipLabel: "Ver comentario",
    };
  }

  return null;
};

export const doesTableFitInFirstPage = (
  data: Row[],
  breakpoints: TableBreakpoints,
) => data.length <= breakpoints.firstPageWithBottomText;

/**
 * @param data: array of rows shown in document
 * @param breakpoints: amount of rows that fit in each page type
 * @returns: If all rows fit in first page (considering bottom's page text), returns {firstPage: Row[]}
 * If all rows don't fit in first page, returns rows to show in first page (firstPage: Row[])
 * and an array with rows to show in each page (remaining: Row[][])
 */
export const divideDataInTableBreakpoints = (
  data: Row[],
  breakpoints: TableBreakpoints,
): { firstPage: Row[]; remaining?: Row[][] } => {
  if (doesTableFitInFirstPage(data, breakpoints)) {
    return {
      firstPage: data,
    };
  }
  const clonedData = clone(data);
  const firstPage = clonedData.splice(0, breakpoints.firstPage);
  const remaining = [];
  while (clonedData.length) {
    remaining.push(clonedData.splice(0, breakpoints.fullPage));
  }
  return {
    firstPage,
    remaining,
  };
};

export const getMassSignPageTitle = (
  data?: PrecontractDocumentData | null,
): string => {
  if (!data) return "";
  return `Firma precontrato ${data.courses?.length > 1 ? "modular" : ""} ‧ ${
    data.courses?.length > 1
      ? "Múltiples cursos"
      : "N° Inscripción " + data.courses[0].registrationNumber
  }`;
};

export const getMassSignDialogInfo = (
  data?: PrecontractDocumentData | null,
): DialogInfoItem[] => {
  if (!data) return [];
  return [
    {
      title: "Curso",
      subtitle: data.courses?.length > 1 ? "Múltiples" : data.courses[0].name,
      sm: 4,
    },
    {
      title: "Sencenet",
      subtitle:
        data.courses?.length > 1
          ? "Múltiples"
          : data.courses[0]?.sencenet ?? "",
    },
    {
      title: "Código Sence",
      subtitle:
        data.courses?.length > 1 ? "Múltiples" : data.courses[0].senceCode,
    },
    {
      title: "Inicio y término",
      subtitle: `${data.processStartDate} - ${data.processFinishDate}`,
    },
    {
      title: "Participantes activos",
      subtitle: String(data.courses[0].participantsNumber),
    },
  ];
};

export const formattedPrecontractDates = (
  startDate: Date,
  endDate: Date,
): string => `${slashFormatDate(startDate)} al ${slashFormatDate(endDate)}`;

import { useCallback, useMemo, useContext } from "react";
import {
  Accordion,
  Avatar,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
  Chip,
  Box,
  IconButton,
  useTheme,
  GridProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import {
  useStyles,
  useAvailableDocumentActions,
  useCanInteractWithDJO,
} from "hooks";
import {
  Course,
  DocumentStatusNames,
  CSSGenerator,
  ActionTypeNames,
  AppRoutes,
  DocumentType,
  EmailSubject,
  CustomReminderType,
  EmailForm,
  DocumentStatus,
} from "interfaces";
import { getTableChipStyles } from "styles";
import {
  TableChipCellStyle,
  TableChipCellType as ChipType,
} from "./Table/interfaces";
import { useHistory } from "react-router-dom";
import {
  DocumentDialogContext,
  FeedbackContext,
  AppContext,
  MessageContext,
} from "contexts";
import {
  formatCourseRangeDates,
  parseMultipleEmails,
  slashFormatDate,
} from "utils";
import {
  sendCustomReminders,
  uploadManualDocument,
  forceDocumentValidation,
} from "api";

interface AccordionInfoProp {
  course: Course;
  documentType: DocumentType;
  route: AppRoutes;
}

export const AccordionInfo = ({
  course,
  documentType,
  route,
}: AccordionInfoProp) => {
  const styles = useStyles(generateStyles);
  const theme = useTheme();
  const history = useHistory();
  const {
    openValidationDialog,
    closeValidationDialog,
    openUploadDialog,
    closeUploadDialog,
  } = useContext(DocumentDialogContext);
  const { openMessageDialog, openInformationDialog } =
    useContext(MessageContext);
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const { user } = useContext(AppContext);

  const getDocumentActions = useAvailableDocumentActions();
  const canInteractDJO = useCanInteractWithDJO(course.djo);

  const isDJ = documentType !== DocumentType.PRECONTRACT;

  const parsedDates = useMemo(() => formatCourseRangeDates(course), [course]);

  const isSignedDJOSignerUserIdentityFileMissing = useMemo<boolean>(() => {
    return (
      [
        DocumentStatus.CORRECTION_REQUESTED,
        DocumentStatus.PENDING_VALIDATION,
      ].includes(course.djo?.status) && !course.djo?.otecSigner?.identityFile
    );
  }, [course]);

  const djoStatusName = useMemo(() => {
    const status = course.djo?.otecSigner?.identityFile
      ? course.djo?.status
      : DocumentStatus.PENDING;
    return DocumentStatusNames[status];
  }, [course]);

  const dialogInfoData = useMemo(
    () => ({
      courseName: course.name ?? "",
      sencenet: course.sencenet ?? "",
      sence: course.senceCode ?? "",
      dates: parsedDates,
      participantsNumber: course.participantsNumber?.toString() ?? "",
    }),
    [course, parsedDates],
  );

  const onForceDocumentValidationFinish = useCallback(
    async (id: number, emailData: EmailForm) => {
      if (!course.djo) return;
      toggleLoader(true, `[AccordionInfo] Forcing document validation`);
      const forcedValidationSuccesful = await forceDocumentValidation(
        {
          documentId: id,
          comment: emailData?.message,
        },
        { sencenet: course.sencenet, documentType },
        documentType,
      );
      toggleLoader(false, `[AccordionInfo] Forcing document validation`);
      if (!forcedValidationSuccesful) {
        showSnackbar("Algo falló al realizar la validación forzada", "error");
        return;
      }
      showSnackbar("Validación forzada realizada exitosamente", "success");
    },
    [course, documentType, showSnackbar, toggleLoader],
  );

  const manuallyUploadFile = useCallback(
    async (file: File, callback: () => void) => {
      if (!course.djo || !course) return;
      toggleLoader(true, "[DetailsPage] Uploading file");
      // Send file
      const wasUploaded = await uploadManualDocument(
        {
          documentId: course.djo.id,
          file,
        },
        {
          sencenet: course.sencenet ?? "",
          documentType: DocumentType.DJO,
        },
        DocumentType.DJO,
      );
      if (!wasUploaded) {
        toggleLoader(false, "[DetailsPage] Uploading file");
        showSnackbar("Algo falló al subir el documento", "error");
        return;
      }
      toggleLoader(false, "[DetailsPage] Uploading file");
      showSnackbar("Se ha subido el documento con éxito", "success");
      callback();
    },
    [course, toggleLoader, showSnackbar],
  );

  const onDJOChipClick = useCallback(() => {
    if (!user || !course.djo || !course.djo?.status) return;
    // Calculate DJO permissions
    const {
      canSignDJO,
      canUploadIdentityFileDJO,
      canRequestCorrectionDJO,
      canValidateDJO,
      canViewDJO,
      canUploadDJO,
      canForceValidationDJO,
      canViewForcedValidationDJO,
    } = getDocumentActions(course.djo);
    if (course.djo?.uuid && canSignDJO) {
      history.push(AppRoutes.SIGN_DOCUMENT.replace(":uuid", course.djo?.uuid));
      return;
    }

    // Only same user that signed can upload identity file
    if (
      user.rut === course.djo?.otecSigner?.rut &&
      !user.identityFile &&
      !!course.djo?.uuid &&
      canUploadIdentityFileDJO
    ) {
      history.push(
        AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(":uuid", course.djo?.uuid),
        {
          refetchCourseDetailsQueryParams: {
            sencenet: course.sencenet ?? "",
            documentType: DocumentType.DJP,
          },
        },
      );
      return;
    }

    if (canUploadDJO) {
      openUploadDialog({
        common: {
          onFinish: (file, callback) => manuallyUploadFile(file, callback),
          onClose: closeUploadDialog,
          title: `Cargar DJO ‧ Nº inscripción ${course.registrationNumber}`,
        },
        courseData: dialogInfoData,
        documentExists: !!course.djo.url,
        stillOpenAt: route,
      });
      return;
    }

    // Validate/visualize document
    if (canViewDJO || canRequestCorrectionDJO || canValidateDJO) {
      openValidationDialog({
        common: {
          onClose: closeValidationDialog,
          title: `${
            course.djo?.status !== DocumentStatus.PENDING ? "Validar" : ""
          } DJO ‧ Nº inscripción ${course.registrationNumber}`,
        },
        headerCourseData: dialogInfoData,
        course,
        sencenet: course.sencenet ?? "",
        stillOpenAt: route,
        document: course.djo,
        actionable: canRequestCorrectionDJO || canValidateDJO,
      });
    }
    if (canForceValidationDJO) {
      openMessageDialog({
        title: `Validación forzada DJO`,
        onlyCommentMode: true,
        stillOpenAt: route,
        onFinish: (emailData: EmailForm) =>
          onForceDocumentValidationFinish(course.djo.id, emailData),
      });
    }
    if (canViewForcedValidationDJO) {
      openInformationDialog({
        title: "DJO validada forzadamente",
        text:
          course.djo?.forcedValidationComment ??
          "No hay comentarios relacionados a la validación forzada",
        stillOpenAt: route,
      });
    }
  }, [
    user,
    getDocumentActions,
    history,
    openUploadDialog,
    closeUploadDialog,
    course,
    dialogInfoData,
    route,
    openMessageDialog,
    openInformationDialog,
    manuallyUploadFile,
    openValidationDialog,
    closeValidationDialog,
    onForceDocumentValidationFinish,
  ]);

  const chipDjoStatusStyle = useMemo(() => {
    if (course.djo?.status) {
      return getTableChipStyles(
        theme,
        ChipType["DOCUMENT_STATUS"],
        course.djo.status,
        TableChipCellStyle.PRIMARY,
        true,
      );
    }
  }, [course.djo?.status, theme]);

  const chipCriticalDaysStyle = useMemo(() => {
    if (course.precontractCriticalyLevel) {
      return getTableChipStyles(
        theme,
        ChipType["DAYS"],
        course.precontractCriticalyLevel,
        TableChipCellStyle.PRIMARY,
      );
    }
    return getTableChipStyles(
      theme,
      ChipType["DAYS"],
      course.djCriticalyLevel,
      TableChipCellStyle.PRIMARY,
    );
  }, [course, theme]);

  // DJO Reminder
  const canSendReminderDJO = useMemo(
    () => getDocumentActions(course.djo).canSendReminderDJO,
    [course, getDocumentActions],
  );

  const onReminderEditionFinished = useCallback(
    async (emailData: EmailForm) => {
      toggleLoader(true, "[SwornStatementDetailsPage] Sending OTEC reminder");
      const emails = parseMultipleEmails(emailData.email);

      const individualReminderSuccesful = await sendCustomReminders(
        {
          emailsData: [
            {
              message: emailData.message,
              emails,
              subject: emailData.subjectOption ?? EmailSubject.REQUEST_SIGN,
              documentId: course.djo?.id,
            },
          ],
          documentType: DocumentType.DJO,
        },
        { sencenet: course.sencenet, documentType: DocumentType.DJO },
      );
      toggleLoader(false, "[SwornStatementDetailsPage] Sending OTEC reminder");
      if (!individualReminderSuccesful) {
        showSnackbar("Algo falló al enviar recordatorio individual", "error");
        return;
      }
      showSnackbar("Recordatorio individual enviado exitosamente", "success");
    },
    [course, showSnackbar, toggleLoader],
  );

  const onHistorialClick = useCallback(() => {
    if (!course.djo?.id) {
      showSnackbar("El curso no tiene una DJO", "error");
      return;
    }
    history.push(`${history.location.pathname}/historial/${course.djo.id}`);
  }, [history, course, showSnackbar]);

  const onDJOReminderClick = useCallback(() => {
    openMessageDialog({
      title: "Enviar recordatorio DJO",
      email: course.otec.email,
      stillOpenAt: route,
      subjectOptions: [EmailSubject.REQUEST_SIGN],
      onFinish: onReminderEditionFinished,
      emailDefaultData: {
        type: CustomReminderType.SIGN_DJO,
        data: {
          companyName: course.company.name,
          courseName: course.name,
          registrationNumber: course.registrationNumber,
          senderName: user?.name ?? "",
          senderPosition: user?.role ?? "",
        },
      },
    });
  }, [course, user, openMessageDialog, onReminderEditionFinished, route]);

  const companyLRIdentityFileUrl =
    course.companyLegalRepresentativeSigner?.identityFile?.url ?? null;

  const openCompanyLRIdentityFile = useCallback(() => {
    if (!companyLRIdentityFileUrl) return;
    window.open(companyLRIdentityFileUrl, "_blank");
  }, [companyLRIdentityFileUrl]);

  return (
    <Accordion defaultExpanded sx={styles.accordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant={"h6"} sx={styles.heading}>
          Detalle del curso
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <Paper variant="outlined">
          <Grid container>
            {course && (
              <>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.bookAvatar}>
                      <BookOutlinedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <Grid sx={styles.infoGrid} item xs={10} sm={10} md={10}>
                    <Typography
                      variant={"caption"}
                      display={"block"}
                      sx={styles.itemHeader}
                    >
                      {"Curso"}
                    </Typography>
                    <Typography variant={"caption"}>{course.name}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="Sencenet"
                    itemValue={course.sencenet}
                  />
                  <AccordionGridItem
                    itemHeader="Código Sence"
                    itemValue={course.senceCode ?? "-"}
                  />
                  <>
                    {isDJ ? (
                      <>
                        <AccordionGridItem
                          itemHeader="Tipo de acción"
                          itemValue={ActionTypeNames[course.actionType] ?? "-"}
                        />
                        <AccordionGridItem
                          itemHeader="Modalidad"
                          itemValue={"A distancia" /*course.modality ?? "-"*/}
                        />
                      </>
                    ) : (
                      <AccordionGridItem
                        itemHeader="Tipo de contrato"
                        itemValue={course.contractType ?? "-"}
                      />
                    )}
                  </>
                  <AccordionGridItem
                    itemHeader="Inicio y término"
                    itemValue={parsedDates}
                  />
                  <>
                    {course.active ? (
                      isDJ ? (
                        <>
                          <Grid sx={styles.infoGrid} item xs={3} sm={3} md={3}>
                            <Typography
                              variant={"caption"}
                              display={"block"}
                              sx={styles.itemHeader}
                            >
                              Días de plazo
                            </Typography>
                            <Chip
                              label={course.djDaysLeft ?? 0}
                              sx={chipCriticalDaysStyle}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid sx={styles.infoGrid} item xs={3} sm={3} md={3}>
                            <Typography
                              variant={"caption"}
                              display={"block"}
                              sx={styles.itemHeader}
                            >
                              Días de plazo
                            </Typography>
                            <Chip
                              label={course.precontractDaysLeft ?? 0}
                              sx={chipCriticalDaysStyle}
                            />
                          </Grid>
                        </>
                      )
                    ) : (
                      <AccordionGridItem
                        itemHeader="Cierre de proceso"
                        itemValue={
                          slashFormatDate(course.finishManagementDate) ?? "-"
                        }
                      />
                    )}
                  </>
                  <AccordionGridItem
                    itemHeader="Preinscripción"
                    itemValue={course.preinscriptionUserName ?? "-"}
                  />
                  <AccordionGridItem
                    itemHeader="Célula"
                    itemValue={course.cell ?? "-"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.businessAvatar}>
                      <BusinessRoundedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <AccordionGridItem
                    itemHeader="Empresa"
                    itemValue={course.company?.name ?? "-"}
                    xs={10}
                    sm={10}
                    md={10}
                  />
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="RUT"
                    itemValue={course.company?.rut ?? "-"}
                  />
                  {!isDJ && (
                    <AccordionGridItem
                      itemHeader="Nombre Rep. Legal"
                      itemValue={
                        course.companyLegalRepresentativeSigner?.name ?? "-"
                      }
                    />
                  )}
                  {!isDJ &&
                    (!!companyLRIdentityFileUrl ? (
                      <AccordionGridItem>
                        <Button
                          sx={styles.buttonIdentityFile}
                          variant="outlined"
                          onClick={openCompanyLRIdentityFile}
                        >
                          Ver C.I. Rep. Legal
                        </Button>
                      </AccordionGridItem>
                    ) : (
                      <AccordionGridItem
                        itemHeader="C.I. Rep. Legal"
                        itemValue="No disponible"
                      />
                    ))}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Divider />
                </Grid>
                <Grid
                  container
                  item
                  sx={styles.grid}
                  spacing={2}
                  xs={4}
                  sm={4}
                  md={4}
                >
                  <Grid item xs={2} sm={2} md={2}>
                    <Avatar sx={styles.schoolAvatar}>
                      <SchoolOutlinedIcon sx={styles.bookIcon} />
                    </Avatar>
                  </Grid>
                  <AccordionGridItem
                    itemHeader="Otec"
                    itemValue={course.otec?.name ?? "-"}
                    xs={10}
                    sm={10}
                    md={10}
                  />
                </Grid>
                <Grid
                  container
                  item
                  spacing={2}
                  sx={styles.grid}
                  xs={8}
                  sm={8}
                  md={8}
                >
                  <AccordionGridItem
                    itemHeader="RUT"
                    itemValue={course.otec?.rut ?? "-"}
                    xs={isDJ ? 3 : 12}
                    sm={isDJ ? 3 : 12}
                    md={isDJ ? 3 : 12}
                  />
                  {isDJ && (
                    <>
                      {/* <AccordionGridItem
                        itemHeader="Emisión DJO"
                        itemValue={slashFormatDate(course.djo?.emissionDate)}
                      /> */}
                      <Grid sx={styles.infoGrid} item xs={6} sm={6} md={6}>
                        <Typography
                          variant={"caption"}
                          display={"block"}
                          sx={styles.itemHeader}
                        >
                          Estado DJO
                          {isSignedDJOSignerUserIdentityFileMissing ? (
                            <strong> (Falta C.I.)</strong>
                          ) : null}
                        </Typography>
                        <Box>
                          <Chip
                            label={djoStatusName}
                            sx={chipDjoStatusStyle}
                            onClick={
                              canInteractDJO ? onDJOChipClick : undefined
                            }
                          />
                          {canSendReminderDJO && (
                            <IconButton
                              onClick={onDJOReminderClick}
                              sx={styles.djoEmailButton}
                              aria-label="email"
                            >
                              <MailOutlineRoundedIcon sx={styles.tableIcons} />
                            </IconButton>
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </AccordionDetails>
      {isDJ && (
        <AccordionActions>
          <Button
            sx={styles.button}
            variant="outlined"
            onClick={onHistorialClick}
          >
            Ver Historial DJO
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
};

const AccordionGridItem: React.FC<
  GridProps & {
    itemHeader?: string;
    itemValue?: string;
    xs?: number;
    sm?: number;
    md?: number;
  }
> = ({ itemHeader, itemValue, xs, sm, md, children }) => {
  const styles = useStyles(generateStyles);
  return (
    <Grid sx={styles.infoGrid} item xs={xs ?? 3} sm={sm ?? 3} md={md ?? 3}>
      {!!itemHeader && (
        <Typography
          variant={"caption"}
          display={"block"}
          sx={styles.itemHeader}
        >
          {itemHeader}
        </Typography>
      )}
      {children ??
        (!!itemValue && (
          <Typography variant={"caption"}>{itemValue}</Typography>
        ))}
    </Grid>
  );
};

const generateStyles: CSSGenerator = (theme: Theme) => ({
  root: {
    width: "100%",
  },
  accordion: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  heading: {},
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  bookIcon: {},
  bookAvatar: {
    margin: "auto",
    backgroundColor: "#e9f5fe",
    color: "#2196f3",
  },
  businessAvatar: {
    margin: "auto",
    backgroundColor: "rgba(254, 165, 37, 0.08)",
    color: theme.palette.secondary.main,
  },
  schoolAvatar: {
    margin: "auto",
    backgroundColor: "#edf6ed",
    color: theme.palette.success.main,
  },
  iconGrid: {
    minHeight: 71,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  infoGrid: {
    minHeight: 71,
    marginBottom: "14px",
  },
  itemHeader: {
    color: theme.palette.text.disabled,
  },
  grid: {
    marginTop: 0,
  },
  filterDiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
    alignItems: "center",
  },
  stack: {
    alignItems: "center",
    height: "fit-content",
  },
  button: {
    borderRadius: 18,
    height: 36,
  },
  buttonIdentityFile: {
    borderRadius: 18,
    height: 36,
    marginTop: "7px",
  },
  searchInput: {
    width: "24vw",
    height: 48,
    justifyContent: "center",
    "& :before": {
      border: "none",
    },
    "& :after": {
      borderBottom: "unset",
    },
    "& :hover": {
      borderBottom: "unset",
    },
    "& :hover:not(.Mui-disabled):before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:before": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:after": {
      borderBottom: "unset",
    },
    "&>.MuiInputBase-root:hover": {
      borderBottom: "unset",
    },
  },
  searchBox: {
    backgroundColor: "white",
    border: "0.5px solid",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    padding: "0px 16px",
    borderColor: "#9e9e9e",
  },
  participantsCount: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  djoEmailButton: {
    marginLeft: "10px",
  },
  tableIcons: {
    color: theme.palette.common.black,
  },
});

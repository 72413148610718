import { Box, TypographyProps } from "@mui/material";
import { DocumentParagraph } from "./DocumentTexts";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";
// import { getResponsiveSize } from "utils";

type ListProps = {
  sx?: TypographyProps["sx"];
  texts: React.ReactNode[];
  type?: "1" | "A";
  startAt?: number;
};

const alphabet = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";

const getPositionedChar = (
  type: ListProps["type"],
  startAt: number,
  currentPos: number,
): string => {
  if (type === "A") {
    return alphabet[startAt + currentPos] + ". ";
  } else if (type === "1") {
    return String(1 + startAt + currentPos) + ". ";
  }
  return "";
};

/* DOCUMENT PARAGRAPHS LIST (max 1 alphabet loop) */
export const DocumentOrderedList: React.FC<ListProps> = ({
  sx,
  texts,
  type = "1",
  startAt = 0,
}) => {
  const styles = useStyles(generateListStyles);
  return (
    <Box sx={{ ...styles.root, ...sx }}>
      {texts.map((text, idx) => (
        <DocumentParagraph
          key={idx}
          sx={styles.paragraph}
          prefix={getPositionedChar(type, startAt, idx)}
          text={text}
        ></DocumentParagraph>
      ))}
    </Box>
  );
};

const generateListStyles: CSSGenerator = () => ({
  root: {
    // textAlign: "left",
    // 19px in 1440px vw
    // fontSize: getResponsiveSize(19),
  },
  paragraph: {
    marginBottom: "10px",
    textAlign: "justify",
    textJustify: "inter-word",
  },
});

import { DocumentNode, gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";
import { Course, DocumentType } from "interfaces";

export const GET_COURSES_SUMMARY_QUERY = (
  documentType: DocumentType,
): DocumentNode => gql`
  query GetCoursesSummary($params: GetCoursesSummaryParams!) {
    getCoursesSummary(params: $params) {
      id
      registrationNumber
      sencenet
      name
      participantsNumber
      company {
        id
        rut
        name
      }
      otec {
        id
        rut
        name
      }
      precontractCriticalyLevel
      precontractDaysLeft
      djCriticalyLevel
      djDaysLeft
      processEndDate(type: ${documentType})
      finishManagementDate
      cell
      documentsRemaining
      documentsFinished
      active
      endDate   
      someDocumentUUIDForMassSign(type: ${documentType})
      companySignatureStatus
      djo {
        id
        status
      }
      minorAuthorizationDocsPending
      minorAuthorizationDocsTotal
      vulnerabilityDocsTotal
    }
  }
`;

interface GetCoursesSummaryParams {
  documentType?: DocumentType;
}

export const useCoursesSummary = (
  params: GetCoursesSummaryParams,
): {
  courses: Course[];
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getCoursesSummary: Course[];
  }>(GET_COURSES_SUMMARY_QUERY(params.documentType ?? DocumentType.DJP), {
    variables: { params },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_COURSES_SUMMARY] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });
  return {
    courses:
      data?.getCoursesSummary?.map((c) => ({
        ...c,        
        startDate: new Date(c.startDate),
        endDate: new Date(c.endDate),
        inscriptionDate: new Date(c.inscriptionDate),
        liquidationDate: new Date(c.liquidationDate),
        finishManagementDate: c.finishManagementDate
          ? new Date(c.finishManagementDate)
          : null,
      })) ?? [],
    loading,
  };
};

export const mockedCourses: any[]= [
  {
		"id": "6257824_PRECONTRACT",
    "registrationNumber": "1447611",
    "sencenet": "6257824",
    "name": "Aplicación de Microsoft Excel Intermedio",
    "participantsNumber": 20,
    "company": {
      "id": "76.727.040-2",
      "rut": "76.727.040-2",
      "name": "Minera Centinela",
      "__typename": "Company"
    },
    "otec": {
      "id": "87.878.600-9",
      "rut": "87.878.600-9",
      "name": "Servicio de Capacitacion Indaco y Compania Limitada",
      "__typename": "Otec"
    },
    "precontractCriticalyLevel": "HIGH",
    "precontractDaysLeft": -196,
    "djCriticalyLevel": "HIGH",
    "djDaysLeft": -146,
    "processEndDate": "2022-12-20T03:00:00.000Z",
    "finishManagementDate": "2022-12-14T01:00:02.873Z",
    "cell": "Celula 10",
    "documentsRemaining": 0,
    "documentsFinished": 0,
    "active": false,
    "endDate": "2022-12-20T03:00:00.000Z",
    "someDocumentUUIDForMassSign": "cc5117b5-c2df-469f-88be-72f0901ef017",
    "companySignatureStatus": "PENDING",
    "djo": null,
    "__typename": "Course",
		"minorAuthorizationDocsPending": 6,
		"minorAuthorizationDocsTotal": 6
  },
  {
    "id": "6234622_PRECONTRACT",
    "registrationNumber": "1419710",
    "sencenet": "6234622",
    "name": "Técnicas de Administración de Bodegas",
    "participantsNumber": 29,
    "company": {
      "id": "71.102.600-2",
      "rut": "71.102.600-2",
      "name": "Corp.municipal de Des.social Antofagasta",
      "__typename": "Company"
    },
    "otec": {
      "id": "87.878.600-9",
      "rut": "87.878.600-9",
      "name": "Servicio de Capacitacion Indaco y Compania Limitada",
      "__typename": "Otec"
    },
    "precontractCriticalyLevel": "HIGH",
    "precontractDaysLeft": -237,
    "djCriticalyLevel": "HIGH",
    "djDaysLeft": -187,
    "processEndDate": "2022-11-09T03:00:00.000Z",
    "finishManagementDate": "2022-11-22T11:35:18.697Z",
    "cell": "Celula 9",
    "documentsRemaining": 0,
    "documentsFinished": 0,
    "active": false,
    "endDate": "2022-11-09T03:00:00.000Z",
    "someDocumentUUIDForMassSign": "cc5117b5-c2df-469f-88be-72f0901ef017",
    "companySignatureStatus": "PENDING",
    "djo": null,
    "__typename": "Course",
    "minorAuthorizationDocsPending": 6,
    "minorAuthorizationDocsTotal": 6,
    "vulnerabilityDocsTotal": 6
  }
];

import { gql } from "@apollo/client";
import { client, GET_COURSES_SUMMARY_QUERY } from "api";
import { DocumentType } from "interfaces";
interface MutationResult {
  signCompanyPrecontracts: boolean | null;
}

interface MutationParams {
  params: {
    documentIds: number[];
  };
}

export const signCompanyPrecontracts = async (
  documentIds: number[],
  documentType: DocumentType,
): Promise<boolean> => {
  if (documentIds.length === 0) return false;

  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SignCompanyPrecontracts(
        $params: SignCompanyPrecontractsParams!
      ) {
        signCompanyPrecontracts(params: $params)
      }
    `,
    variables: {
      params: { documentIds },
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_COURSES_SUMMARY_QUERY(documentType),
        variables: { params: { documentType } },
      },
    ],
  });

  return !!response.data?.signCompanyPrecontracts;
};

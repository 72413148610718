import { useParams, RouteComponentProps } from "react-router-dom";
import { useRestrictedPage } from "hooks";
import {
  Permission,
  AppRoutes,
  DocumentType,
} from "interfaces";
// import {
//   Order,
//   TableCellType,
//   TableChipCellStyle,
//   TableColumnData,
// } from "components";
// import { compareDocumentStatuses } from "utils";
import { ElearningDetailsPage } from "./ElearningDetailsPage";

export const SwornStatementElearningDetailsPage: React.FC<RouteComponentProps> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_DJS_ELEARNING, Permission.CAN_VIEW_COURSE],
    redirectTo: AppRoutes.DASHBOARD,
  });
  let { sencenet } = useParams<{ sencenet: string }>();
  return (
    <ElearningDetailsPage
      documentType={DocumentType.DJP}
      sencenet={sencenet}
      route={AppRoutes.SWORN_STATEMENT_ELEARNING_DETAILS}
    />
  );
};

// const columnDefs: TableColumnData[] = [
//   {
//     cellType: TableCellType.TEXT,
//     headerName: "Nombre participante",
//     attr: "name",
//     sortable: true,
//   },
//   {
//     cellType: TableCellType.TEXT,
//     headerName: "Rut",
//     attr: "rut",
//     sortable: true,
//   },
//   {
//     cellType: TableCellType.TEXT,
//     headerName: "Correo electrónico",
//     attr: "email",
//     sortable: true,
//   },
//   {
//     cellType: TableCellType.TEXT,
//     headerName: "Teléfono",
//     attr: "phone",
//     sortable: true,
//   },
//   {
//     cellType: TableCellType.CHIP,
//     headerName: "Firma DJP",
//     attr: "mainSignerStatus",
//     sortable: true,
//     onClick: undefined, // injected on component
//     chipStyleType: TableChipCellStyle.PRIMARY,
//     sortedByDefault: true,
//     sortFn: (a: any, b: any, order: Order) => {
//       const statusA = a["mainSignerStatus"][2] as DocumentStatus;
//       const statusB = b["mainSignerStatus"][2] as DocumentStatus;
//       return compareDocumentStatuses(statusA, statusB, order);
//     },
//   },
//   {
//     cellType: TableCellType.TEXT,
//     headerName: "Útimo recordatorio",
//     attr: "lastReminder",
//     sortable: true,
//   },
//   {
//     cellType: TableCellType.ICON_BUTTON,
//     headerName: "",
//     attr: "sendReminder",
//     sortable: false,
//     onClick: undefined, // injected on component
//     getIconData: undefined, // injected on component
//     style: {},
//     align: "center",
//     permissions: [
//       Permission.CAN_SEND_PARTICIPANT_MAIL,
//       Permission.CAN_SEND_RESPONSABLE_MAIL,
//     ],
//   },
//   // {
//   //   cellType: TableCellType.ICON_BUTTON,
//   //   headerName: "",
//   //   attr: "whatsApp",
//   //   sortable: false,
//   //   onClick: undefined, // injected on component
//   //   iconData: { icon: <WhatsApp />, tooltipLabel: "Enviar recordatorio" },
//   //   style: {},
//   //   align: "center",
//   // },
//   {
//     cellType: TableCellType.ICON_BUTTON,
//     headerName: "",
//     attr: "document",
//     sortable: false,
//     onClick: undefined, // injected on component
//     getIconData: undefined, // injected on component
//     style: {},
//     align: "center",
//     permissions: [
//       Permission.CAN_UPLOAD_PARTICIPANT_FILE,
//       Permission.CAN_VIEW_PARTICIPANT_FILE,
//       Permission.CAN_VALIDATE_FILE,
//       Permission.CAN_REQUEST_AMEND,
//     ],
//   },
// ];

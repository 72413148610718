import {
  Course,
  DocumentCompanyStatusNames,
  DocumentParticipantInfo,
  DocumentStatusNames,
  DocumentType,
} from "interfaces";
import { slashFormatDate } from "utils";

export interface ParsedCourseDetailsCSVInfo {
  name: string;
  rut: string;
  email: string;
  phone: string;
  mainSignerStatus: string;
  secondarySignerStatus?: string;
}

export interface ParsedCoursesSummaryCSVInfo {
  daysLeft: string;
  endDate: string;
  courseStatus: string;
  registrationNumber: string;
  sencenet: string;
  courseName: string;
  companyName: string;
  documentsRemaining: string;
  djoStatus?: string;
}

export const parseCourseDetailsInfoToCSV = (
  documentParticipantInfo: DocumentParticipantInfo[],
): ParsedCourseDetailsCSVInfo[] => {
  return documentParticipantInfo.map(
    ({ participant, mainSignerStatus, secondarySignerStatus }) => ({
      name: participant.name,
      rut: participant.rut,
      email: participant.email,
      phone: participant.phone,
      mainSignerStatus: DocumentStatusNames[mainSignerStatus],
      ...(secondarySignerStatus
        ? {
            secondarySignerStatus:
              DocumentCompanyStatusNames[secondarySignerStatus],
          }
        : {}),
    }),
  );
};

export const parseCoursesSummaryInfoToCSV = (
  courses: Course[],
  type: DocumentType,
): ParsedCoursesSummaryCSVInfo[] => {
  return courses.map((c) => ({
    daysLeft: c.active
      ? type === DocumentType.PRECONTRACT
        ? String(c.precontractDaysLeft)
        : String(c.djDaysLeft)
      : "",
    endDate: slashFormatDate(c.endDate),
    courseStatus: c.active ? "Activo" : "Finalizado",
    registrationNumber: c.registrationNumber,
    sencenet: c.sencenet,
    courseName: c.name,
    companyName: c.company.name,
    documentsRemaining: String(c.documentsRemaining),
    ...(c.djo?.status ? { djoStatus: c.djo.status } : {}),
  }));
};

export interface ParsedELearningCoursesSummaryCSVInfo {
  daysLeft: string;
  purchaseOrder: string;
  sencenet: string;
  courseName: string;
  companyName: string;
  documentsRemaining: string;
  cellName: string;
  courseStatus?: string;
  djoStatus?: string;
}

import { format, isToday, isAfter, isValid, isEqual } from "date-fns";
import esLocale from "date-fns/locale/es";
import { Course, ElearningCourse } from "interfaces";

export const validateExpirationDate = (date: Date | null): boolean => {
  if (!date) return false;
  return isValid(date) && isAfter(date, new Date());
};

export const validateExpirationDateValidationDialog = (
  date: Date | null,
  minDate: Date | null,
): boolean => {
  if (!date) return false;
  // console.log("minDate: ", minDate)
  // console.log("Date: ", date)
  // console.log("validacion: ", (isValid(date) && isValid(minDate)) && (isAfter(date, minDate!) || isToday(minDate!)))
  return (
    isValid(date) &&
    isValid(minDate) &&
    (isAfter(date, minDate!) || isEqual(date, minDate!))
  );
};

export const humanFormatDate = (date: Date) =>
  format(date, "dd 'de' MMMM 'de' yyyy HH:mm", {
    locale: esLocale,
  });

export const slashFormatDate = (date?: Date | null, def = "") => {
  return date ? format(date, "dd'/'MM'/'yyyy") : def;
};

export const slashFormatDateTime = (date?: Date | null, def = "") => {
  return date ? format(date, "dd'/'MM'/'yyyy HH:mm") : def;
};

export const commentFormatDate = (date: Date): string => {
  if (isToday(date)) return format(date, "'HOY, 'HH:mm");
  return format(date, "dd MMMM', 'yyyy", { locale: esLocale }).toUpperCase();
};

export const formatCourseRangeDates = (course?: Course | null): string => {
  if (course?.startDate && course?.endDate) {
    const begin = format(course.startDate, "dd/MM/yyyy");
    const end = format(course.endDate, "dd/MM/yyyy");
    return `${begin} - ${end}`;
  }
  return "-";
};

export const formatElearningCourseRangeDates = (course?: ElearningCourse | null): string => {
  if (course?.startDate && course?.endDate) {
    const begin = format(course.startDate, "dd/MM/yyyy");
    const end = format(course.endDate, "dd/MM/yyyy");
    return `${begin} - ${end}`;
  }
  return "-";
};

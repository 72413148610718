import { gql } from "@apollo/client";

export const DJPDocumentDataFragment = gql`
  fragment DJPDocumentDataFields on DJPDocumentData {
    signDate
    firstSigner {
      id
      rut
      name
      email
      address
      commune
      city
      region
      identityFile {
        id
        status
        expirationDate
      }
    }
    otec {
      id
      rut
      name
    }
    course {
      id
      name
      senceCode
      sencenet
      startDate
      endDate
    }
  }
`;

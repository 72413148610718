import {
  CriticalityLevel,
  DocumentStatus,
  ElearningCourseStatus,
  ParticipantDjpStatus,
} from "interfaces";
import { TableChipCellType as ChipType } from "components";
import { DocumentCompanyStatus } from "./document";

interface ActiveCourseData {
  daysLeft: [number, ChipType, CriticalityLevel];
  registrationNumber: string;
  sencenet: string;
  name: string;
  companyData: string;
  otecData: string;
  company: string;
  lastReminder: string;
  id: number;
  cell: string;
}

export type ActivePrecontractCourseData = ActiveCourseData & {
  remainingDocuments: [string, ChipType, CriticalityLevel];
  companySignatureStatus: DocumentCompanyStatus;
  someDocumentUUIDForMassSign: string;
  minorAuthorizationDocs: [string, ChipType, CriticalityLevel];
  vulnerability: [string, ChipType, CriticalityLevel];
};

export type ActiveDjCourseData = ActiveCourseData & {
  remainingDjps: [string, ChipType, CriticalityLevel];
  djoStatus: [string, ChipType, DocumentStatus];
};

interface FinishedCourseData {
  registrationNumber: string;
  endDate: string;
  sencenet: string;
  name: string;
  companyData: string;
  otecData: string;
  company: string;
  cell: string;
}

export type FinishedPrecontractCourseData = FinishedCourseData & {
  finishedDocuments: [string, ChipType, CriticalityLevel];
};

export type FinishedDjCourseData = FinishedCourseData & {
  finishedDjps: [string, ChipType, CriticalityLevel];
  djoStatus: [string, ChipType, DocumentStatus];
};

export type CourseListData =
  | ActivePrecontractCourseData
  | ActiveDjCourseData
  | FinishedPrecontractCourseData
  | FinishedDjCourseData;

export type CourseListFilters = {
  registrationNumber: string[];
  sencenet: string | null;
  name: string | null;
  company: string | null;
  otec: string | null;
  criticalityLevel: Record<CriticalityLevel, boolean>;
  cell: string | null;
};

export type ElearningCourseListData =
  | ActiveElearningCourseData
  | FinishedElearningCourseData;

export type ElearningCourseListFilters = {
  purchaseOrder: string[];
  sencenet: string | null;
  name: string | null;
  company: string | null;
  otec: string | null;
  criticalityLevel: Record<CriticalityLevel, boolean>;
  cell: string | null;
};

export enum CourseListTabs {
  ACTIVE = "PENDIENTES",
  FINISHED = "CERRADOS",
}

export interface DocumentParticipantInfoData {
  onRowClick: () => void;
  id: number;
  documentUrl: string;
  name: string;
  rut: string;
  email: string;
  phone: string;
  mainSignerStatus: string[];
  secondarySignerStatus?: string[];
  minorAuthorizationStatus?: string[];
  vulnerabilityStatus?: string[];
  lastReminder: string;
}

export interface ActiveElearningCourseData {
  id: number;
  daysLeft: [number, ChipType, CriticalityLevel];
  criticalityLevel?: CriticalityLevel;
  purchaseOrder: string;
  sencenet: string;
  name: string;
  companyData: string;
  otecData: string;
  cell: string;
  remainingDjps: [string, ChipType, CriticalityLevel];
  djoStatus: [string, ChipType, ElearningCourseStatus];
  haveDocument: boolean;
}

export interface FinishedElearningCourseData {
  id: number;
  endDate: string;
  purchaseOrder: string;
  sencenet: string;
  name: string;
  companyData: string;
  otecData: string;
  cell: string;
  remainingDjps: [string, ChipType, CriticalityLevel];
  djoStatus: [string, ChipType, ElearningCourseStatus];
  haveDocument: boolean;
}

export interface ElearningParticipantData {
  rut: string,
  nombre: string,
  apellidoPaterno: string,
  apellidoMaterno: string,
  estadoDjp: [string, ChipType, ParticipantDjpStatus],
}

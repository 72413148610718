import {
  Company,
  CriticalityLevel,
  DocumentStatus,
  Otec,
  Participant,
  Document,
  DocumentCompanyStatus,
} from "interfaces";

export interface Course {
  registrationNumber: string;
  inscriptionDate: Date;
  sencenet: string;
  senceCode: string;
  name: string;
  active: boolean;
  modality: string;
  location: string;
  contractType: string;
  actionType: ActionType;
  courseType: string;
  startDate: Date;
  endDate: Date;
  duration: number; // in days
  liquidationDate: Date;
  preinscriptionUserName: string;
  standardHours: number;
  additionalHours: number;
  schedule: string;
  participants: Participant[];
  participantsNumber: number;
  company: Company;
  companyLegalRepresentativeSigner: Participant;
  otec: Otec;
  precontractCriticalyLevel: CriticalityLevel;
  precontractDaysLeft: number;
  djCriticalyLevel: CriticalityLevel;
  djDaysLeft: number;
  processEndDate: Date;
  finishManagementDate: Date | null;
  lastReminder: number;
  documentsRemaining: number;
  documentsFinished: number;
  djo: Document;
  documentParticipantInfo: DocumentParticipantInfo[];
  someDocumentUUIDForMassSign: string;
  companySignatureStatus: DocumentCompanyStatus;
  cell: string;
  functions?: string;
  content?: string;
  functionshours?: number;
  adressSucCompany?: string;
  minorAuthorizationDocsPending: number;
  minorAuthorizationDocsTotal: number;
  vulnerabilityDocsTotal: number;
}

export interface DocumentParticipantInfo {
  id: number; // Document id
  document: Document;
  documentVulnerability: Document;
  documentMinorAuth: Document;
  participant: Participant;
  mainSignerStatus: DocumentStatus;
  secondarySignerStatus?: DocumentCompanyStatus;
  minorAuthorizationStatus: DocumentStatus;
  minorAuthorizationId: number;
  vulnerabilityStatus: DocumentStatus;
  vulnerabilityId: number;
  lastReminder: string;
  forcedValidationComment: string | null;
  lastCorrectionRequestComment: string;
}

export enum ActionType {
  UNIQUE = "UNIQUE",
  PARTIAL = "PARTIAL",
  COMPLEMENTARY = "COMPLEMENTARY",
}

export const ActionTypeNames: Record<ActionType, string> = {
  [ActionType.UNIQUE]: "Único",
  [ActionType.PARTIAL]: "Parcial",
  [ActionType.COMPLEMENTARY]: "Complementario",
};

import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { debounce } from "lodash";
import { CustomButton } from "components";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";

type Props = {
  signerName: string;
  open: boolean;
  onClose: () => void;
  onSignClick: () => void;
};

const DEBOUNCE_TIME = 800;

const removeDiacritics = (str: string): string =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const compareNames = (value: string, signerName: string): string => {
  if (!value) return "";
  if (value === signerName) return "";
  // Check if the only difference are capital letters
  if (value.toLowerCase() === signerName.toLowerCase())
    return "Tu nombre no coincide debido a las mayúsculas";
  // Check if the only difference are accents
  if (removeDiacritics(value) === removeDiacritics(signerName))
    return "Tu nombre no coincide debido a los tildes";
  // Check if the difference are capital letters and diacritics
  if (
    removeDiacritics(value).toLowerCase() ===
    removeDiacritics(signerName).toLowerCase()
  )
    return "Tu nombre no coincide debido a las mayúsculas y tildes";
  if (value !== signerName)
    return "El nombre ingresado no coincide con el de la firma";
  return "";
};

export const SignDialog: React.FC<Props> = ({
  signerName,
  open,
  onClose,
  onSignClick,
}) => {
  const styles = useStyles(generateStyles);
  const [inputName, setInputName] = useState<string>("");
  const [compareErrorMessage, setCompareErrorMessage] = useState<string>("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidateFormat = useCallback(
    debounce((value: string) => {
      setCompareErrorMessage(compareNames(value, signerName));
    }, DEBOUNCE_TIME),
    [setCompareErrorMessage, signerName],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputName = event.target.value;
      setInputName(inputName);
      setCompareErrorMessage("");
      debouncedValidateFormat(inputName);
    },
    [setCompareErrorMessage, debouncedValidateFormat, setInputName],
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Firmar documento</DialogTitle>
      <DialogContent sx={styles.content}>
        <Typography>
          Para firmar escribe tu nombre y apellido tal como se muestra
          (respetando mayúsculas y tildes):
        </Typography>
        <Typography sx={styles.signature}>{signerName}</Typography>
        <TextField
          sx={styles.input}
          required
          autoComplete="off"
          value={inputName}
          onChange={onChange}
          error={!!compareErrorMessage}
          helperText={compareErrorMessage || undefined}
          inputProps={{
            spellCheck: false,
          }}
          InputProps={{
            endAdornment:
              signerName === inputName ? (
                <InputAdornment position="end">
                  <CheckCircle sx={styles.inputAdornment} />
                </InputAdornment>
              ) : null,
          }}
        />
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <CustomButton onClick={onClose}>CANCELAR</CustomButton>
        <CustomButton
          variant="contained"
          disabled={signerName !== inputName}
          onClick={onSignClick}
        >
          FIRMAR
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    gap: "26px",
  },
  signature: {
    fontFamily: "Dancing Script",
    fontWeight: 500,
    fontSize: "46px",
    textAlign: "center",
    // Prevents user from copy-pasting signature
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  input: {
    width: "100%",
    height: "80px",
  },
  inputAdornment: {
    color: theme.palette.primary.main,
  },
  actions: {
    display: "flex",
    justifyContent: "end",
    gap: "24px",
    padding: "15px 24px",
  },
});

export * from "./validations";
export * from "./data";
export * from "./documents";
export * from "./documentStatus";
export * from "./events";
export * from "./date.utils";
export * from "./courseDetails";
export * from "./identityFile";
export * from "./routes.utils";
export * from "./emails.utils";
export * from "./csv";
export * from "./pages";

export const splitArrayInChunks = <T = unknown>(
  array: T[],
  chunk_size: number,
): T[][] => {
  return Array(Math.ceil(array.length / chunk_size))
    .fill(0)
    .map((begin, index) =>
      array.slice(index * chunk_size, (index + 1) * chunk_size),
    );
};

import { gql, useQuery } from "@apollo/client";
import { Course, LastExecutionBotData } from "interfaces";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";

export const GET_LAST_EXECUTION_BOT_QUERY = gql`
  query GetLastExecutionBot {
    getLastExecutionBot {
      idLog
      descripcion
      fechaIngreso
      idAccion
    }
  }
`;

export const useLastExecutionBot = (): {
  lastExecutionBotData: LastExecutionBotData | null;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getLastExecutionBot: LastExecutionBotData | null;
  }>(GET_LAST_EXECUTION_BOT_QUERY, {
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_LAST_EXECUTION_BOT_DATA] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });
  return {
    lastExecutionBotData: data?.getLastExecutionBot ?? null,
    loading,
  };
};

import React from "react";
import { CurrentUser, DocumentData, DocumentType, Setting } from "interfaces";
import { DJODocument } from "./DJODocument";
import { DJPDocument } from "./DJPDocument";
import { PrecontractDocument } from "./PrecontractDocument";

export * from "./DocumentComponents";
export * from "./IdentificationFiles";
export * from "./DJPDocument";
export * from "./DJODocument";
export * from "./PrecontractDocument";
export * from "./SignDialog";
export * from "./PostSignPageContent";
export * from "./TextSubHeader";
export * from "./EventsPages";

type DocumentComponentProps = {
  data: DocumentData;
  openDialog: () => void;
  signed: boolean;
  settings: Setting;
  user: CurrentUser | null;
  template?: boolean;
};

export type DocumentComponentType = React.FC<DocumentComponentProps>;

export const DocumentComponentMapping: Record<
  DocumentType,
  DocumentComponentType
> = {
  [DocumentType.DJP]: DJPDocument,
  [DocumentType.DJO]: DJODocument,
  [DocumentType.PRECONTRACT]: PrecontractDocument,
  [DocumentType.APME]: PrecontractDocument,
  [DocumentType.VULNERABILITY]: PrecontractDocument,
};

import { MouseEventHandler } from "react";
import {
  Dialog,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";

type Props = DialogProps & {
  title: string;
  children: React.ReactNode;
};

export const CustomDialog: React.FC<Props> = ({
  title,
  children,
  onClose,
  ...props
}) => {
  const styles = useStyles(generateStyles);

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle sx={styles.titleContainer}>
        <Typography sx={styles.title}>{title}</Typography>
        <IconButton
          sx={styles.icon}
          onClick={onClose as MouseEventHandler<HTMLButtonElement>}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  titleContainer: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "3",
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "19px",
    fontWeight: 500,
  },
  icon: {
    color: theme.palette.common.white,
  },
});

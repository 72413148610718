import { useContext, useCallback } from "react";
import { AppContext } from "contexts";
import { DocumentParticipantInfo, Permission } from "interfaces";
import { validateDocumentActionByStatus, DocumentActions } from "utils";

export interface AvailableSpecialDocumentActions {
  /* DJP's & Precontracts */
  canView: boolean;
  canManualUpload: boolean;
  canValidate: boolean;
  canMassiveSignPrecontract: boolean;
  canSendReminderToResponsable: boolean;
  canForceValidation: boolean;
  canViewForcedValidation: boolean;
  canReplace: boolean;
  canSendParticipantReminder: boolean;
  /* Vulnerability */
  canViewVulnerability: boolean;
  canManualUploadVulnerability: boolean;
  canValidateVulnerability: boolean;
  /* Minor Authorization */
  canViewMinorAuthorization: boolean;
  canManualUploadMinorAuthorization: boolean;
  canValidateMinorAuthorization: boolean;
}

type HookReturn = (documentParticipantInfo: DocumentParticipantInfo | null) => AvailableSpecialDocumentActions;

export const useAvailableSpecialDocumentActions = (): HookReturn => {
  const { isAllowedTo } = useContext(AppContext);

  const getActions = useCallback(
    (documentParticipantInfo: DocumentParticipantInfo | null) => {
      const { vulnerabilityStatus, minorAuthorizationStatus } = documentParticipantInfo ?? {};
      return {
        /* DJP's & Precontracts */
        canView:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.VIEW) &&
          isAllowedTo(
            [
              Permission.CAN_VIEW_PARTICIPANT_FILE,
              Permission.CAN_VIEW_OTEC_FILE,
            ],
            false,
          ),
        canManualUpload:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(
            vulnerabilityStatus,
            DocumentActions.MANUAL_UPLOAD,
          ) &&
          isAllowedTo([Permission.CAN_UPLOAD_PARTICIPANT_FILE]),
        canValidate:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.VALIDATION) &&
          isAllowedTo(
            [Permission.CAN_REQUEST_AMEND, Permission.CAN_VALIDATE_FILE],
            false,
          ),
        canMassiveSignPrecontract:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.SIGN) &&
          isAllowedTo([Permission.CAN_SIGN_PRECONTRACT_COMPANY]),
        canSendReminderToResponsable:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(
            vulnerabilityStatus,
            DocumentActions.SEND_REMINDER_TO_RESPONSABLE,
          ) &&
          isAllowedTo([Permission.CAN_SEND_RESPONSABLE_MAIL]),
        canForceValidation:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(
            vulnerabilityStatus,
            DocumentActions.FORCE_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_FORCE_VALIDATION]),
        canViewForcedValidation:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(
            vulnerabilityStatus,
            DocumentActions.VIEW_FORCED_VALIDATION,
          ) &&
          isAllowedTo([Permission.CAN_VIEW_PARTICIPANT_FILE]),
        canReplace:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.REPLACE) &&
          isAllowedTo([Permission.CAN_UPLOAD_PARTICIPANT_FILE]),
        canSendParticipantReminder:
          !!vulnerabilityStatus &&
          validateDocumentActionByStatus(
            vulnerabilityStatus,
            DocumentActions.SEND_REMINDER_TO_SIGNER,
          ) &&
          isAllowedTo([Permission.CAN_SEND_PARTICIPANT_MAIL]),
        /* Vulnerability */
      canViewVulnerability:
        !!vulnerabilityStatus &&
        validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.VIEW) &&
        isAllowedTo(
          [
            Permission.CAN_VIEW_VULNERABILITY_FILE,
            Permission.CAN_VIEW_OTEC_FILE,
          ],
          false,
        ),
      canManualUploadVulnerability:
        !!vulnerabilityStatus &&
        validateDocumentActionByStatus(
          vulnerabilityStatus,
          DocumentActions.MANUAL_UPLOAD,
        ) &&
        isAllowedTo([Permission.CAN_UPLOAD_VULNERABILITY_FILE]),
      canValidateVulnerability:
        !!vulnerabilityStatus &&
        validateDocumentActionByStatus(vulnerabilityStatus, DocumentActions.VALIDATION) &&
        isAllowedTo(
          [Permission.CAN_REQUEST_AMEND_VULNERABILITY, Permission.CAN_VALIDATE_VULNERABILITY],
          false,
        ),
        /* Minor Authorization */
      canViewMinorAuthorization:
        !!minorAuthorizationStatus &&
        validateDocumentActionByStatus(minorAuthorizationStatus, DocumentActions.VIEW) &&
        isAllowedTo(
          [
            Permission.CAN_VIEW_MINORAUTH_FILE,
            Permission.CAN_VIEW_OTEC_FILE,
          ],
          false,
        ),
      canManualUploadMinorAuthorization:
        !!minorAuthorizationStatus &&
        validateDocumentActionByStatus(
          minorAuthorizationStatus,
          DocumentActions.MANUAL_UPLOAD,
        ) &&
        isAllowedTo([Permission.CAN_UPLOAD_MINORAUTH_FILE]),
      canValidateMinorAuthorization:
        !!minorAuthorizationStatus &&
        validateDocumentActionByStatus(minorAuthorizationStatus, DocumentActions.VALIDATION) &&
        isAllowedTo(
          [Permission.CAN_REQUEST_AMEND_MINORAUTH, Permission.CAN_VALIDATE_MINORAUTH],
          false,
        ),
        
      };
    },
    [isAllowedTo],
  );

  return getActions;
};

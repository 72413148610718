import {
  DocumentCompanyStatus,
  DocumentStatus,
  DocumentType,
} from "./document";

export enum CriticalityLevel {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  NONE = "NONE",
}

export const DocumentStatusNames: Record<DocumentStatus, string> = {
  [DocumentStatus.PENDING]: "FALTANTE",
  [DocumentStatus.CORRECTION_REQUESTED]: "EN CORRECCIÓN",
  [DocumentStatus.PENDING_VALIDATION]: "POR VALIDAR",
  [DocumentStatus.VALIDATED]: "VALIDADO",
  [DocumentStatus.INACTIVE]: "INACTIVO",
  [DocumentStatus.OUTDATED]: "FUERA PLAZO",
  [DocumentStatus.FORCED_VALIDATED]: "VALIDADO",
  // Shouldn't be showed
  [DocumentStatus.WAITING_MODULAR]: "ESPERA MODULAR",
};

export const DocumentCompanyStatusNames: Record<DocumentCompanyStatus, string> =
  {
    [DocumentCompanyStatus.PENDING]: "FALTANTE",
    [DocumentCompanyStatus.PRESIGNED]: "FALTANTE",
    [DocumentCompanyStatus.VALIDATED]: "VALIDADO",
    [DocumentCompanyStatus.FORCED_VALIDATED]: "VALIDADO",
    [DocumentCompanyStatus.OUTDATED]: "FUERA PLAZO",
  };

export const DocumentNames: Record<DocumentType, string> = {
  [DocumentType.PRECONTRACT]: "Precontrato",
  [DocumentType.DJO]: "Declaración Jurada OTEC",
  [DocumentType.DJP]: "Declaración Jurada Participante",
  [DocumentType.APME]: "Autorizacion para menores de edad",
  [DocumentType.VULNERABILITY]: "Vulnerabilidad",
};

export const DocumentShortNames: Record<DocumentType, string> = {
  [DocumentType.PRECONTRACT]: "Precontrato",
  [DocumentType.DJO]: "DJO",
  [DocumentType.DJP]: "DJP",
  [DocumentType.APME]: "Autorizacion para menores",
  [DocumentType.VULNERABILITY]: "Vulnerabilidad",

};

export type CriticalyDays = {
  [criticalityLevel in CriticalityLevel]: number;
};

export interface Setting {
  djCriticalyDays: CriticalyDays;
  precontractCriticalyDays: CriticalyDays;
  elearningCriticalyDays: CriticalyDays;
  djpDocumentText: DJPDocumentText;
  djoDocumentText: DJODocumentText;
  precontractDocumentText: PrecontractDocumentText;
  customReminderTexts: CustomReminderTexts;
  pollingIntervals: PollingIntervalsType;
  termsAndConditionsUrl: string;
}

export interface DJPDocumentText {
  title: string;
  subtitle: string;
  upperDescriptionText: string;
  lowerDescriptionText: string;
  footer: string;
}

export interface DJODocumentText {
  title: string;
  subtitle: string;
  firstParagraph: string;
  secondParagraph: string;
  thirdParagraph: string;
  footer: string;
}

export interface PrecontractDocumentText {
  title: string;
  firstParagraph: string;
  articles: string[];
  observations: string[];
}

export enum CustomReminderType {
  SIGN_DJP = "SIGN_DJP",
  SIGN_PRECONTRACT = "SIGN_PRECONTRACT",
  SIGN_DJO = "SIGN_DJO",
  REMINDER_TO_RESPONSABLE_SINGLE_COURSE = "REMINDER_TO_RESPONSABLE_SINGLE_COURSE",
  REMINDER_TO_RESPONSABLE_MULTIPLE_COURSES = "REMINDER_TO_RESPONSABLE_MULTIPLE_COURSES",
}

export type CustomReminderTexts = Record<CustomReminderType, string>;

export enum PolledQuery {
  GET_COURSES_SUMMARY = "GET_COURSES_SUMMARY",
  GET_COURSE_DETAILS = "GET_COURSE_DETAILS",
  GET_USERS = "GET_USERS",
  GET_COMPANIES = "GET_COMPANIES",
  GET_LEGACY_USERS = "GET_LEGACY_USERS",
  GET_OTECS = "GET_OTECS",
  GET_ROLES = "GET_ROLES",
  GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA",
  GET_LAST_EXECUTION_BOT_DATA = "GET_LAST_EXECUTION_BOT_DATA",
  GET_ELEARNING_COURSES_SUMMARY = "GET_ELEARNING_COURSES_SUMMARY",
  GET_COURSES_HISTORY = "GET_COURSES_HISTORY",
  GET_PARTICIPANTS_DJP = "GET_PARTICIPANTS_DJP",
  GET_URL_COURRSE_FILE = "GET_URL_COURRSE_FILE",
  GET_COURSE_METRICS = "GET_COURSE_METRICS",
}

export enum PollingIntervalDuration {
  SHORT = "SHORT",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export type PollingIntervalsType = Partial<Record<PolledQuery, number>> &
  Record<PollingIntervalDuration, number>;

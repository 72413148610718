import { gql } from "@apollo/client";
import {
  client,
  GET_DJ_COURSE_DETAILS_QUERY,
  GET_PRECONTRACT_COURSE_DETAILS_QUERY,
} from "api";
import { DocumentType, InternalMassReminderParams } from "interfaces";

interface MutationParams {
  params: InternalMassReminderParams;
}

interface RefetchParams {
  params: {
    sencenet: string;
    documentType: DocumentType;
  };
}

interface MutationResult {
  sendInternalMassReminder: boolean;
}

export const sendInternalMassReminder = async (
  params: MutationParams["params"],
  refetchParams: RefetchParams["params"],
): Promise<boolean> => {
  const response = await client.mutate<MutationResult, MutationParams>({
    mutation: gql`
      mutation SendInternalMassReminder($params: InternalMassReminderParams!) {
        sendInternalMassReminder(params: $params)
      }
    `,
    variables: {
      params,
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query:
          params.documentType === DocumentType.PRECONTRACT
            ? GET_PRECONTRACT_COURSE_DETAILS_QUERY(params.documentType)
            : GET_DJ_COURSE_DETAILS_QUERY(params.documentType, refetchParams.sencenet),
        variables: {
          params: refetchParams,
        },
      },
    ],
  });
  return !!response.data?.sendInternalMassReminder;
};

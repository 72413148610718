import { Fragment } from "react";
import { Box, Divider, lighten, TextField, Typography } from "@mui/material";
import { createComment } from "api";
import { CustomButton } from "components";
import { FeedbackContext } from "contexts";
import { useStyles } from "hooks";
import { Comment, CSSGenerator } from "interfaces";
import { useCallback, useContext, useState } from "react";
import { CommentComponent } from ".";

type Props = { comments: Comment[]; documentId: number };

export const CommentSection: React.FC<Props> = ({ comments, documentId }) => {
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const styles = useStyles(generateStyles);
  const [newComment, setNewComment] = useState<string | null>();

  const onTextChange = useCallback(
    (event) => {
      setNewComment(event.target.value);
    },
    [setNewComment],
  );

  const onAddCommentClick = useCallback(async () => {
    if (!newComment) return;
    setNewComment("");
    toggleLoader(true, "[HistorialPage] Creating comment");
    const commentCreatedSuccesfully = await createComment(
      {
        documentId,
        text: newComment,
      },
      { documentId },
    );
    if (!commentCreatedSuccesfully) {
      toggleLoader(false, "[HistorialPage] Creating comment");
      showSnackbar("Algo falló al crear el comentario", "error");
      return;
    }
    toggleLoader(false, "[HistorialPage] Creating comment");
    showSnackbar("Se ha creado el comentario con éxito", "success");
  }, [documentId, newComment, showSnackbar, toggleLoader, setNewComment]);

  return (
    <Box>
      <Box sx={styles.titleSection}>
        <Typography sx={styles.title}>Comentarios</Typography>
      </Box>
      <Divider />
      <Box>
        {comments.map((comment, idx) => (
          <Fragment key={idx}>
            <CommentComponent key={`comment-${idx}`} comment={comment} />
            <Divider key={`comment-divider-${idx}`} />
          </Fragment>
        ))}
        {comments.length === 0 && (
          <>
            <Box sx={styles.noCommentsContainer}>
              <Typography sx={styles.noCommentsText}>
                No hay comentarios para este documento
              </Typography>
            </Box>
            <Divider />
          </>
        )}
      </Box>
      <Box sx={styles.formSection}>
        <TextField
          sx={styles.textArea}
          label={"Agrega tu comentario"}
          variant="outlined"
          InputProps={{ sx: styles.textArea }}
          multiline
          value={newComment}
          onChange={onTextChange}
        />
        <CustomButton
          variant="contained"
          disabled={!newComment}
          onClick={onAddCommentClick}
        >
          AGREGAR
        </CustomButton>
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  titleSection: {
    display: "flex",
    alignItems: "center",
    padding: "24px",
  },
  title: {
    fontSize: "19px",
    fontWeight: 500,
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    alignItems: "end",
    gap: "16px",
  },
  textArea: {
    width: "100%",
    minHeight: "144px",
    alignItems: "start",
  },
  noCommentsContainer: {
    padding: "24px",
  },
  noCommentsText: {
    color: lighten(theme.palette.common.black, 0.54),
  },
});

import { isBefore } from "date-fns";
import { IdentityFile, IdentityFileStatus } from "interfaces";
import jsPDF, { ImageOptions } from "jspdf";
import imageCompression from "browser-image-compression";

const pdfImageMaxHeight = 112.5;
const pdfImageMaxWidth = 150;
const compressOptions = {
  maxSizeMB: 0.5,
};

export const isIdentityFileValid = (
  identityFile: IdentityFile | null,
): boolean => {
  if (!identityFile) return false;
  if (identityFile.status === IdentityFileStatus.PENDING_VALIDATION)
    return false;
  if (isBefore(new Date(identityFile.expirationDate), new Date())) return false;
  return true;
};

interface CanvasData {
  canvas: HTMLCanvasElement;
  img: HTMLImageElement;
  width: number;
  height: number;
}
const generateCanvasWithImage = (imgSrc: string): Promise<CanvasData> => {
  return new Promise<CanvasData>(function (resolve, reject) {
    const img = new Image();
    img.onload = () => {
      var canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const { width, height } = img;
      canvas.width = width;
      canvas.height = height;
      context?.drawImage(img, 0, 0);
      resolve({ canvas, img, width, height });
    };
    img.src = imgSrc;

    //fileSize(imgSrc);
    
  });
};

const fileSize = (imagen: string) => {
   const base64String = imagen;

   const stringlenght = base64String.length - 'data:image/png;base64,'.length;

   const sizeinKb = Math.ceil((stringlenght / 4) * 3) / 1000

   return sizeinKb

} 

const getAddImageOptions = (data: CanvasData, n: 0 | 1): ImageOptions => {
  const { canvas, height, width } = data;
  const aspectRatio = width / height;
  const isPortrait = height > width;
  const h = isPortrait ? pdfImageMaxHeight : pdfImageMaxWidth / aspectRatio;
  const w = isPortrait ? pdfImageMaxWidth * aspectRatio : pdfImageMaxWidth;
  return {
    imageData: canvas.toDataURL('image/jpeg', 0.5), //conviertiendo componente a imagen con el fin de reducir tamaño final de pdf
    //imageData: canvas,
    x: 30 + (pdfImageMaxWidth - w) / 2,
    y: 10 + n * (pdfImageMaxHeight + 10),
    width: w,
    height: h,
    compression: "SLOW",
  };
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const compressImage = (file: File): Promise<File> =>
  imageCompression(file, compressOptions);

interface PdfParams {
  frontContent: string;
  backContent: string;
}
export const generateIdentificationFilePdf = async ({
  frontContent,
  backContent,
}: PdfParams): Promise<Blob> => {
  const pdf = new jsPDF("p", "mm", [210, 297], true);
  const [canvas1Data, canvas2Data] = await Promise.all([
    generateCanvasWithImage(frontContent),
    generateCanvasWithImage(backContent),
  ]);
  pdf.addImage(getAddImageOptions(canvas1Data, 0));
  pdf.addImage(getAddImageOptions(canvas2Data, 1));
  const pdfContent = pdf.output("blob");
  return pdfContent;
};

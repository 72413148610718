import {
  ActiveElearningCourseData,
  ElearningCourse,
  FinishedElearningCourseData,
  CriticalityLevel,
  ElearningCourseListData,
  ElearningCourseListFilters,
  ElearningCourseStatusNames,
  ElearningParticipant,
  ElearningParticipantData,
  ElearningParticipantStatusNames,
  AppRoutes,
} from "interfaces";
import { History } from "history";
import { TableChipCellType as ChipType } from "components";
import { slashFormatDate } from "utils/date.utils";

const courseCriticalyLevel = (
  djpsRemaining: number,
  participantsNumber?: number,
): CriticalityLevel => {
  if (djpsRemaining === 0) return CriticalityLevel.LOW;
  return CriticalityLevel.HIGH;
};

export const parseActiveElearningCoursesData = (
  courses: ElearningCourse[],
  history: History,
): ActiveElearningCourseData[] =>
  courses.map((c) => {
    const djpsRemaining = c.participantsNumber - c.djpNumber;
    const remainingDjpsCL = courseCriticalyLevel(
      djpsRemaining,
      c.participantsNumber,
    );
    return {
      onRowClick: () => {
        history.push(`${AppRoutes.SWORN_STATEMENTS_ELEARNING}/${c.sencenet}`);
      },
      id: Number(c.sencenet),
      daysLeft: [c.djDaysLeft, ChipType.DAYS, c.djCriticalyLevel],
      criticalityLevel: c.djCriticalyLevel,
      purchaseOrder: `${c.purchaseOrder}`,
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      cell: c.cell,
      haveDocument: c.haveDocument,
      remainingDjps: [
        `${djpsRemaining}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDjpsCL,
      ],
      djoStatus: [
        ElearningCourseStatusNames[c.djo?.status],
        ChipType.DOCUMENT_STATUS,
        c.djo?.status,
      ],
    };
  });

export const parseFinishedElearningCoursesData = (
  courses: ElearningCourse[],
  history: History,
): FinishedElearningCourseData[] =>
  courses
    .sort(
      (elearningCourseA: ElearningCourse, elearningCourseB: ElearningCourse) =>
        elearningCourseB.endDate.getTime() - elearningCourseA.endDate.getTime(),
    )
    .map((c) => {
      const djpsRemaining = c.participantsNumber - c.djpNumber;
      const remainingDjpsCL = courseCriticalyLevel(
        djpsRemaining,
        c.participantsNumber,
      );

      return {
        onRowClick: () => {
          history.push(`${AppRoutes.SWORN_STATEMENTS_ELEARNING}/${c.sencenet}`);
        },
        id: Number(c.sencenet),
        endDate: slashFormatDate(c.endDate),
        purchaseOrder: `${c.purchaseOrder}`,
        sencenet: c.sencenet,
        name: c.name,
        companyData: `${c.company.rut} - ${c.company.name}`,
        otecData: `${c.otec.rut} - ${c.otec.name}`,
        cell: c.cell,
        haveDocument: c.haveDocument,
        remainingDjps: [
          `${djpsRemaining}/${c.participantsNumber}`,
          ChipType.FRACTION,
          remainingDjpsCL,
        ],
        djoStatus: [
          ElearningCourseStatusNames[c.djo?.status],
          ChipType.DOCUMENT_STATUS,
          c.djo?.status,
        ],
      };
    });

export const parseActiveOnSiteCoursesData = (
  courses: ElearningCourse[],
  history: History,
): ActiveElearningCourseData[] =>
  courses.map((c) => {
    const djpsRemaining = c.participantsNumber - c.djpNumber;
    const remainingDjpsCL = courseCriticalyLevel(
      djpsRemaining,
      c.participantsNumber,
    );
    return {
      onRowClick: () => {
        history.push(`${AppRoutes.LCE_ONSITE}/${c.sencenet}`);
      },
      id: Number(c.sencenet),
      daysLeft: [c.djDaysLeft, ChipType.DAYS, c.djCriticalyLevel],
      criticalityLevel: c.djCriticalyLevel,
      purchaseOrder: `${c.purchaseOrder}`,
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      cell: c.cell,
      haveDocument: c.haveDocument,
      remainingDjps: [
        `${djpsRemaining}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDjpsCL,
      ],
      djoStatus: [
        ElearningCourseStatusNames[c.djo?.status],
        ChipType.DOCUMENT_STATUS,
        c.djo?.status,
      ],
    };
  });

export const parseFinishedOnSiteCoursesData = (
  courses: ElearningCourse[],
  history: History,
): FinishedElearningCourseData[] =>
  courses
  .sort(
    (onSiteCourseA: ElearningCourse, onSiteCourseB: ElearningCourse) =>
      onSiteCourseB.endDate.getTime() - onSiteCourseA.endDate.getTime(),
  )
  .map((c) => {
    const djpsRemaining = c.participantsNumber - c.djpNumber;
    const remainingDjpsCL = courseCriticalyLevel(
      djpsRemaining,
      c.participantsNumber,
    );

    return {
      onRowClick: () => {
        history.push(`${AppRoutes.LCE_ONSITE}/${c.sencenet}`);
      },
      id: Number(c.sencenet),
      endDate: slashFormatDate(c.endDate),
      purchaseOrder: `${c.purchaseOrder}`,
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      cell: c.cell,
      haveDocument: c.haveDocument,
      remainingDjps: [
        `${djpsRemaining}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDjpsCL,
      ],
      djoStatus: [
        ElearningCourseStatusNames[c.djo?.status],
        ChipType.DOCUMENT_STATUS,
        c.djo?.status,
      ],
    };
  });

export const parseElearningParticipantsData = (
  data: ElearningParticipant[],
  history: History,
): ElearningParticipantData[] =>
  data?.map((c) => {
    return {
      rut: c.rutParticipante,
      nombre: c.nombreParticipante,
      apellidoPaterno: c.apellidoPaterno,
      apellidoMaterno: c.apellidoMaterno,
      estadoDjp: [
        ElearningParticipantStatusNames[c.estadoDjp],
        ChipType.DOCUMENT_STATUS,
        c.estadoDjp,
      ],
    };
  });

export const filterElearningCourseListData = (
  data: (ElearningCourseListData & { criticalityLevel?: CriticalityLevel })[],
  filters: ElearningCourseListFilters,
): (ElearningCourseListData & { criticalityLevel?: CriticalityLevel })[] => {
  return data.filter((d) => {
    if (
      filters.purchaseOrder.length > 0 &&
      !filters.purchaseOrder.includes(d.purchaseOrder)
    )
      return false;
    if (filters.sencenet && filters.sencenet !== d.sencenet) return false;
    if (filters.name && filters.name !== d.name) return false;
    if (filters.company && filters.company !== d.companyData) return false;
    if (d.criticalityLevel && !filters.criticalityLevel[d.criticalityLevel])
      return false;
    if (filters.otec && filters.otec !== d.otecData) return false;
    if (filters.cell && filters.cell !== d.cell) return false;

    return true;
  });
};

export const initialElearningCourseFilters: ElearningCourseListFilters = {
  purchaseOrder: [],
  sencenet: null,
  name: null,
  company: null,
  otec: null,
  cell: null,
  criticalityLevel: Object.assign(
    {},
    ...Object.values(CriticalityLevel).map((cl) => ({ [cl]: true })),
  ),
};

import React, { useMemo } from "react";
import { TableCell, Tooltip } from "@mui/material";
import { TableIconCellProps } from "./interfaces";

export const IconCell: React.FC<TableIconCellProps> = ({
  iconData: externalIconData,
  getIconData,
}) => {
  const iconData = useMemo(() => getIconData?.(0) ?? externalIconData, [
    externalIconData,
    getIconData,
  ]);
  if (!iconData) return <TableCell size={"small"} padding={"none"} />;
  return (
    <Tooltip title={iconData.tooltipLabel ?? ""}>
      <TableCell size={"small"} padding={"none"}>
        {iconData.icon ?? ""}
      </TableCell>
    </Tooltip>
  );
};

import { useContext, useEffect } from "react";
import { AppContext } from "contexts";
import { Permission } from "interfaces";
import { useHistory } from "react-router";
import { FORCE_AUTH } from "config";

interface HookProps {
  p: Permission | Permission[];
  every?: boolean;
  redirectTo?: string;
}

export const useRestrictedPage = ({
  p,
  every,
  redirectTo,
}: HookProps): void => {
  const history = useHistory();
  const { loading, isAllowedTo } = useContext(AppContext);

  useEffect(() => {
    if (!FORCE_AUTH && !loading && !isAllowedTo(p, every)) {
      if (redirectTo) {
        history.replace(redirectTo);
      } else {
        window.location.href = process.env.REACT_APP_REDIRECT_UNAUTH_TO || "";
      }
    }
  }, [p, every, redirectTo, loading, isAllowedTo, history]);
};

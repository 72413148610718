import React, { useContext } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";
import { useStyles } from "hooks";
import { CSSGenerator } from "interfaces";
import { FeedbackContext } from "contexts";

interface DropzoneProps {
  onFileChange: (files: File) => void;
  multiple?: boolean;
  accept: string;
  children: React.ReactNode;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onFileChange,
  accept,
  multiple = false,
  children,
}) => {
  const { showSnackbar } = useContext(FeedbackContext);
  const styles = useStyles(generateStyles);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      // TODO: accept multiple files
      onFileChange(acceptedFiles[0]);
    },
    [onFileChange],
  );

  const onDropRejected = useCallback(() => {
    showSnackbar("Este tipo de archivo no está permitido", "error");
  }, [showSnackbar]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    multiple,
    accept,
  });

  return (
    <Box sx={styles.root} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <Typography>Suelta tu archivo aquí</Typography>
      ) : (
        children
      )}
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    width: "100%",
    minHeight: "200px",
    height: "100%",
    padding: "20px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: `2.5px dashed ${theme.palette.grey[400]}`,
    flex: 1,
  },
});

import { CoursesHistoryResponse } from '../../../../api/src/interfaces/assistance-api.interface';
import { gql, useQuery } from "@apollo/client";
import { useContext } from "react";
import { AppContext } from "contexts";
import {
  PolledQuery,
  PollingIntervalDuration,
} from "../../interfaces/settings";


export const GET_COURSES_HISTORY = ( sencenet: number ) => gql`
  query GetCoursesHistory($sencenet: Int!) {
    getCoursesHistory(sencenet: $sencenet) {
      djp {
        idCurso,
        senceNet,
        oc,
        rut,
        fechaIngresoDescargaDJO,
        fechaIngresoDescargaDJP,
        nroParticipantes,
        djpDescargadas
      }
      djo {
          idCurso,
          senceNet,
          oc,
          rut,
          fechaIngresoDescargaDJO,
          fechaIngresoDescargaDJP,
          nroParticipantes,
          djpDescargadas
      }
    }
  }
`;


export const useCoursesHistory = (
  sencenet: number,
): {
  data: any;
  loading: boolean;
} => {
  const { settings } = useContext(AppContext);

  const { data, loading } = useQuery<{
    getCoursesHistory: CoursesHistoryResponse;
  }>(GET_COURSES_HISTORY(sencenet), {
    variables: { sencenet },
    pollInterval:
      settings?.pollingIntervals?.[PolledQuery.GET_COURSES_HISTORY] ??
      settings?.pollingIntervals?.[PollingIntervalDuration.SHORT] ??
      0,
  });

  return {
    data,
    loading
  };
};
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Create } from "@mui/icons-material";
import { AppContext, FeedbackContext } from "contexts";
import { useCoursesSummary } from "api";
import {
  FilterSection,
  TabbedTable,
  TableCellType,
  TableColumnData,
  TableChipCellStyle,
  CellIconData,
  DownloadButton,
} from "components";
import {
  CSSGenerator,
  CourseListFilters,
  CourseListTabs,
  Permission,
  AppRoutes,
  DocumentType,
  DocumentCompanyStatus,
} from "interfaces";
import { useStyles, useRestrictedPage } from "hooks";
import {
  parseActivePrecontractCoursesData,
  parseFinishedPrecontractCoursesData,
  filterCourseListData,
  initialCourseFilters,
  ParsedCoursesSummaryCSVInfo,
  parseCoursesSummaryInfoToCSV,
  filterColDefsBasedOnUser,
} from "utils";

export const PrecontractsPage: React.FC<RouteComponentProps> = () => {
  useRestrictedPage({
    p: [Permission.CAN_ACCESS_PRECONTRACTS, Permission.CAN_LIST_COURSES],
    redirectTo: AppRoutes.DASHBOARD,
  });
  const { isAllowedTo, user } = useContext(AppContext);
  const history = useHistory();
  const { toggleLoader } = useContext(FeedbackContext);
  const { courses, loading } = useCoursesSummary({
    documentType: DocumentType.PRECONTRACT,
  });

  const styles = useStyles(generateStyles);
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [filters, setFilters] =
    useState<CourseListFilters>(initialCourseFilters);
  const activeCoursesData = useMemo(
    () =>
      parseActivePrecontractCoursesData(
        courses.filter((course) => course.active),
        history,
      ),
    [courses, history],
  );

  const finishedCoursesData = useMemo(
    () =>
      parseFinishedPrecontractCoursesData(
        courses.filter((course) => !course.active),
        history,
      ),
    [courses, history],
  );

  const getSelectedTab = useCallback(
    (selectedTab: string) => {
      setSelectedTab(selectedTab);
    },
    [setSelectedTab],
  );
  const getSignButtonIconData = useCallback(
    (id: number): CellIconData | null => {
      const course = activeCoursesData.find((c) => c.id === id);
      if (!course) return null;
      if (
        ![
          DocumentCompanyStatus.PENDING,
          DocumentCompanyStatus.PRESIGNED,
        ].includes(course.companySignatureStatus) 
      )
        return null;

      
      
      return {
        icon: <Create />,
        tooltipLabel: "Firma masiva",
        //disabled: !course.courseContentValidation
        disabled: false
      };
    },
    [activeCoursesData],
  );

  const onMassSignClick = useCallback(
    (id: number) => {
      const course = activeCoursesData.find((c) => c.id === id);

      //console.log("Tiene carnet: ", user?.identityFile);      
      //TODO: Obtener UUID de algun documento en query para hacer replace en ruta 

      if(user?.identityFile === undefined || user?.identityFile === null){
        history.push(AppRoutes.UPLOAD_IDENTIFICATION_FILE.replace(
          ":uuid",
          String(course!.someDocumentUUIDForMassSign),
        ));
        return;
      }      

      if (course?.companySignatureStatus === DocumentCompanyStatus.PENDING) {
        history.push(
          AppRoutes.MASS_PRECONTRACT_SIGN_TEMPLATE.replace(
            ":sencenet",
            String(id),
          ),
        );
        return;
      }
      if (course?.companySignatureStatus === DocumentCompanyStatus.PRESIGNED) {
        history.push(
          AppRoutes.MASS_PRECONTRACT_SIGN_TEMPLATE.replace(
            ":sencenet",
            String(id),
          ),
        );
        return;
      }
      return;
    },
    [activeCoursesData, history],
  );

  const enrichedActiveColumnDefs: TableColumnData[] = useMemo(() => {
    // Filter columns by permissions
    const colDefs = activeColDefs.filter(
      (colDef) => !colDef.permissions || isAllowedTo(colDef.permissions, false),
    );

    const massSignCol = colDefs.find((col) => col.attr === "massSign");
    if (massSignCol) {
      massSignCol.onClick = onMassSignClick;
      massSignCol.getIconData = getSignButtonIconData;
    }

    return colDefs;
  }, [getSignButtonIconData, isAllowedTo, onMassSignClick]);

  const tabsInfo = useMemo(
    () => [
      {
        name: CourseListTabs.ACTIVE,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(enrichedActiveColumnDefs, user),
          rawData: filterCourseListData(activeCoursesData, filters),
        },
      },
      {
        name: CourseListTabs.FINISHED,
        tableInfo: {
          columnDefs: filterColDefsBasedOnUser(finishedColDefs, user),
          rawData: filterCourseListData(finishedCoursesData, filters),
        },
      },
    ],
    [
      enrichedActiveColumnDefs,
      activeCoursesData,
      filters,
      finishedCoursesData,
      user,
    ],
  );

  const CSV_HEADERS: {
    label: string;
    key: keyof ParsedCoursesSummaryCSVInfo;
  }[] = [
    { label: "Días de plazo", key: "daysLeft" },
    { label: "Fecha finalización del curso", key: "endDate" },
    { label: "Estado curso", key: "courseStatus" },
    { label: "N° de Inscripción", key: "registrationNumber" },
    { label: "Sencenet", key: "sencenet" },
    { label: "Nombre curso", key: "courseName" },
    { label: "Nombre empresa", key: "companyName" },
    { label: "Precontratos faltantes", key: "documentsRemaining" },
  ];

  const parsedExportableData: ParsedCoursesSummaryCSVInfo[] = useMemo(
    () => parseCoursesSummaryInfoToCSV(courses, DocumentType.PRECONTRACT),
    [courses],
  );

  useEffect(() => {
    toggleLoader(loading, "PrecontractsPage loading");
  }, [loading, toggleLoader]);

  return (
    <Box sx={styles.root}>
      <Typography variant="h6">Precontratos</Typography>
      <FilterSection
        data={
          selectedTab === CourseListTabs.FINISHED
            ? finishedCoursesData
            : activeCoursesData
        }
        filters={filters}
        setFilters={setFilters}
        useCriticalityFilters={selectedTab === CourseListTabs.ACTIVE}
        useCellFilter={user?.needFilterByCell}
      />
      <Box sx={styles.buttonContainer}>
        <DownloadButton
          data={parsedExportableData}
          headers={CSV_HEADERS}
          filename="lista-cursos-precontratos.csv"
        />
      </Box>
      <TabbedTable tabsInfo={tabsInfo} getSelectedTab={getSelectedTab} />
    </Box>
  );
};

const generateStyles: CSSGenerator = () => ({
  root: { display: "flex", flexDirection: "column", gap: "24px" },
  buttonContainer: { display: "flex", justifyContent: "end" },
});

const activeColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.CHIP,
    headerName: "Días de plazo",
    attr: "daysLeft",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.PRIMARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "N° Inscripción",
    attr: "registrationNumber",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Precontratos faltantes",
    attr: "remainingDocuments",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Autoriz. Menores",
    attr: "minorAuthorizationDocs",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Vulnerabilidad",
    attr: "vulnerability",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.ICON_BUTTON,
    headerName: "",
    attr: "massSign",
    sortable: false,
    onClick: undefined, // injected on component
    getIconData: undefined, // injected on component
    style: {},
    align: "center",
    permissions: [Permission.CAN_SIGN_PRECONTRACT_COMPANY],
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
];

const finishedColDefs: TableColumnData[] = [
  {
    cellType: TableCellType.TEXT,
    headerName: "Fecha de cierre",
    attr: "endDate",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "N° Inscripción",
    attr: "registrationNumber",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Sencenet",
    attr: "sencenet",
    sortable: true,
    align: "center",
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Curso",
    attr: "name",
    sortable: true,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Empresa",
    attr: "companyData",
    sortable: true,
  },
  {
    cellType: TableCellType.CHIP,
    headerName: "Precontratos firmados",
    attr: "finishedDocuments",
    sortable: true,
    align: "center",
    chipStyleType: TableChipCellStyle.SECONDARY,
  },
  {
    cellType: TableCellType.TEXT,
    headerName: "Célula",
    attr: "cell",
    sortable: true,
    align: "center",
  },
];

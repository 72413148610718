import { CSSObject, Theme } from "@mui/material";

export type CSSStyles = Record<string, CSSObject>;

export type CSSGenerator<T = undefined> = (theme: Theme, args: T) => CSSStyles;

export enum ComponentType {
  DEFAULT = "DEFAULT",
  DOCUMENT = "DOCUMENT",
}

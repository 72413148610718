import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Toolbar } from "@mui/material";
import { useCoursesHistory } from "api";
import { FeedbackContext } from "contexts";
import { RecordDetailSection, TextHeader } from "components";
import {
  ComponentType,
  CSSGenerator,
} from "interfaces";
import { useStyles } from "hooks";

export const HistoryDetailPage: React.FC = () => {
  const styles = useStyles(generateStyles);
  const { toggleLoader } = useContext(FeedbackContext);
  const { sencenet } = useParams<{ sencenet: any }>();

  const { data, loading: loadingData } = useCoursesHistory(
    Number(sencenet),
  );

  const djpItems = data?.getCoursesHistory.djp;
  const djoItems = data?.getCoursesHistory.djo;

  useEffect(() => {
    toggleLoader(loadingData, "[Historial Page] Fetching historial data");
  }, [loadingData, toggleLoader]);
  return (
    <Box sx={styles.background}>
      <TextHeader text={`SenceNet: ${sencenet}`} />
      <Toolbar />
      <Box sx={styles.root}>
        {data && (
          <>
            <Paper sx={styles.historialSection}>
              <RecordDetailSection
                type={ComponentType.DEFAULT}
                djpItems={djpItems}
                djoItems={djoItems}
              />
            </Paper>
          </>
        )}
      </Box>
    </Box>
  );
};

const generateStyles: CSSGenerator = () => ({
  root: { display: "flex", gap: "16px" },
  background: {
    padding: "31px",
    backgroundColor: "#F9F9F9",
    minHeight: "calc(100vh - 64px)",
  },
  historialSection: {
    flex: 5,
    padding: "8px",
  },
});

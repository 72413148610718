import { useContext, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { RecordIconType, PostSignPageContent, TextHeader } from "components";
import { FeedbackContext } from "contexts";
import { useDocumentDataByUUID } from "api";
import {
  getSuccessfulSignaturePageBodyText,
  getSignPageDescription,
  getSignPageTitle,
} from "utils";
import { DocumentStatus, DocumentType } from "interfaces";

export const SuccessfulSignaturePage: React.FC = () => {
  const { toggleLoader } = useContext(FeedbackContext);

  /* FETCH DOCUMENT DATA */
  const { uuid } = useParams<{ uuid: string }>();
  const { data, loading } = useDocumentDataByUUID(uuid);
  useEffect(() => {
    toggleLoader(loading, "[SuccessfulSignaturePage] Fetching document data");
  }, [loading, toggleLoader]);

  const title = useMemo(() => getSignPageTitle(data), [data]);

  const description = useMemo(
    () =>
      data?.document?.status === DocumentStatus.VALIDATED
        ? getSignPageDescription(data)
        : data?.document?.type === DocumentType.DJO
        ? "Nos comunicaremos contigo ante cualquier error."
        : "Validaremos tu cédula de identidad. Nos comunicaremos contigo ante cualquier error.",
    [data],
  );

  const bodyText = useMemo(
    () => getSuccessfulSignaturePageBodyText(data),
    [data],
  );

  const openDocument = useCallback(() => {
    const url = data?.document?.url;
    if (url) window.open(url, "_blank")?.focus();
  }, [data?.document?.url]);

  return (
    <Box>
      <TextHeader text={title} showAvatar={false} />
      <PostSignPageContent
        icon={RecordIconType.DOCUMENT_SUCCESSFUL}
        iconType="success"
        title="Has firmado con éxito"
        description={description}
        email={data?.document?.signatureEmail ?? ""}
        bodyText={bodyText}
        showButtonDocument={!!data?.document?.url}
        onClick={openDocument}
      />
    </Box>
  );
};

import { useCallback } from "react";
import { AppBar, Box, IconButton, Toolbar, darken } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { AvatarComponent } from "./AvatarComponent";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";

const HOMEPAGE_URL = process.env.REACT_APP_HOMEPAGE_URL;

type Props = {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppHeader: React.FC<Props> = ({ setDrawerOpen }) => {
  const styles = useStyles(generateStyles);

  const onMenuClick = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, [setDrawerOpen]);

  return (
    <AppBar position="fixed" sx={styles.root}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.leftContainer}>
          <IconButton onClick={onMenuClick}>
            <Menu sx={styles.icon} />
          </IconButton>
          <a href={HOMEPAGE_URL}>
            <img
              style={{ height: "35px" }}
              src="/icons/logo_white.svg"
              alt="logo"
            />
          </a>
        </Box>
        <Box sx={styles.rightContainer}>
          <AvatarComponent />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  icon: {
    fill: theme.palette.common.white,
  },
  leftContainer: {
    display: "flex",
    gap: "24px",
  },
  rightContainer: {
    display: "flex",
    gap: "24px",
  },
  logoutButton: {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: darken(theme.palette.primary.main, 0.1),
    },
  },
});

import {
  DocumentParticipantInfo,
  Course,
  DocumentStatusNames,
  DocumentType,
  DocumentShortNames,
} from "interfaces";

export const getUploadDialogTitle = (
  info: DocumentParticipantInfo,
  documentType: DocumentType,
) =>
  `Cargar ${DocumentShortNames[documentType]} · RUT ${info.participant.rut} · ${info.participant.name}`;

export const getValidationDialogTitle = (
  info: DocumentParticipantInfo,
  course: Course | null,
  documentType: DocumentType,
) => {
  const { document, participant } = info ?? {};
  const { status } = document || {};
  return `${DocumentShortNames[documentType]} · N° Inscripción ${
    course?.registrationNumber ?? ""
  } · RUT ${participant?.rut ?? ""} · ${participant?.name ?? ""} · ${
    status ? DocumentStatusNames[status] : ""
  }`;
};


export const getValidationDialogVulnerabilityTitle = (
  info: DocumentParticipantInfo,
  course: Course | null,
  documentType: DocumentType,
) => {
  const { documentVulnerability, participant } = info ?? {};
  const { status } = documentVulnerability || {};
  return `${DocumentShortNames[documentType]} · N° Inscripción ${
    course?.registrationNumber ?? ""
  } · RUT ${participant?.rut ?? ""} · ${participant?.name ?? ""} · ${
    status ? DocumentStatusNames[status] : ""
  }`;
};

export const getValidationDialogMinorAuthTitle = (
  info: DocumentParticipantInfo,
  course: Course | null,
  documentType: DocumentType,
) => {
  const { documentMinorAuth , participant } = info ?? {};
  const { status } = documentMinorAuth || {};
  return `${DocumentShortNames[documentType]} · N° Inscripción ${
    course?.registrationNumber ?? ""
  } · RUT ${participant?.rut ?? ""} · ${participant?.name ?? ""} · ${
    status ? DocumentStatusNames[status] : ""
  }`;
};
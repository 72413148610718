import { DatePicker, DatePickerProps } from "@mui/lab";
import { TextField } from "@mui/material";
import { addDays } from "date-fns";

export const ExpirationDatePicker: React.FC<
  Omit<DatePickerProps, "renderInput">
> = ({ label, value, onChange, minDate }) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      renderInput={(params: any): any => <TextField {...params} />}
      openTo="year"
      disableHighlightToday
      minDate={minDate ?? addDays(new Date(), 1)}
    />
  );
};

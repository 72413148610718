import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { CSSGenerator, CSSStyles } from "interfaces";

export const useStyles = <T = undefined>(
  generateStyles: CSSGenerator<T> | CSSStyles,
  args?: T,
) => {
  const theme = useTheme();
  const [styles, setStyles] = useState<CSSStyles>(() => {
    if (typeof generateStyles === "function")
      return generateStyles(theme, args as T);

    return generateStyles;
  });

  useEffect(() => {
    if (typeof generateStyles === "function") {
      setStyles(generateStyles(theme, args as T));
    }
  }, [theme, args, generateStyles, setStyles]);

  return styles;
};

import React from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  RecordIconStyle,
  RecordPNGIcon,
  EventIconMapping,
  RecordIcon,
} from "components";
import { Typography } from "@mui/material";
import { ComponentType, CSSGenerator, EventType } from "interfaces";
import { useStyles } from "hooks";
import { setSizeByComponentType } from "utils";

export interface RecordItem {
  mainText: string;
  secondaryText?: string;
  date: string;
  eventType: EventType;
  iconStyle?: RecordIconStyle;
}

type Props = {
  items: RecordItem[];
  type: ComponentType;
};

export const RecordTimeline: React.FC<Props> = ({ items, type }) => {
  const styles = useStyles(generateStyles, type);
  return (
    <Timeline sx={styles.root}>
      {items.map((item, idx) => (
        <TimelineItem sx={styles.item} key={`timelineItem-${idx}`}>
          <TimelineSeparator>
            {type === ComponentType.DOCUMENT ? (
              <RecordPNGIcon
                icon={EventIconMapping[item.eventType]}
                style={styles.backgroundIcon}
              />
            ) : (
              <RecordIcon
                icon={EventIconMapping[item.eventType]}
                backgroundStyle={styles.backgroundIcon}
                iconStyle={styles.icon}
              />
            )}
            {idx + 1 !== items.length && (
              <TimelineConnector sx={styles.connector} />
            )}
          </TimelineSeparator>
          <TimelineContent sx={styles.content}>
            <Typography sx={styles.primaryText}>{item.mainText}</Typography>
            {item.secondaryText && (
              <Typography sx={styles.secondaryText}>
                {`"${item.secondaryText}"`}
              </Typography>
            )}
            <Typography sx={styles.date}>{item.date}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const generateStyles: CSSGenerator<ComponentType> = (theme, type) => {
  return {
    root: {
      margin: 0,
    },
    backgroundIcon: {
      width: setSizeByComponentType(40, type),
      height: setSizeByComponentType(40, type),
    },
    icon: {
      fontSize: setSizeByComponentType(20, type),
    },
    item: {
      minHeight: setSizeByComponentType(90, type),
      "&:before": {
        display: "none",
      },
    },
    connector: {
      backgroundColor: theme.palette.grey[300],
    },
    content: {
      paddingRight: "0px",
      paddingTop: "0px",
    },
    primaryText: {
      fontSize: setSizeByComponentType(16, type),
    },
    secondaryText: {
      fontSize: setSizeByComponentType(16, type),
      color: theme.palette.text.secondary,
      fontStyle: "italic",
    },
    date: {
      fontSize: setSizeByComponentType(13, type),
      color: theme.palette.text.secondary,
    },
  };
};

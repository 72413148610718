import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  RecordIconType,
  RecordIconStyle,
  RecordPNGIcon,
  RecordIcon,
  EventIconMapping,
} from "components";
import { useStyles } from "hooks";
import {
  ComponentType,
  CSSGenerator, 
  EventType,
} from "interfaces";
import React from "react";
import { setSizeByComponentType, humanFormatDate } from "utils";

export interface RecordDetailItem {
  idCurso: string;
  secondaryText?: string
  fechaIngresoDescargaDJO: Date;
  fechaIngresoDescargaDJP: Date;
  eventType: EventType;
  oc: number;
  rut: string;
  djpDescargadas: number;
  senceNet: number;
  iconStyle?: RecordIconStyle;
}

type RecordSectionProps = {
  // documentType: DocumentType;
  djpItems: RecordDetailItem[];
  djoItems: RecordDetailItem[];
  type: ComponentType;
};

export const RecordDetailSection: React.FC<RecordSectionProps> = ({
  djpItems,
  djoItems,
  type
}) => {
  const styles = useStyles(generateSectionStyles, type);
  return (
    <Box sx={styles.root}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.title}>Historial de Firma Digital</Typography>
        <RecordPNGIcon
          icon={RecordIconType.LOCK}
          style={styles.backgroundIcon}
        />
        <Typography sx={styles.documentName}>
          
        </Typography>
      </Box>
      <RecordDetailTimeline type={type} djoItems={djoItems} djpItems={djpItems} />
    </Box>
  );
};


type TimelineProps = {
  djoItems: RecordDetailItem[];
  djpItems: RecordDetailItem[];
  type: ComponentType;
};

export const RecordDetailTimeline: React.FC<TimelineProps> = ({ djpItems, djoItems, type }) => {
  const styles = useStyles(generateTimelineStyles, type);
  return (
    <Timeline sx={styles.root}>
      {djpItems?.map((item, idx) => (
        <TimelineItem sx={styles.item} key={`timelineItem-${idx}`}>
          <TimelineSeparator>
            {type === ComponentType.DOCUMENT ? (
              <RecordPNGIcon
                icon={EventIconMapping["DOCUMENT_GENERATED"]}
                style={styles.backgroundIcon}
              />
            ) : (
              <RecordIcon
                icon={EventIconMapping["DOCUMENT_GENERATED"]}
                backgroundStyle={styles.backgroundIcon}
                iconStyle={styles.icon}
              />
            )}
            {idx + 1 !== djpItems.length && (
              <TimelineConnector sx={styles.connector} />
            )}
          </TimelineSeparator>
          <TimelineContent sx={styles.content}>
            <Typography sx={styles.primaryText}>RUT: {item.rut}</Typography>
            {item.secondaryText && (
              <Typography sx={styles.secondaryText}>
                {`"${item.secondaryText}"`}
              </Typography>
            )}
            <Typography sx={styles.date}>{humanFormatDate(new Date(item.fechaIngresoDescargaDJP))}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
      {djoItems?.map((item, idx) => (
        <TimelineItem sx={styles.item} key={`timelineItem-${idx}`}>
          <TimelineSeparator>
            {type === ComponentType.DOCUMENT ? (
              <RecordPNGIcon
                icon={EventIconMapping["DOCUMENT_GENERATED"]}
                style={styles.backgroundIcon}
              />
            ) : (
              <RecordIcon
                icon={EventIconMapping["DOCUMENT_GENERATED"]}
                backgroundStyle={styles.backgroundIcon}
                iconStyle={styles.icon}
              />
            )}
            {idx + 1 !== djoItems.length && (
              <TimelineConnector sx={styles.connector} />
            )}
          </TimelineSeparator>
          <TimelineContent sx={styles.content}>
            <Typography sx={styles.primaryText}>OC: {item.oc}</Typography>
            {item.secondaryText && (
              <Typography sx={styles.secondaryText}>
                {`"${item.secondaryText}"`}
              </Typography>
            )}
            <Typography sx={styles.date}>{humanFormatDate(new Date(item.fechaIngresoDescargaDJO))}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};


const generateSectionStyles: CSSGenerator<ComponentType> = (theme, type) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: setSizeByComponentType(24, type),
    border:
      type === ComponentType.DOCUMENT
        ? `solid ${setSizeByComponentType(0.6, type)} ${
            theme.palette.grey[300]
          }`
        : "unset",
    padding: setSizeByComponentType(16, type),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: setSizeByComponentType(16, type),
  },
  title: {
    fontSize: setSizeByComponentType(20, type),
    fontWeight: type === ComponentType.DOCUMENT ? 600 : 500,
  },
  documentName: {
    fontSize: setSizeByComponentType(20, type),
    color: theme.palette.text.secondary,
    fontWeight: type === ComponentType.DOCUMENT ? 600 : 500,
  },
  descriptionContainer: {
    backgroundColor: theme.palette.grey[100],
    padding: setSizeByComponentType(16, type),
    display: "flex",
    flexDirection: type === ComponentType.DOCUMENT ? "column" : "row",
  },
  backgroundIcon: {
    width: setSizeByComponentType(30, type),
    height: setSizeByComponentType(30, type),
  },
  icon: {
    fontSize: setSizeByComponentType(15, type),
  },
});

const generateTimelineStyles: CSSGenerator<ComponentType> = (theme, type) => {
  return {
    root: {
      margin: 0,
    },
    backgroundIcon: {
      width: setSizeByComponentType(40, type),
      height: setSizeByComponentType(40, type),
    },
    icon: {
      fontSize: setSizeByComponentType(20, type),
    },
    item: {
      minHeight: setSizeByComponentType(90, type),
      "&:before": {
        display: "none",
      },
    },
    connector: {
      backgroundColor: theme.palette.grey[300],
    },
    content: {
      paddingRight: "0px",
      paddingTop: "0px",
    },
    primaryText: {
      fontSize: setSizeByComponentType(16, type),
    },
    secondaryText: {
      fontSize: setSizeByComponentType(16, type),
      color: theme.palette.text.secondary,
      fontStyle: "italic",
    },
    date: {
      fontSize: setSizeByComponentType(13, type),
      color: theme.palette.text.secondary,
    },
  };
};

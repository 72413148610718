import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";
import { CustomTable, CustomTableProps } from "components";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";

export type TabbedTableProps = {
  tabsInfo: {
    name: string;
    tableInfo: CustomTableProps;
  }[];
  getSelectedTab?: (selectedTab: string) => void;
};

export const TabbedTable: React.FC<TabbedTableProps> = ({
  tabsInfo,
  getSelectedTab,
}) => {
  const styles = useStyles(generateStyles);
  const [selectedTab, setSelectedTab] = useState<string>(tabsInfo[0].name);
  const handleChangeTab = useCallback(
    (_, newTab: string) => {
      setSelectedTab(newTab);
    },
    [setSelectedTab],
  );
  const selectedTabInfo = useMemo(
    () => tabsInfo.find((tabInfo) => tabInfo.name === selectedTab),
    [selectedTab, tabsInfo],
  );

  useEffect(() => {
    if (getSelectedTab) getSelectedTab(selectedTab);
  }, [selectedTab, getSelectedTab]);

  return (
    <Paper>
      <Box sx={styles.tabContainer}>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          {tabsInfo.map((tab, index) => (
            <Tab
              key={`tab-${index}`}
              label={tab.name}
              value={tab.name}
              sx={styles.tab}
            />
          ))}
        </Tabs>
      </Box>
      <Box>
        <CustomTable
          rawData={selectedTabInfo?.tableInfo.rawData ?? []}
          columnDefs={selectedTabInfo?.tableInfo.columnDefs ?? []}
        />
      </Box>
    </Paper>
  );
};

const generateStyles: CSSGenerator = () => ({
  tabContainer: { borderBottom: 1, borderColor: "divider" },
  tab: { width: "240px", letterSpacing: "1.25px", fontWeight: 500 },
});

import { TableColumnData } from "components";
import { CurrentUser } from "interfaces";

export const filterColDefsBasedOnUser = (
  colDefs: TableColumnData[],
  user: CurrentUser | null,
): TableColumnData[] => {
  //return colDefs.filter((cd) => user?.needFilterByCell || cd.attr !== "cell")
  return colDefs;
};

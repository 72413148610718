import { gql } from "@apollo/client";

export const EventFragment = gql`
  fragment EventFields on Event {
    id
    type
    entity
    personName
    personEmail
    personRut
    personRole
    comment
    createdAt
  }
`;

import { useMemo } from "react";
import { DocumentPage, RecordItem, RecordSection } from "components";
import { ComponentType, Document } from "interfaces";
import { splitArrayInChunks } from "utils";

interface Props {
  document: Document;
  items: RecordItem[];
}
export const EventsPages: React.FC<Props> = ({ items, document }) => {
  const eventsPerPage = useMemo(() => splitArrayInChunks(items, 12), [items]);
  return (
    <>
      {eventsPerPage.map((events, index) => (
        <DocumentPage key={index}>
          <RecordSection
            items={events}
            type={ComponentType.DOCUMENT}
            document={document}
            continuation={index > 0}
          />
        </DocumentPage>
      ))}
    </>
  );
};

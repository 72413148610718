import { AppBar, Box, Toolbar, Typography, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { AvatarComponent } from "./AvatarComponent";
import { CSSGenerator } from "interfaces";
import { useStyles } from "hooks";

type Props = {
  text?: string;
  showAvatar?: boolean;
};

export const TextHeader: React.FC<Props> = ({ text, showAvatar = true }) => {
  const styles = useStyles(generateStyles);
  const history = useHistory();
  const onClick = useCallback(() => {
    history.goBack();
  }, [history]);
  return (
    <AppBar position="fixed" sx={styles.root}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={styles.leftContainer}>
          <IconButton onClick={onClick}>
            <ArrowBack sx={styles.icon} />
          </IconButton>

          <Typography sx={styles.text}>{text}</Typography>
        </Box>

        {showAvatar && (
          <Box sx={styles.rightContainer}>
            <AvatarComponent />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px",
  },
  icon: {
    fill: theme.palette.common.white,
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    gap: "24px",
    [theme.breakpoints.down("sm")]: {
      gap: "0px",
    },
  },
  rightContainer: {
    display: "flex",
    gap: "24px",
  },
  text: {
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

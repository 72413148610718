import { Box, Typography, alpha } from "@mui/material";
import { CSSGenerator, DocumentFormat } from "interfaces";
import { RecordIcon, RecordIconType, CustomIframe } from "components";
import { useStyles } from "hooks";

type Props = {
  url?: string;
  identityFileExtension: DocumentFormat;
};

export const FileView: React.FC<Props> = ({ url, identityFileExtension }) => {
  const styles = useStyles(generateStyles);
  if (identityFileExtension === DocumentFormat.PDF)
    return (
      <CustomIframe
        title="Documento"
        src={url ?? ""}
        hideNavpanes
        style={{
          width: "100%",
          height: "550px",
          marginRight: "10px",
        }}
      />
    );
  if (identityFileExtension === DocumentFormat.IMG)
    return (
      <div
        style={{
          width: "100%",
          height: "550px",
          marginRight: "10px",
          overflowX: "scroll",
          overflowY: "scroll",
        }}
      >
        <img src={url} alt="" width="100%" />
      </div>
    );
  return (
    <Box sx={styles.background}>
      <Box sx={styles.containerIcon}>
        <RecordIcon
          icon={RecordIconType.WARNING}
          iconStyle={styles.icon}
          backgroundStyle={styles.iconBackground}
        />
      </Box>
      <Typography sx={styles.text}>
        No hay cédula de identidad cargada
      </Typography>
    </Box>
  );
};

const generateStyles: CSSGenerator = (theme) => ({
  background: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    alignItems: "center",
    paddingTop: "calc(32px + 128px)",
    paddingBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "calc(32px + 64px)",
    },
  },
  icon: {
    color: theme.palette.warning.dark,
    height: "78px",
    width: "63px",
    [theme.breakpoints.down("sm")]: {
      height: "43px",
      width: "32px",
    },
  },
  iconBackground: {
    background: alpha(theme.palette.warning.dark, 0.1),
    height: "133px",
    width: "133px",
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: "19px",
    textAlign: "center",
    padding: "0px 30px 0px 30px",
  },
});

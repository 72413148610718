import { History } from "history";
import {
  ActivePrecontractCourseData,
  ActiveDjCourseData,
  FinishedPrecontractCourseData,
  FinishedDjCourseData,
  CourseListData,
  CourseListFilters,
  AppRoutes,
  Course,
  CriticalityLevel,
  DocumentStatusNames,
  DocumentParticipantInfo,
  DocumentCompanyStatusNames,
  DocumentParticipantInfoData,
  DocumentStatus,
} from "interfaces";
import { TableChipCellType as ChipType } from "components";
import { differenceInCalendarDays } from "date-fns";
import { slashFormatDate } from "utils";

const documentsCriticalyLevel = (
  documentsRemaining: number,
  participantsNumber: number,
): CriticalityLevel => {
  if (documentsRemaining === participantsNumber) return CriticalityLevel.HIGH;
  if (documentsRemaining === 0) return CriticalityLevel.LOW;
  return CriticalityLevel.MEDIUM;
};

const minorAuthorizationCriticalyLevel = (
  minorAuthorizationDocsPending: number,
): CriticalityLevel => {
  if (minorAuthorizationDocsPending === 0) return CriticalityLevel.LOW;  //verde
  return CriticalityLevel.HIGH; //rojo
};

const vulnerabilityCriticalyLevel = (
): CriticalityLevel => {
  return CriticalityLevel.NONE; 
};

export const parseActiveDjCoursesData = (
  courses: Course[],
  history: History,
): ActiveDjCourseData[] =>
  courses.map((c) => {
    const remainingDjpsCL = documentsCriticalyLevel(
      c.documentsRemaining,
      c.participantsNumber,
    );
    return {
      onRowClick: () => {
        history.push(`${AppRoutes.SWORN_STATEMENTS}/${c.sencenet}`);
      },
      id: Number(c.sencenet),
      daysLeft: [c.djDaysLeft, ChipType.DAYS, c.djCriticalyLevel],
      processEndDate: c.processEndDate,
      criticalityLevel: c.djCriticalyLevel,
      registrationNumber: c.registrationNumber,
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      company: c.company.name,
      remainingDjps: [
        `${c.documentsRemaining}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDjpsCL,
      ],
      djoStatus: [
        DocumentStatusNames[c.djo?.status],
        ChipType.DOCUMENT_STATUS,
        c.djo?.status,
      ],
      lastReminder:
        c.lastReminder === null
          ? ""
          : `Hace ${c.lastReminder} día${c.lastReminder > 1 ? "s" : ""}`,
      cell: c.cell,
    };
  });

export const parseFinishedDjCoursesData = (
  courses: Course[],
  history: History,
): FinishedDjCourseData[] =>
  courses.map((c) => {
    const remainingDjpsCL = documentsCriticalyLevel(
      c.documentsRemaining,
      c.participantsNumber,
    );
    return {
      onRowClick: () => {
        history.push(`${AppRoutes.SWORN_STATEMENTS}/${c.sencenet}`);
      },
      registrationNumber: c.registrationNumber,
      endDate: slashFormatDate(c.finishManagementDate),
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      company: c.company.name,
      finishedDjps: [
        `${c.documentsFinished}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDjpsCL,
      ],
      djoStatus: [
        DocumentStatusNames[c.djo?.status],
        ChipType.DOCUMENT_STATUS,
        c.djo?.status,
      ],
      cell: c.cell,
    };
  });

export const parseActivePrecontractCoursesData = (
  courses: Course[],
  history: History,
): ActivePrecontractCourseData[] =>
  courses.map((c) => {
    const remainingDocsCL = documentsCriticalyLevel(
      c.documentsRemaining,
      c.participantsNumber,
    );  
    const authorizationDocsCL = minorAuthorizationCriticalyLevel(
      c.minorAuthorizationDocsPending,
    );
    const vulnerabilityDocsCL = vulnerabilityCriticalyLevel();

    return {
      id: Number(c.sencenet),
      companySignatureStatus: c.companySignatureStatus,
      onRowClick: () => {
        history.push(`${AppRoutes.PRECONTRACTS}/${c.sencenet}`);
      },
      daysLeft: [
        c.precontractDaysLeft,
        ChipType.DAYS,
        c.precontractCriticalyLevel,
      ],
      criticalityLevel: c.precontractCriticalyLevel,
      registrationNumber: c.registrationNumber,
      sencenet: c.sencenet,
      someDocumentUUIDForMassSign: c.someDocumentUUIDForMassSign,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      company: c.company.name,
      remainingDocuments: [
        `${c.documentsRemaining}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDocsCL,
      ],
      minorAuthorizationDocs: [
        `${c.minorAuthorizationDocsPending}/${c.minorAuthorizationDocsTotal}`,
        ChipType.FRACTION,
        authorizationDocsCL,
      ],
      vulnerability: [
        `${c.vulnerabilityDocsTotal}`,
        ChipType.FRACTION,
        vulnerabilityDocsCL,
      ],
      lastReminder:
        c.lastReminder === null
          ? ""
          : `Hace ${c.lastReminder} día${c.lastReminder > 1 ? "s" : ""}`,
      cell: c.cell,
    };
  });
 

export const parseFinishedPrecontractCoursesData = (
  courses: Course[],
  history: History,
): FinishedPrecontractCourseData[] =>
  courses.map((c) => {
    const remainingDocsCL = documentsCriticalyLevel(
      c.documentsRemaining,
      c.participantsNumber,
    );
    return {
      onRowClick: () => {
        history.push(`${AppRoutes.PRECONTRACTS}/${c.sencenet}`);
      },
      registrationNumber: c.registrationNumber,
      endDate: slashFormatDate(c.finishManagementDate),
      sencenet: c.sencenet,
      name: c.name,
      companyData: `${c.company.rut} - ${c.company.name}`,
      otecData: `${c.otec.rut} - ${c.otec.name}`,
      company: c.company.name,
      finishedDocuments: [
        `${c.documentsFinished}/${c.participantsNumber}`,
        ChipType.FRACTION,
        remainingDocsCL,
      ],
      cell: c.cell,
    };
  });

const getLastReminderDaysText = (date: string | null): string => {
  if (date === null) return "Sin recordatorios enviados";
  const differenceDays = differenceInCalendarDays(
    new Date(),
    new Date(Number(date)),
  );
  return differenceDays === 0
    ? "Hoy"
    : `Hace ${differenceDays} día${differenceDays > 1 ? "s" : ""}`;
};

export const parseCourseParticipantsData = (
  docsParticipants: DocumentParticipantInfo[],
  history: History,
): DocumentParticipantInfoData[] => {
  return docsParticipants.map((doc: DocumentParticipantInfo) => ({
    onRowClick: () => {
      history.push(`${history.location.pathname}/historial/${doc.id}`);
    },
    id: doc.id,
    documentUrl: doc.document?.url,
    name: doc.participant.name,
    rut: doc.participant.rut,
    email: doc.participant.email,
    phone: doc.participant.phone,
    mainSignerStatus: [
      DocumentStatusNames[doc.mainSignerStatus],
      ChipType.DOCUMENT_STATUS,
      doc.mainSignerStatus,
    ],
    secondarySignerStatus: doc.secondarySignerStatus && [
      DocumentCompanyStatusNames[doc.secondarySignerStatus],
      ChipType.DOCUMENT_STATUS,
      doc.secondarySignerStatus,
    ],
    vulnerabilityStatus: doc.vulnerabilityStatus === DocumentStatus.INACTIVE ? [
      "NO APLICA",
      ChipType.DOCUMENT_STATUS,
      doc.vulnerabilityStatus,
    ] : [
      DocumentStatusNames[doc.documentVulnerability.status],
      ChipType.DOCUMENT_STATUS,
      doc.documentVulnerability.status,
    ],
    minorAuthorizationStatus: doc.minorAuthorizationStatus === DocumentStatus.INACTIVE ? [
      "NO APLICA",
      ChipType.DOCUMENT_STATUS,
      doc.minorAuthorizationStatus,
    ] : [
      DocumentStatusNames[doc.documentMinorAuth.status],
      ChipType.DOCUMENT_STATUS,
      doc.documentMinorAuth.status,
    ]
    ,
    // TODO-14: Change lasReminder to a Date instead of a number a parse with more accurate information
    lastReminder: getLastReminderDaysText(doc.lastReminder),
  }));
};

export const parseDJOParticipantsData = (
  docsParticipants: DocumentParticipantInfo[],
  history: History,
): DocumentParticipantInfoData[] => {
  return docsParticipants.map((doc: DocumentParticipantInfo) => ({
    onRowClick: () => {
      history.push(`${history.location.pathname}/historial/${doc.id}`);
    },
    id: doc.id,
    documentUrl: doc.document?.url,
    name: doc.participant.name,
    rut: doc.participant.rut,
    email: doc.participant.email,
    phone: doc.participant.phone,
    mainSignerStatus: [
      DocumentStatusNames[doc.mainSignerStatus],
      ChipType.DOCUMENT_STATUS,
      doc.mainSignerStatus,
    ],
    secondarySignerStatus: doc.secondarySignerStatus && [
      DocumentCompanyStatusNames[doc.secondarySignerStatus],
      ChipType.DOCUMENT_STATUS,
      doc.secondarySignerStatus,
    ],
    // TODO-14: Change lasReminder to a Date instead of a number a parse with more accurate information
    lastReminder: getLastReminderDaysText(doc.lastReminder),
  }));
};

export const filterCourseListData = (
  data: (CourseListData & { criticalityLevel?: CriticalityLevel })[],
  filters: CourseListFilters,
): (CourseListData & { criticalityLevel?: CriticalityLevel })[] => {
  return data.filter((d) => {
    if (
      filters.registrationNumber.length > 0 &&
      !filters.registrationNumber.includes(d.registrationNumber)
    )
      return false;
    if (filters.sencenet && filters.sencenet !== d.sencenet) return false;
    if (filters.name && filters.name !== d.name) return false;
    if (filters.company && filters.company !== d.companyData) return false;
    if (d.criticalityLevel && !filters.criticalityLevel[d.criticalityLevel])
      return false;
    if (filters.otec && filters.otec !== d.otecData) return false;
    if (filters.cell && filters.cell !== d.cell) return false;
    return true;
  });
};

export const initialCourseFilters: CourseListFilters = {
  registrationNumber: [],
  sencenet: null,
  name: null,
  company: null,
  otec: null,
  cell: null,
  criticalityLevel: Object.assign(
    {},
    ...Object.values(CriticalityLevel).map((cl) => ({ [cl]: true })),
  ),
};

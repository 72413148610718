import { Theme, PaletteColor, lighten } from "@mui/material";
import {
  TableChipCellStyle,
  TableChipCellType as ChipType,
} from "../components/Reusable/Table/interfaces";
import {
  CriticalityLevel,
  DocumentCompanyStatus,
  DocumentStatus,
  ElearningCourseStatus,
} from "interfaces";

export const fixedWidths: Record<ChipType, string> = {
  [ChipType.DAYS]: "62px",
  [ChipType.FRACTION]: "90px",
  [ChipType.DOCUMENT_STATUS]: "132px",
  [ChipType.DEFAULT]: "unset",
};

type LevelIndexerType =
  | CriticalityLevel
  | DocumentStatus
  | DocumentCompanyStatus
  | ElearningCourseStatus;

export const getTableChipStyles = (
  theme: Theme,
  type: ChipType,
  level: LevelIndexerType,
  chipStyleType?: TableChipCellStyle,
  clickable: boolean = false,
) => {
  if (type === ChipType.DEFAULT) return {};
  const {
    palette: { error, warning, success, common, secondary, info, grey },
  } = theme;

  const colorStateMapping: Record<LevelIndexerType, PaletteColor> = {
    [CriticalityLevel.HIGH]: error,
    [CriticalityLevel.MEDIUM]: secondary,
    [CriticalityLevel.LOW]: success,
    [CriticalityLevel.NONE]:  {
      main: grey[600],
      dark: grey[700],
      light: grey[900],
      contrastText: common.white, 
    },
    [DocumentStatus.PENDING]: error,
    [DocumentStatus.CORRECTION_REQUESTED]: warning,
    [DocumentStatus.PENDING_VALIDATION]: secondary,
    [DocumentStatus.VALIDATED]: success,
    [DocumentStatus.FORCED_VALIDATED]: success,
    [DocumentStatus.OUTDATED]: {
      main: grey[600],
      dark: grey[800],
      light: grey[400],
      contrastText: common.white,
    },
    // Not shown in frontend
    [DocumentStatus.INACTIVE]: {
      main: grey[200],
      dark: grey[200],
      light: grey[200],
      contrastText: common.black,
    },
    [DocumentStatus.WAITING_MODULAR]: info,
    [DocumentCompanyStatus.PENDING]: error,
    [DocumentCompanyStatus.PRESIGNED]: error,
    [DocumentCompanyStatus.VALIDATED]: success,
    // Elearning module
    [ElearningCourseStatus.PENDING]: error,
    [ElearningCourseStatus.FINISHED]: success,
  };

  const baseColor = colorStateMapping[level];
  const commonStyle = {
    fontWeight: 500,
    width: fixedWidths[type],
  };
  const blackColorTypes: LevelIndexerType[] = [
    CriticalityLevel.MEDIUM,
    DocumentStatus.PENDING_VALIDATION,
  ];

  if (chipStyleType === TableChipCellStyle.PRIMARY) {
    return {
      ...commonStyle,
      backgroundColor: baseColor ? baseColor.main : "transparent",
      "&:hover": {
        backgroundColor:
          baseColor && clickable
            ? lighten(baseColor.main, 0.3)
            : baseColor
            ? baseColor.main
            : "transparent",
      },
      color:
        level && blackColorTypes.includes(level) ? common.black : common.white,
    };
  }
  if (chipStyleType === TableChipCellStyle.SECONDARY) {
    return {
      ...commonStyle,
      backgroundColor: baseColor ? common.white : "transparent",
      color: baseColor ? baseColor.dark : "transparent",
      border: baseColor ? `1px solid ${baseColor.main}` : "unset",
    };
  }
  return {};
};

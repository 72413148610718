import { gql, useQuery } from "@apollo/client";
import { DocumentData } from "interfaces";
import {
  DJPDocumentDataFragment,
  DJODocumentDataFragment,
  PrecontractDocumentDataFragment,
  EventFragment,
} from "../fragments";

interface QueryResult {
  getDocumentDataByUUID: DocumentData | null;
}

interface HookReturn {
  data: DocumentData | null;
  loading: boolean;
}

const parseResponse = (
  loading: boolean,
  data?: DocumentData | null,
): HookReturn => {
  return {
    data: data
      ? {
          ...data,
          courseLiquidationDate: new Date(data.courseLiquidationDate),
        }
      : null,
    loading,
  };
};

export const useDocumentDataByUUID = (
  uuid?: string,
  createVisualizationEvent?: boolean,
): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(DOCUMENT_DATA_UUID_QUERY, {
    variables: {
      params: {
        signatureLinkUUID: uuid,
        createVisualizationEvent,
      },
    },
  });
  return parseResponse(loading, data?.getDocumentDataByUUID);
};

export const useDocumentDataSimplifiedByUUID = (
  uuid?: string,
  createVisualizationEvent?: boolean,
  sendUuidAsDocumentUuid?: boolean,
): HookReturn => {
  const { data, loading } = useQuery<QueryResult>(
    DOCUMENT_DATA_SIMPLIFIED_UUID_QUERY,
    {
      variables: {
        params: {
          signatureLinkUUID: sendUuidAsDocumentUuid ? null : uuid,
          documentUUID: sendUuidAsDocumentUuid ? uuid : null,
          createVisualizationEvent,
        },
      },
    },
  );
  return parseResponse(loading, data?.getDocumentDataByUUID);
};

export const DOCUMENT_DATA_UUID_QUERY = gql`
  query GetDocumentDataByUUID($params: GetDocumentDataByUUIDParams!) {
    getDocumentDataByUUID(params: $params) {
      document {
        id
        type
        status
        url
        uuid
        signatureEmail
        manualUploaded
        events {
          ...EventFields
        }
      }
      courseLiquidationDate
      djpData {
        ...DJPDocumentDataFields
      }
      djoData {
        ...DJODocumentDataFields
      }
      precontractData {
        ...PrecontractDocumentDataFields
      }
    }
  }
  ${EventFragment}
  ${DJPDocumentDataFragment}
  ${DJODocumentDataFragment}
  ${PrecontractDocumentDataFragment}
`;

export const DOCUMENT_DATA_SIMPLIFIED_UUID_QUERY = gql`
  query GetDocumentDataByUUID($params: GetDocumentDataByUUIDParams!) {
    getDocumentDataByUUID(params: $params) {
      courseLiquidationDate
    }
  }
`;

import React, { createContext, useCallback, useMemo, useState } from "react";
import { CustomSnackbar, FullPageLoader, SnackbarData } from "components";
import { uniq } from "lodash";
import { AlertColor } from "@mui/material";

interface FeedbackContextType {
  loading: boolean;
  showLoader: (name: string) => void;
  hideLoader: (name: string) => void;
  toggleLoader: (loading: boolean, name: string) => void;
  showSnackbar: (text: string, severity: AlertColor) => void;
}

export const FeedbackContext = createContext({} as FeedbackContextType);

export const FeedbackProvider: React.FC = ({ children }) => {
  const [loaders, setLoaders] = useState<string[]>([]);
  const [snackbarData, setSnackbarData] = useState<SnackbarData | null>(null);

  const showLoader = useCallback(
    (name: string) => {
      setLoaders((prev) => uniq([...prev, name]));
    },
    [setLoaders],
  );

  const hideLoader = useCallback(
    (name: string) => {
      setLoaders((prev) => prev.filter((key) => key !== name));
    },
    [setLoaders],
  );

  const toggleLoader = useCallback(
    (loading: boolean, name: string) => {
      if (loading) {
        showLoader(name);
      } else {
        hideLoader(name);
      }
    },
    [showLoader, hideLoader],
  );

  const showSnackbar = useCallback(
    (text: string, severity: AlertColor) => {
      setSnackbarData({ text, severity });
    },
    [setSnackbarData],
  );

  const onSnackbarClose = useCallback(() => {
    setSnackbarData(INITIAL_SNACKBAR_DATA);
  }, [setSnackbarData]);

  const context = useMemo(
    () => ({
      loading: loaders.length > 0,
      showLoader,
      hideLoader,
      toggleLoader,
      showSnackbar,
    }),
    [loaders, showLoader, hideLoader, toggleLoader, showSnackbar],
  );

  return (
    <FeedbackContext.Provider value={context}>
      <>
        {children}
        <FullPageLoader open={loaders.length > 0} />
        <CustomSnackbar
          open={!!snackbarData?.text}
          data={snackbarData ?? INITIAL_SNACKBAR_DATA}
          onClose={onSnackbarClose}
        />
      </>
    </FeedbackContext.Provider>
  );
};

const INITIAL_SNACKBAR_DATA: SnackbarData = {
  text: "",
  severity: "success",
};

import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

interface LoaderProps {
  open: boolean;
}

export const FullPageLoader: React.FC<LoaderProps> = ({ open }) => {
  return (
    <Backdrop
      className="flex justify-center"
      open={open}
      style={{ zIndex: 10000 }}
    >
      <CircularProgress size={120} color="primary" />
    </Backdrop>
  );
};
